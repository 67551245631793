import { Formik } from "formik"
import { topUpWithVoucher } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
}

const AddVoucher = props => {
  const { isOpen, toggle, userId, refetch } = props

  const [loading, setLoading] = useState(null)
  const [passwordType, setPasswordType] = useState("password")
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
    if (feedbackModal.openFor === "pin") {
      toggle()
    }
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
    if (warningFeedbackModal.openFor === "pin") {
      toggle()
    }
  }
  const handlePasswordType = () => {
    if (passwordType === "text") setPasswordType("password")
    else setPasswordType("text")
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Load Vomoz Card</ModalHeader>
        <ModalBody>
          <Row className="justify-content-center">
            <Col lg={12}>
              <br />
              <Formik
                initialValues={{
                  voucher: "",
                }}
                validationSchema={Yup.object({
                  voucher: Yup.string()
                    .matches(
                      /(^[0-9]+$)/,
                      "Voucher PIN can only contain digit number"
                    )
                    .required("Required")
                    .min(10, "Minimum of 10 digits")
                    .max(16, "Maximum of 16 digits"),
                })}
                onSubmit={async (values, { resetForm }) => {
                  console.log(values)
                  setLoading(true)
                  const data = {
                    orgId: "939001",
                    userGlobalId: userId,
                    voucher: values.voucher,
                  }
                  if (values.voucher) {
                    const res = await topUpWithVoucher(data)
                    resetForm({ values: "" })
                    setLoading(null)
                    if (res === undefined) {
                      alert("Please check your internet connection")
                      setLoading(null)
                    }
                    if (res?.success === false) {
                      console.log(res)
                      setLoading(null)
                      setWarningFeedbackModal(prev => ({
                        ...prev,
                        open: true,
                        openFor: "mobile",
                        success: false,
                        error: true,
                        title: "Failed!",
                        message: res.message,
                      }))
                    } else if (res?.success === true) {
                      console.log(res)
                      setFeedbackModal(prev => ({
                        ...prev,
                        open: true,
                        openFor: "mobile",
                        success: true,
                        error: false,
                        title: "Done!",
                        message: res.message,
                      }))
                      refetch()
                      toggle()
                    }
                  }
                }}
              >
                {props => {
                  return (
                    <form
                      className={"justify-content-center"}
                      onSubmit={props.handleSubmit}
                    >
                      <FormGroup className="row mb-4">
                        <Label
                          for="horizontal-firstname-Input"
                          className="col-sm-12 col-form-Label"
                        >
                          Enter the card PIN here
                        </Label>
                        <InputGroup>
                          <input
                            name="voucher"
                            style={{
                              marginLeft: "0px",
                              marginRight: "0px",
                            }}
                            value={props.values.voucher}
                            onChange={props.handleChange}
                            type={passwordType}
                            placeholder="Enter PIN"
                            className="form-control mr-0"
                            onBlur={props.handleBlur}
                          />
                          <div
                            className="input-group-append"
                            style={{ cursor: "pointer" }}
                            onClick={handlePasswordType}
                          >
                            <span className="input-group-text">
                              {passwordType === "text" ? (
                                <i class="fa fa-low-vision"></i>
                              ) : (
                                <i class="fa fa-eye" aria-hidden="true"></i>
                              )}
                            </span>
                          </div>
                        </InputGroup>
                        {props.touched.voucher && props.errors.voucher ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.voucher}
                          </p>
                        ) : null}
                      </FormGroup>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Col md={4} style={{ flex: "1" }}></Col>
                        <Col md={3} style={{ flex: "2" }}>
                          <FormGroup className="row justify-content-center">
                            <Button
                              type="submit"
                              color="primary"
                              className="btn btn-primary btn-block waves-effect waves-light"
                            >
                              {loading ? "Loading..." : "Load"}
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col md={1} style={{ flex: "1" }}>
                          <Button color="link" onClick={toggle}>
                            Cancel
                          </Button>
                        </Col>
                        <Col md={4} style={{ flex: "1" }}></Col>
                      </Row>
                    </form>
                  )
                }}
              </Formik>
              {feedbackModal.open && (
                <SweetAlert
                  title={feedbackModal.title}
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={closeFeedbackModal}
                >
                  {feedbackModal.message}
                </SweetAlert>
              )}
              <div>
                {warningFeedbackModal.open && (
                  <SweetAlert
                    title={warningFeedbackModal.title}
                    danger
                    confirmBtnBsStyle="danger"
                    onConfirm={closeWarningFeedbackModal}
                  >
                    {warningFeedbackModal.message}
                  </SweetAlert>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter></ModalFooter>
        {/* </Container> */}
      </div>
    </Modal>
  )
}

AddVoucher.propTypes = {
  t: PropTypes.any,
}

export default AddVoucher
