const TableColumn = [
  {
    field: "n",
    label: "#",
    sort: "asc",
  },
  {
    field: "firstName",
    label: "Name",
    sort: "asc",
    width: 136,
  },
  {
    field: "mobile",
    label: "Phone",
    sort: "asc",
    width: 100,
  },
  {
    field: "email",
    label: "Email",
    sort: "asc",
  },
  {
    label: "Action",
    field: "action",
  },
]

export default TableColumn
