import classnames from "classnames"
import { systemSettings } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Card,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const FollowUs = () => {
  const [activeTab, setActiveTab] = useState("1")
  const [log, setLog] = useState([])
  const [loading, setLoading] = useState(null)

  const getSystemSettings = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const assigned = await systemSettings(data)
    const socialMediaLog = assigned?.data?.socialMedia
    console.log(log)

    if (socialMediaLog) {
      setLog(socialMediaLog)
      setLoading(null)
    }
  }

  useEffect(() => {
    getSystemSettings()
  }, [])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Services" breadcrumbItem="Follow Us" />
          <div className="checkout-tabs">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardTitle className="ml-2 mt-2">
                    Join us on social media
                  </CardTitle>
                  <Nav
                    style={{ display: "flex", justifyContent: "space-around" }}
                    className="flex-column"
                    pills
                  >
                    <NavItem>
                      <NavLink
                        target="_self"
                        rel="noopener"
                        href={log.facebook}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        <i className="bx bxl-facebook-square d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Facebook</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        target="_self"
                        rel="noopener"
                        href={log.twitter}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        <i className="bx bxl-twitter d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Twitter</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        target="_self"
                        rel="noopener"
                        href={log.youtube}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        <i className="bx bxl-youtube d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Youtube</p>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        target="_self"
                        rel="noopener"
                        href={log.instagram}
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        <i className="bx bxl-instagram d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Instagram</p>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        target="_self"
                        rel="noopener"
                        href={log.tiktok}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5")
                        }}
                      >
                        <i className="fab fa-tiktok d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">Tiktok</p>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        target="_self"
                        rel="noopener"
                        href={log.linkedIn}
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggleTab("6")
                        }}
                      >
                        <i className="bx bxl-linkedin d-block check-nav-icon mt-4 mb-2" />
                        <p className="font-weight-bold mb-4">LinkedIn</p>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FollowUs
