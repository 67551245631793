import {
  deleteExistingAutoRecharge,
  existingAutoRecharge,
} from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Button, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AutoRechargeCard from "./card-pricing"
import EditAutoRecharge from "./components/EditAutoTopUp"
import SetupAutoRecharge from "./components/SetUpAutoTopUp"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const AutoRecharge = () => {
  const [loading, setLoading] = useState(null)
  const [orders, setOrders] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [editModalInfo, setEditModalInfo] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [icon, setIcon] = useState(null)
  const [deleteB, setDeleteB] = useState("")

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const toggleEditModal = orders => {
    setEditModalInfo(orders)
    setEditModal(!editModal)
  }

  const toggleAddModal = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    const widthen = window.innerWidth
    console.log(widthen)

    if (widthen < 500) setIcon(true)
    else setIcon(null)
  })

  const getExistingAutoRecharge = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const response = await existingAutoRecharge(data)
    console.log(response)
    const servedOrders = response?.data
    console.log(servedOrders)
    if (servedOrders) {
      setOrders(servedOrders)
      setLoading(null)
    }
  }

  useEffect(() => {
    getExistingAutoRecharge()
  }, [])

  console.log(orders)

  const deleteButton = order => {
    setInfo({ confirm: true, id: order.id })
  }

  const handleLocalNumberDelete = async event => {
    setDeleteB(info?.id)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: info?.id,
    }
    const Deleted = await deleteExistingAutoRecharge(data)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      getExistingAutoRecharge()
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  const handleDeleteClick = () => {
    return setInfo({ confirm: true, id: orders.id })
  }

  const handleEditClick = () => {
    return toggleEditModal(orders)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <SetupAutoRecharge
          isOpen={addModal}
          toggle={toggleAddModal}
          refreshTable={getExistingAutoRecharge}
        />
        <EditAutoRecharge
          isOpen={editModal}
          toggle={toggleEditModal}
          editInfo={editModalInfo}
          refreshTable={getExistingAutoRecharge}
          topUpDetails={orders}
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Account" breadcrumbItem="Auto Recharge" />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
              confirmBtnBsStyle="success"
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
              confirmBtnBsStyle="danger"
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-justify mb-5">
                <h4>My Auto Top Up</h4>
                <p className="text-muted">
                  Never run low on call credit! Activate your account
                  auto-refill.
                </p>
              </div>
            </Col>
            <Col lg={10}>
              <div
                className="justify-content-center"
                style={{
                  display: "flex",
                  maxWidth: "200%",
                }}
              >
                {loading ? (
                  ""
                ) : orders.isExistingFound === "false" ? (
                  <div className="text-muted justify-content-start">
                    <p className="setup">
                      Click on the Plus (+) below to activate your account
                      auto-refill.
                    </p>

                    <Button
                      type="button"
                      color="success"
                      className="w-md local-number-add-button"
                      onClick={toggleAddModal}
                    >
                      <i className="mdi mdi-plus mr-1" />
                      <span> Setup Auto Top Up</span>
                    </Button>
                  </div>
                ) : (
                  <AutoRechargeCard
                    pricing={orders}
                    handleDelete={handleDeleteClick}
                    handleEdit={handleEditClick}
                  />
                )}
                {console.log(orders)}
              </div>
            </Col>
          </Row>

          <Col xl="3" lg="4" sm="6" className="mb-2">
            {info.confirm && (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="success"
                cancelBtnText="No"
                cancelBtnBsStyle="danger"
                onConfirm={handleLocalNumberDelete}
                onCancel={() => setInfo({})}
              ></SweetAlert>
            )}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AutoRecharge
