import { Form, Formik } from "formik"
import { buyCreditWithExistingCard } from "helpers/requests"
import { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../../components/CustomSelect"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const PayWithExistingCard = props => {
  const { cardId, editInfo, isOpen, toggle, refreshTable, userDetails } = props

  const [loading, setLoading] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [dataInfo, setDataInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const closeSuccessFeedbackModal = () => {
    setDataInfo({ success_dlg: false })
  }

  const closeErrorFeedbackModal = () => {
    setDataInfo({ error_dlg: false })
  }

  function onCaptchaChange(value) {
    setDisableButton(true)
  }

  return (
    <>
      {dataInfo.success_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeSuccessFeedbackModal}
        >
          {dataInfo.dynamic_description}
        </SweetAlert>
      ) : null}

      {dataInfo.error_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          error
          confirmBtnBsStyle="danger"
          onConfirm={closeErrorFeedbackModal}
        >
          {dataInfo?.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Pay with the Current Card</ModalHeader>
          <Formik
            initialValues={{
              amount: "",
            }}
            validationSchema={Yup.object({
              amount: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                id: cardId,
                amount: values.amount,
              }
              const res = await buyCreditWithExistingCard(data)
              resetForm({ values: "" })
              setLoading(false)
              if (res === undefined) {
                alert("Please check your internet connection")
              }
              if (res?.success) {
                setLoading(false)

                setDataInfo({
                  confirm: false,
                  success_dlg: true,
                  dynamic_title: "Done!",
                  dynamic_description: res?.message,
                })
                refreshTable()
                toggle()
              } else {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  error_dlg: true,
                  dynamic_title: "Failed",
                  dynamic_description: res?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Select an amount to recharge</Label>
                          <CustomSelect
                            classNamePrefix="select2-selection"
                            name="amount"
                            value={props.values.amount}
                            options={userDetails.amountCreditUserCanBuy.map(
                              item => ({
                                label: item,
                                value: item,
                              })
                            )}
                            onChange={props.handleChange("amount")}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup className="select2-container">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          type="submit"
                          disabled={!disableButton}
                          className="btn btn-primary btn-block waves-effect waves-light"
                          color="primary"
                        >
                          {loading ? "Sending..." : "Send"}
                        </Button>
                      </Col>

                      <Col md={3} style={{ flex: "1" }}>
                        <Button color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <br />
                      <i
                        style={{
                          color: "#000",
                          fontSize: "11px",
                          marginLeft: "10px",
                        }}
                      >
                        Kindly check the captcha checkbox to enable this "Send"
                        button
                      </i>
                    </Row>
                  </ModalBody>
                  <ModalFooter></ModalFooter>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default PayWithExistingCard
