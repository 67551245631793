//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { authorizedCountries, deleteCountry } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import AddCountry from "./components/AddCountry"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const AuthorizedCountries = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])
  const [deleteB, setDeleteB] = useState("")
  const [countryAdded, setCountryAdded] = useState(false)

  const toggleModal = () => {
    setOpen(!open)
  }
  const handleAuthCountryChange = () => setCountryAdded(!countryAdded)

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const getAuthorizedCountries = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    console.log(data)

    const countries = await authorizedCountries(data)
    const countriesList = countries?.data?.returnedRecords
    console.log(countriesList)
    if (countriesList) {
      setLogs(countriesList)
      setLoading(false)
    }
  }
  useEffect(() => {
    getAuthorizedCountries()
  }, [])

  const deleteButton = order => {
    setInfo({ confirm: true, id: order.id })
  }

  const handleLocalNumberDelete = country => () => {
    setDeleteB(country)

    setInfo({
      confirm: true,
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
  }

  const handleDelete = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      country: deleteB,
    }
    console.log(data)
    const Deleted = await deleteCountry(data)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      getAuthorizedCountries()
      // setOrders(orders.filter(order => order.id != info?.id))
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        {open && (
          <AddCountry
            isOpen={open}
            toggle={toggleModal}
            refreshTable={getAuthorizedCountries}
          />
        )}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Account"}
            breadcrumbItem={"Authorized Countries"}
          />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}
          <Row className="justify-content-center">
            <Col lg={7}>
              <Row className="mb-2">
                <Col sm="4"></Col>
                <Col sm="8">
                  <div className="text-sm-right">
                    <Button
                      type="button"
                      color="success"
                      className="btn-square waves-effect waves-light mb-2 mr-2 authorized-countries-add-button"
                      onClick={toggleModal}
                    >
                      <i className="mdi mdi-plus mr-1" />
                      <span>Add New Country</span>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    My Authorized Countries
                  </CardTitle>

                  <div className="table-responsive">
                    {loading ? (
                      ""
                    ) : logs.length === 0 ? (
                      "You do not have any country in your authorized list yet. Kindly click on the Add button to add one now."
                    ) : logs.length > 0 ? (
                      <Table className="table table-centered table-nowrap">
                        <tbody>
                          {logs.map((member, index) => (
                            <tr key={"_member_" + index}>
                              <td>{index + 1}</td>
                              <td>
                                {member.flag !== "Null" ? (
                                  <img
                                    src={member.flag}
                                    className="rounded-circle avatar-xs"
                                    alt=""
                                    style={{
                                      width: "30% !important",
                                      height: "auto",
                                      padding: "5%",
                                    }}
                                  />
                                ) : (
                                  <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                                      {member.country.charAt(0)}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td>
                                <h5 className="font-size-14 m-0">
                                  <Link to="" className="text-dark">
                                    {member.country}
                                  </Link>
                                </h5>
                              </td>
                              <td>
                                <Button
                                  type="button"
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  color="primary"
                                  onClick={handleLocalNumberDelete(
                                    member.country
                                  )}
                                  style={{
                                    width: "60px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "auto",
                                    height: "32px",
                                  }}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>
                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.confirm && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleDelete}
                        onCancel={() =>
                          setInfo({
                            error_dlg: false,
                          })
                        }
                      ></SweetAlert>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AuthorizedCountries.propTypes = {
  t: PropTypes.any,
}

export default AuthorizedCountries
