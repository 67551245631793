import "assets/scss/datatables.scss"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { deleteReferredFriends, listReferredFriends } from "helpers/requests"
import { map } from "lodash"
import { MDBDataTableV5 } from "mdbreact"
import React, { useEffect, useRef, useState } from "react"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import "react-datepicker/dist/react-datepicker.css"
import { withRouter } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import AssignLocalNumber from "./components/assignLocalNumbers"
import ReferralColumn from "./dataTableColumns"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const ReferAFriend = () => {
  const [loading, setLoading] = useState(null)
  const [orders, setOrders] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [editModalInfo, setEditModalInfo] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [checkedList, setCheckedList] = useState({})
  const [checkbox1, setCheckbox1] = useState([])
  const [isShowingTable, setIsShowingTable] = useState(true)
  const [icon, setIcon] = useState(null)
  const [deleteB, setDeleteB] = useState("")

  const [info, setInfo] = useState({
    group: null,
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const toggleEditModal = order => {
    setEditModalInfo(order)
    setEditModal(!editModal)
  }

  const toggleAddModal = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    const widthen = window.innerWidth

    if (widthen < 500) setIcon(true)
    else setIcon(null)
  })

  const getReferralList = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const Assigned = await listReferredFriends(data)
    console.log(Assigned)
    const servedOrders = Assigned?.data?.returnedRecords
    console.log(servedOrders)
    // if (servedOrders) setOrders(servedOrders.splice(1, 100))
    if (servedOrders) {
      console.log(orders)
      setOrders(servedOrders)
      setLoading(null)
    }
  }

  useEffect(() => {
    getReferralList()
  }, [])

  const deleteButton = order => {
    setInfo({ confirm: true, id: order.id[id.length - 1] })
  }

  // const { orders()

  const getRealDate = date => {
    if (date === "") {
      return "-"
    } else {
      const str = new Date(date).toDateString()

      let splitDate = str.split(" ")
      return (
        <>
          {splitDate[0] +
            " " +
            splitDate[1] +
            " " +
            splitDate[2] +
            "," +
            " " +
            splitDate[3]}
        </>
      )
    }
  }

  const getReferralCommission = referralCommission => {
    if (referralCommission === "") {
      return "-"
    } else {
      return "$" + referralCommission + ".00"
    }
  }
  const getMobile = mobile => {
    if (mobile === "") {
      return "-"
    } else {
      return mobile
    }
  }

  const [deleteId, setDeleteId] = useState([])

  const handleCheck = id => () => {
    console.log(id)

    let prev = deleteId

    console.log(prev)
    setDeleteId(prev => {
      if (prev.find(pid => pid === id)) {
        return prev.filter(pid => pid !== id)
      }

      prev.concat([id])
      return prev
    })
  }

  useEffect(() => {
    console.log(deleteId)
  }, [deleteId])

  const data = {
    columns: ReferralColumn,
    rows: map(orders, order => ({
      ...order,
      signUpDate: getRealDate(order.signUpDate),
      purchasedCreditDate: getRealDate(order.purchasedCreditDate),
      mobile: getMobile(order.mobile),
      referralCommission: getReferralCommission(order.referralCommission),

      n: (
        <>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={order.id}
              onChange={e => {
                handleCheckTableItem(e, order)
              }}
              // checked={deleteId.find(did => did === order.id)}
            />
            <label className="custom-control-label" htmlFor={order.id}>
              &nbsp;
            </label>
          </div>
        </>
      ),
      action: (
        <>
          {!icon ? (
            <>
              <Button
                color="primary"
                onClick={() => setInfo({ confirm: true, id: order.id })}
                id="sa-params"
                className="ml-3"
              >
                {deleteB === order.id ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  </>
                ) : (
                  "Delete"
                )}
              </Button>
            </>
          ) : (
            <>
              <button
                className="btn noti-icon waves-effect"
                onClick={() => setInfo({ confirm: true, id: order.id })}
              >
                <i className="bx bx-trash fontColor" />
              </button>
            </>
          )}
        </>
      ),
    })),
  }

  const handleCheckTableItem = (e, order) => {
    let temporaryCheckedList = {}
    if (e.target.checked) {
      temporaryCheckedList = { ...checkedList, [order.id]: order }
    } else {
      temporaryCheckedList = { ...checkedList }
      delete temporaryCheckedList[order.id]
      console.log(temporaryCheckedList)
    }
    setCheckedList(temporaryCheckedList)
  }

  const handleGroupDelete = async event => {
    // Object.values(checkedList).map(item => item.id)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: Object.values(checkedList).map(item => item.id),
    }
    console.log(Object.values(checkedList).map(item => item.id))
    const Deleted = await deleteReferredFriends(data)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        group: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      setOrders(
        orders.filter(
          order =>
            !Object.values(checkedList).find(
              (element, index) => element.id === order.id
            )
        )
      )
      setCheckedList({})
      setIsShowingTable(false)
      setTimeout(() => {
        setIsShowingTable(true)
      }, 100)
    } else {
      setLoading(false)
      setInfo({
        group: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  const handleSingleDelete = async () => {
    setDeleteB(info?.id)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: [info.id],
    }
    const Deleted = await deleteReferredFriends(data)
    console.log("Hi delete!", Deleted)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      setOrders(orders.filter(order => order.id != info?.id))
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <AssignLocalNumber
          isOpen={addModal}
          toggle={toggleAddModal}
          refreshTable={getReferralList}
          size="xl"
          style={{ minWidth: "1000px", width: "200%" }}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Services" breadcrumbItem="Referrals" />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="3">
                      <Button
                        type="button"
                        color="success"
                        className="w-md local-number-add-button"
                        onClick={toggleAddModal}
                      >
                        <i className="mdi mdi-plus mr-1" />
                        <span> Refer A Friend</span>
                      </Button>
                    </Col>
                    <Col lg="3">
                      {Object.values(checkedList).length > 0 && (
                        <>
                          {!icon ? (
                            <>
                              <Button
                                style={{
                                  transform: "scale(1) translate(-224%, -45%)",
                                }}
                                color="primary"
                                className="btn noti-icon waves-effect mt-3"
                                onClick={() =>
                                  setInfo({ group: true, id: orders.id })
                                }
                              >
                                Delete
                              </Button>
                            </>
                          ) : (
                            <>
                              <button
                                style={{
                                  transform: "scale(1) translate(-13%, 190%)",
                                }}
                                className="btn noti-icon waves-effect"
                                onClick={() =>
                                  setInfo({ group: true, id: orders.id })
                                }
                              >
                                <i className="bx bx-trash" />
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </Col>
                    <Col lg="4"></Col>
                    <Col lg="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md btn-square waves-effect waves-light"
                        onClick={handlePrint}
                        block
                      >
                        Print
                      </Button>
                    </Col>
                  </Row>
                  <div ref={componentRef}>
                    {loading ? (
                      ""
                    ) : orders.length === 0 ? (
                      "You don't have a referred friend yet. Kindly refer a friend"
                    ) : isShowingTable && orders.length > 0 ? (
                      <MDBDataTableV5
                        responsive
                        striped
                        hover
                        data={data}
                        className="mt-4"
                        pagesAmount={4}
                        entriesOptions={[100, 200, 500, 1000]}
                        entrieslabel="How Many?"
                        entries={50}
                      />
                    ) : null}
                  </div>
                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.confirm && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnText="No"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleSingleDelete}
                        onCancel={() =>
                          setInfo({
                            error_dlg: false,
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.group && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnText="No"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleGroupDelete}
                        onCancel={() =>
                          setInfo({
                            error_dlg: false,
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ReferAFriend)
