import Breadcrumbs from "components/Common/Breadcrumb"
import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import Form from "./ContactForm"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const Contact = () => {
  const [loading, setLoading] = useState(null)
  const [load, setLoad] = useState(null)
  const [userFullName, setUserFullName] = useState("")
  const [userEmail, setUserEmail] = useState("")

  const fetchUserAccountParameters = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const response = await userAccountParameters(data)
    const userDetails = response?.data
    console.log(userDetails)
    if (userDetails) {
      const emptySpace = " "
      setUserFullName(
        userDetails?.firstName + emptySpace + userDetails?.lastName
      )
      setUserEmail(userDetails?.email)
      console.log(userFullName)
      console.log(userEmail)
      setLoad(true)
    }
  }

  useEffect(() => {
    fetchUserAccountParameters()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Contact"} breadcrumbItem={"Contact Support"} />

          <Row className={"justify-content-center"}>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    We would love to hear from you
                  </CardTitle>
                  <CardSubtitle></CardSubtitle>
                  <br />
                  {load ? (
                    <Form
                      initialFullName={userFullName}
                      initialEmail={userEmail}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Contact.propTypes = {
  t: PropTypes.any,
}

export default Contact
