import { Form, Formik } from "formik"
import {
  updateExistingAutoRecharge,
  userAccountParameters,
} from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../../components/CustomSelect"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const EditAutoRecharge = props => {
  const { editInfo, isOpen, toggle, refreshTable, topUpDetails } = props
  const [loading, setLoading] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [info, setInfo] = useState({})
  const [dataInfo, setDataInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const [amountCredUserCanBuy, setAmountCredUserCanBuy] = useState([])
  const [
    minAccountBalanceBeforeAutoRecharge,
    setMinAccountBalanceBeforeAutoRecharge,
  ] = useState([])

  const closeSuccessFeedbackModal = () => {
    setDataInfo({ success_dlg: false })
  }

  const closeErrorFeedbackModal = () => {
    setDataInfo({ error_dlg: false })
  }

  const onCaptchaChange = value => {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const fetchRechargeDetails = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    if (parameters) {
      console.log(parameters.data)
      setLoading(null)
      const userParameters = parameters?.data
      console.log(userParameters)
      const amountCreditUserCanBuy = userParameters?.amountCreditUserCanBuy
      const minimumAccountBalanceBeforeAutoRecharge =
        userParameters?.minimumAccountBalanceBeforeAutoRecharge
      if (amountCreditUserCanBuy) {
        setAmountCredUserCanBuy(amountCreditUserCanBuy)
        console.log(amountCredUserCanBuy)
      }
      if (minimumAccountBalanceBeforeAutoRecharge) {
        setMinAccountBalanceBeforeAutoRecharge(
          minimumAccountBalanceBeforeAutoRecharge
        )
        console.log(minAccountBalanceBeforeAutoRecharge)
      }
    }
  }

  useEffect(() => {
    fetchRechargeDetails()
  }, [])

  useEffect(() => {
    setInfo(editInfo)
  }, [editInfo])

  console.log(topUpDetails)

  return (
    <>
      {dataInfo.success_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeSuccessFeedbackModal}
        >
          {dataInfo.dynamic_description}
        </SweetAlert>
      ) : null}

      {dataInfo.error_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          error
          confirmBtnBsStyle="danger"
          onConfirm={closeSuccessFeedbackModal}
        >
          {dataInfo?.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Update Auto Recharge</ModalHeader>
          <Formik
            initialValues={{
              amountUserCanBuy: topUpDetails.amountToRecharge,
              accountBalanceBeforeRecharge:
                topUpDetails.accountBalanceBeforeRecharge,
            }}
            validationSchema={Yup.object({
              amountUserCanBuy: Yup.string().required("Required"),
              accountBalanceBeforeRecharge: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              console.log(editInfo)
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                id: info.id,
                accountBalanceBeforeRecharge:
                  values.accountBalanceBeforeRecharge,
                amountToRecharge: values.amountUserCanBuy,
              }
              console.log(data)
              const res = await updateExistingAutoRecharge(data)
              resetForm({ values: "" })
              setLoading(false)
              if (res === undefined) {
                alert("Please check your internet connection")
              }
              if (res?.success) {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  success_dlg: true,
                  dynamic_title: "Updated!",
                  dynamic_description: res?.message,
                })
                refreshTable()
                toggle()
              } else {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  error_dlg: true,
                  dynamic_title: "Failed",
                  dynamic_description: res?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Select Amount to Recharge</Label>
                          <CustomSelect
                            name="amountUserCanBuy"
                            value={props.values.amountUserCanBuy}
                            placeholder={<div>Type to search</div>}
                            options={amountCredUserCanBuy.map(item => ({
                              label: item,
                              value: item,
                            }))}
                            defaultValue={{
                              label: topUpDetails.amountToRecharge,
                              value: topUpDetails.amountToRecharge,
                            }}
                            onChange={props.handleChange("amountUserCanBuy")}
                          />
                          {props.touched.amountUserCanBuy &&
                          props.errors.amountUserCanBuy ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.amountUserCanBuy}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Minimum Amount Before Auto Recharge</Label>
                          <CustomSelect
                            name="accountBalanceBeforeRecharge"
                            value={props.values.accountBalanceBeforeRecharge}
                            options={minAccountBalanceBeforeAutoRecharge.map(
                              item => ({
                                label: item,
                                value: item,
                              })
                            )}
                            defaultValue={{
                              label: topUpDetails.accountBalanceBeforeRecharge,
                              value: topUpDetails.accountBalanceBeforeRecharge,
                            }}
                            onChange={props.handleChange(
                              "accountBalanceBeforeRecharge"
                            )}
                          />
                          {props.touched.accountBalanceBeforeRecharge &&
                          props.errors.accountBalanceBeforeRecharge ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.accountBalanceBeforeRecharge}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Country</Label> */}
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          color="primary"
                          disabled={!disableButton}
                        >
                          {loading ? "Updating" : "Update"}
                        </Button>
                      </Col>
                      <Col md={3} style={{ flex: "1" }}>
                        <Button type="button" color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter></ModalFooter>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default EditAutoRecharge
