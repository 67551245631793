import Breadcrumbs from "components/Common/Breadcrumb"
import { purchaseHistory } from "helpers/requests"
import { MDBDataTable } from "mdbreact"
import React, { useEffect, useRef, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import "react-datepicker/dist/react-datepicker.css"
import { useHistory } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const transferCreditHis = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])
  const [transaction, setTransaction] = useState({})
  const openModal = transaction => () => {
    setTransaction(transaction)
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
  }
  const toggleModal = () => {
    setOpen(!open)
  }

  const handleChange = date => {
    setStartDate(date)
    console.log(date.toLocaleDateString("en-CA"))
  }
  const handleChange2 = date => {
    setEndDate(date)
  }

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const getListCustomerLogs = async () => {
    setLoading(true)
    console.log(endDate?.toLocaleDateString("en-CA"))
    console.log(startDate?.toLocaleDateString("en-CA"))
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      startDate: startDate?.toLocaleDateString("en-CA") || null,
      endDate: endDate?.toLocaleDateString("en-CA") || null,
    }

    const log = await purchaseHistory(data)
    console.log(log)
    const ListCustomerLog = log?.data?.returnedRecords
    console.log(ListCustomerLog)
    if (ListCustomerLog) setLogs(ListCustomerLog)
    setLoading(false)
  }

  useEffect(() => {
    getListCustomerLogs()
  }, [endDate])

  const getRealDate = date => {
    const str = new Date(date).toDateString()

    let splitDate = str.split(" ")
    return (
      <>
        {splitDate[0] +
          " " +
          splitDate[1] +
          " " +
          splitDate[2] +
          "," +
          " " +
          splitDate[3]}
      </>
    )
  }

  const calcLocalTime = startTime => {
    let realTime = new Date(startTime).toLocaleTimeString()
    return realTime.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1")
  }

  const getCardIcon = cardType => {
    cardType = cardType.toLowerCase()
    if (cardType === "amex") {
      return "fab fa-cc-amex"
    } else if (cardType === "diners-club") {
      return "fab fa-cc-diners-club"
    } else if (cardType === "discover") {
      return "fab fa-cc-discover"
    } else if (cardType === "jcb") {
      return "fab fa-cc-jcb"
    } else if (cardType === "mc") {
      return "fab fa-cc-mastercard"
    } else if (cardType === "paypal") {
      return "fab fa-cc-paypal"
    } else if (cardType === "stripe") {
      return "fab fa-cc-stripe"
    } else if (cardType === "visa") {
      return "fab fa-cc-visa"
    } else if (cardType === "card") {
      return "fas fa-credit-card"
    } else if (cardType === "google-wallet") {
      return "fab fa-google-wallet"
    } else {
      return "fas fa-credit-card"
    }
  }

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Date",
        field: "date",
        width: 100,
        sort: "asc",
      },
      {
        label: "Time",
        field: "time",
        width: 100,
        sort: "asc",
      },
      {
        label: "Amount ($)",
        field: "amount",
        width: 100,
        sort: "asc",
      },
      {
        label: "Card Type",
        field: "cardType",
        width: 150,
        sort: "asc",
      },
      {
        label: "Mode",
        field: "mode",
        width: 500,
        sort: "asc",
      },
      {
        label: "Source",
        field: "source",
        width: 500,
        sort: "asc",
      },
    ],
  })

  useEffect(() => {
    if (logs.length > 0) {
      const badgesData = {
        columns: [
          {
            label: "#",
            field: "id",
          },
          ...datatable.columns,
        ],
        rows: [
          ...logs.map((row, order) => ({
            ...row,
            id: <>{order + 1}</>,

            date: getRealDate(row?.date),
            time: calcLocalTime(row?.date),
            cardType: (
              <>
                <i className={getCardIcon(row.cardType) + " mr-1"}></i>{" "}
                {row.cardType}
              </>
            ),
          })),
        ],
      }
      setDatatable(badgesData)
    }
  }, [logs])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}

        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Account" breadcrumbItem="Purchase History" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="all-customer-card-body">
                  {logs.length > 0 && (
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          margin: "10px",
                        }}
                      >
                        <Button
                          type="button"
                          color="primary"
                          className="btn-square waves-effect waves-light"
                          onClick={() => {
                            history.push("/account/buy-credit")
                          }}
                        >
                          Buy Credit
                        </Button>

                        <Button
                          type="button"
                          color="primary"
                          className="btn-square waves-effect waves-light"
                          onClick={handlePrint}
                        >
                          Print
                        </Button>
                      </div>
                    </div>
                  )}
                  <div
                    style={{ width: "100%", overflow: "auto" }}
                    ref={componentRef}
                  >
                    {loading ? (
                      ""
                    ) : logs.length === 0 ? (
                      <p>
                        You are yet to purchase a call credit in your account
                        with your card. Kindly do so now to view this log.
                      </p>
                    ) : logs.length > 0 ? (
                      <MDBDataTable
                        hover
                        entriesOptions={[100, 200, 500, 1000]}
                        entries={100}
                        pagesAmount={4}
                        data={datatable}
                        striped
                        style={{
                          marginTop: "10px",
                        }}
                      />
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default transferCreditHis
