import { Form, Formik } from "formik"
import { enableOrDisableTwoFactorAuth } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import {
  Button,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
}

const TwoFactorAuthModal = props => {
  const { isOpen, toggle, oppositeOf2FAStatus, refreshData } = props

  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)
  const [passwordType, setPasswordType] = useState("password")
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
    if (feedbackModal.openFor === "pin") {
      toggle()
    }
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
    if (warningFeedbackModal.openFor === "pin") {
      toggle()
    }
  }

  const handlePasswordType = () => {
    if (passwordType === "text") setPasswordType("password")
    else setPasswordType("text")
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      password: password,
    }
    const passwordReq = await showPin(data)
    if (passwordReq.success) {
      onSuccess(passwordReq.data.pin)
      toggle()
    } else {
      console.log(passwordReq)
    }
  }

  const getNewStatus = word => word + "d"
  const new2FAStatus = getNewStatus(oppositeOf2FAStatus)

  //  N0L0g1n890@$

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>
            <span style={{ textTransform: "capitalize" }}>
              {oppositeOf2FAStatus}
            </span>{" "}
            2FA Status-Modification Procedure
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col lg={12}>
                <br />
                <Formik
                  initialValues={{
                    password: "",
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    console.log(values)
                    setLoading(true)
                    const data = {
                      orgId: "939001",
                      userGlobalId: user?.userGlobalId,
                      newStatus: new2FAStatus,
                      password: values.password,
                    }
                    if (values.password) {
                      const res = await enableOrDisableTwoFactorAuth(data)
                      resetForm({ values: "" })
                      setLoading(false)
                      if (res === undefined) {
                        alert("Please check your internet connection")
                        setLoading(null)
                      }
                      if (res?.success === false) {
                        console.log(res)
                        setLoading(false)
                        setWarningFeedbackModal(prev => ({
                          ...prev,
                          open: true,
                          openFor: "mobile",
                          success: false,
                          error: true,
                          title: "Failed!",
                          message: res.message,
                        }))
                      } else if (res?.success === true) {
                        console.log(res)
                        setLoading(false)
                        setFeedbackModal(prev => ({
                          ...prev,
                          open: true,
                          openFor: "mobile",
                          success: true,
                          error: false,
                          title: "2FA Status Modified!",
                          message: res.message,
                        }))
                        refreshData()
                        toggle()
                      }
                    }
                  }}
                >
                  {props => {
                    return (
                      <Form className={"justify-content-center"}>
                        <FormGroup className="row mb-4">
                          <Label
                            for="horizontal-firstname-Input"
                            className="col-sm-12 col-form-Label"
                          >
                            Input Password
                          </Label>
                          <InputGroup>
                            <input
                              name="password"
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                              }}
                              value={props.values.password}
                              onChange={props.handleChange}
                              type={passwordType}
                              placeholder="Enter your login password"
                              className="form-control"
                              onBlur={props.handleBlur}
                            />
                            <div
                              className="input-group-append"
                              style={{ cursor: "pointer" }}
                              onClick={handlePasswordType}
                            >
                              <span className="input-group-text">
                                {passwordType === "text" ? (
                                  <i class="fa fa-low-vision"></i>
                                ) : (
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                                )}
                              </span>
                            </div>
                          </InputGroup>
                          {props.touched.password && props.errors.password ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.password}
                            </p>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="row justify-content-center">
                          <Button
                            type="submit"
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            {loading ? "Modifying..." : "Modify"}
                          </Button>

                          <Button type="button" color="link">
                            <Link to="/">Cancel</Link>
                          </Button>
                        </FormGroup>
                      </Form>
                    )
                  }}
                </Formik>
                {feedbackModal.open && (
                  <SweetAlert
                    title={feedbackModal.title}
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={closeFeedbackModal}
                    timeout={2000}
                  >
                    {feedbackModal.message}
                  </SweetAlert>
                )}
                <div>
                  {warningFeedbackModal.open && (
                    <SweetAlert
                      title={warningFeedbackModal.title}
                      danger
                      confirmBtnBsStyle="danger"
                      onConfirm={closeWarningFeedbackModal}
                    >
                      {warningFeedbackModal.message}
                    </SweetAlert>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

TwoFactorAuthModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default TwoFactorAuthModal
