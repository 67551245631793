import { useField } from "formik"
import React, { useState } from "react"
import Select, { components } from "react-select"

const Placeholder = props => {
  return <components.Placeholder {...props} />
}

const CustomSelect = ({
  name,
  options,
  onChange,
  defaultValue,
  placeholder,
}) => {
  const [field, meta, helpers] = useField(name)
  const [label, setLabel] = useState()
  console.log(label)

  return (
    <>
      <Select
        isClearable
        options={options}
        name={name}
        placeholder={placeholder}
        onChange={value => {
          console.log(value)
          helpers.setValue(value)
          if (onChange) {
            setLabel(value.label)
            onChange(value?.value)
          }
        }}
        defaultValue={defaultValue}
        onBlur={() => helpers.setTouched(true)}
      />
    </>
  )
}

export default CustomSelect
