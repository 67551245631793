import { availableFollowMeNumbers, editFollowMeNumber } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Modal, ModalHeader } from "reactstrap"
import "../../styles.scss"
import FormikForm from "./EditFollowMeForm"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const EditFollowMeNumber = ({ editInfo, isOpen, toggle, refreshTable }) => {
  const [loading, setLoading] = useState(null)
  const [availableNumbers, setAvailableNumbers] = useState([])
  const [showReturnDate, setShowReturnDate] = useState(false)
  const [showVoiceMailOption, setShowVoiceMailOption] = useState(false)
  const [options, setOptions] = useState([])
  const [values, setValues] = useState("")
  const [valuesInfo, setValuesInfo] = useState(null)
  const [followMeNumber, setFollowMeNumber] = useState(null)
  const [dataInfo, setDataInfo] = useState({})
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const [disableButton, setDisableButton] = useState(false)

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  useEffect(() => {
    if (editInfo) {
      setDataInfo(editInfo)
      console.log(editInfo)
    }
  }, [editInfo])
  console.log(dataInfo)

  const handleChange = event => {
    setInfo({ ...info, [event.target.name]: event.target.value })
  }

  let optionGroup = [
    {
      options,
    },
  ]
  useEffect(() => {
    console.log(availableNumbers)
    setOptions(
      availableNumbers.map(local => ({
        label: local.followMeNumber,
        value: local.id,
        id: local.id,
        // voiceMailOption: local.voiceMailOption,
      }))
    )
  }, [availableNumbers])

  useEffect(() => {
    let idEd
    if (availableFollowMeNumbers.length < 1)
      idEd = availableFollowMeNumbers.filter(
        num => num.followMeNumber === followMeNumber
      )
    console.log(idEd)
    console.log(followMeNumber)
  }, [availableFollowMeNumbers])

  const getAvailableLocalNumbers = async () => {
    let data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const available = await availableFollowMeNumbers(data)
    const availableNumbers = available?.data?.returnedRecords
    if (availableNumbers) {
      setOptions(
        availableNumbers.map(local => ({
          label: local.followMeNumber,
          value: local.id,
          id: local.id,
        }))
      )
    }
  }
  useEffect(() => {
    getAvailableLocalNumbers()
  }, [])

  const handleReturnDateRadio = e => {
    setValues(prev => ({
      ...prev,
      returnRadio: e === "returnDate" ? "true" : "false",
    }))
    if (e === "returnDate") {
      setShowReturnDate(true)
    } else {
      setShowReturnDate(false)
    }
  }

  const handleVoiceMailRadio = e => {
    setValues(prev => ({
      ...prev,
      voiceMailRadio: e === "voiceMail" ? "true" : "false",
    }))
    if (e === "voiceMail") {
      setShowVoiceMailOption(true)
    } else {
      setShowVoiceMailOption(false)
    }
  }

  useEffect(() => {
    getAvailableLocalNumbers()
  }, [])

  const handleLocalNumberEdit = async event => {
    setLoading(true)
    event.preventDefault()
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const Editted = await editFollowMeNumber({ ...data, ...info })
    console.log(Editted)
    setLoading(null)
    toggle()

    if (Editted?.success) {
      toggle()
      setSalert({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Updated",
        dynamic_description: "Local Number has been successfully Updated.",
      })
      refreshTable()
    } else
      setSalert({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Editted?.message,
      })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Edit Follow Me Number</ModalHeader>
          <FormikForm
            toggle={toggle}
            refreshTable={refreshTable}
            destination={dataInfo.destination}
            voicemailOption={dataInfo.voicemailOption}
            returnDate={dataInfo.returnDate}
            recordId={dataInfo.recordId}
            dataInfoFollowMeNumber={dataInfo.followMeNumber}
          />
        </div>
      </Modal>
    </>
  )
}

EditFollowMeNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EditFollowMeNumber
