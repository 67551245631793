import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { Form, Formik } from "formik"
import { forgotPassword } from "helpers/requests"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import logo from "../../assets/images/logo.svg"
// import images
import profile from "../../assets/images/profile-img.png"
import externalLinks from "../../helpers/externalLinks"

const ForgotPassword = () => {
  const [loading, setLoading] = useState(null)
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const [disableButton, setDisableButton] = useState(false)

  const closeSuccessFeedbackModal = () => {
    setInfo({ success_dlg: false })
  }

  const closeErrorFeedbackModal = () => {
    setInfo({ error_dlg: false })
  }
  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  return (
    <React.Fragment>
      {info.success_dlg ? (
        <SweetAlert
          title={info.dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeSuccessFeedbackModal}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          title={info.dynamic_title}
          error
          confirmBtnBsStyle="danger"
          onConfirm={closeErrorFeedbackModal}
        >
          {info?.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="account-pages my-4 pt-sm-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Forgot Password</h5>
                        {/* <p>Re-Password with Skote.</p> */}
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <a
                      href={externalLinks.vomozFlexLandingPage}
                      rel="noopener noreferrer"
                    >
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </a>
                  </div>

                  <div className="p-2">
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      Enter your email and instructions will be sent to you!
                    </div>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .email("Invalid email address")
                          .required("Required"),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        console.log(values)
                        setLoading(true)
                        const data = {
                          orgId: "939001",
                          email: values.email,
                          passwordResetUrl:
                            externalLinks.vomozFlexResetPassword,
                          // passwordResetUrl:
                          //   "http://localhost:3000/reset-password/",
                        }
                        console.log(data)
                        const response = await forgotPassword(data)
                        resetForm({ values: "" })
                        setLoading(false)
                        if (response === undefined) {
                          alert("Please check your internet connection")
                        }
                        if (response) {
                          console.log(response)
                          setLoading(false)
                          setInfo({
                            confirm: false,
                            success_dlg: true,
                            dynamic_title: "Ok!",
                            dynamic_description: response?.message,
                          })
                        } else {
                          setLoading(false)
                          setInfo({
                            confirm: false,
                            error_dlg: true,
                            dynamic_title: "Failed!",
                            dynamic_description: response?.message,
                          })
                        }
                      }}
                    >
                      {props => {
                        return (
                          <Form
                            className="form-horizontal"
                            action="dashboard"
                            name="password-reset-form"
                          >
                            <Col md={12}>
                              <FormGroup>
                                <Label for="useremail">Email</Label>
                                <Input
                                  name="email"
                                  className="form-control mr-0 ml-0"
                                  id="useremail"
                                  placeholder="Enter your email"
                                  onChange={props.handleChange}
                                  type="email"
                                  value={props.values.email}
                                  onBlur={props.handleBlur}
                                />
                                {props.touched.email && props.errors.email ? (
                                  <p style={{ color: "red", fontSize: "11px" }}>
                                    {props.errors.email}
                                  </p>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col md={12}>
                              <FormGroup className="select2-container">
                                {/* <Label>Country</Label> */}
                                <ReCAPTCHA
                                  sitekey={
                                    process.env.REACT_APP_RECAPTCHA_SITE_KEY
                                  }
                                  onChange={onCaptchaChange}
                                />
                              </FormGroup>
                            </Col>
                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                disabled={!disableButton}
                              >
                                {loading ? "Sending..." : "Send"}
                              </button>
                              <br />
                              <i style={{ fontSize: "11px" }}>
                                Kindly check the captcha checkbox to enable this
                                "Login" button
                              </i>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Never mind!{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    {" "}
                    Take me back to login
                  </Link>{" "}
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword
