import { Form, Formik } from "formik"
import { becomeAPartner } from "helpers/requests"
import { Base64 } from "js-base64"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import { useHistory } from "react-router-dom"
import { Button, Col, FormGroup, Input, Label } from "reactstrap"
import * as Yup from "yup"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
  },
}

const BecomeAPartnerForm = props => {
  const history = useHistory()
  const { initialFirstName, initialLastName, initialEmail } = props

  const [disableButton, setDisableButton] = useState(false)
  const [loading, setLoading] = useState(null)
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  console.log(initialFirstName)
  console.log(initialEmail)

  const formikInitialValues = {
    fullName: initialFirstName + " " + initialLastName,
    email: initialEmail,
    emailBody: "",
  }

  return (
    <>
      <Col lg={12}>
        {feedbackModal.open && (
          <SweetAlert
            title={feedbackModal.title}
            success
            confirmBtnBsStyle="success"
            onConfirm={closeFeedbackModal}
          >
            {feedbackModal.message}
          </SweetAlert>
        )}
        <div>
          {warningFeedbackModal.open && (
            <SweetAlert
              title={warningFeedbackModal.title}
              danger
              confirmBtnBsStyle="danger"
              onConfirm={closeWarningFeedbackModal}
            >
              {warningFeedbackModal.message}
            </SweetAlert>
          )}
        </div>
      </Col>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={Yup.object({
          fullName: Yup.string(),
          email: Yup.string().email("Invalid email address"),
          emailBody: Yup.string()
            .max(500, "Must be 500 characters or less")
            .required("Required"),
        })}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true)
          const data = {
            orgId: "939001",
            userGlobalId: user?.userGlobalId,
            emailBody: Base64.encode(values.emailBody),
          }
          console.log(data)
          if (values.emailBody) {
            const res = await becomeAPartner(data)
            resetForm({ values: "" })
            setLoading(null)
            if (res === undefined) {
              alert("Please check your internet connection")
              setLoading(null)
            }
            if (res?.success === false) {
              setLoading(null)
              setWarningFeedbackModal(prev => ({
                ...prev,
                open: true,
                success: false,
                error: true,
                title: "Failed!",
                message: res.message,
              }))
            } else if (res?.success === true) {
              console.log(res)
              setFeedbackModal(prev => ({
                ...prev,
                open: true,
                success: true,
                error: false,
                title: "Sent!",
                message: res.message,
              }))
              setTimeout(() => {
                history.push("/")
              }, 2000)
            }
          }
        }}
      >
        {props => {
          return (
            <Form>
              <Col>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    name="fullName"
                    className="form-control"
                    style={{
                      width: "100%",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                    placeholder="Enter Full Name"
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    type="text"
                    value={props.values.fullName}
                  />
                  {props.touched.fullName && props.errors.fullName ? (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {props.errors.fullName}
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    style={{
                      width: "100%",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                    placeholder="Enter contact email"
                    type="email"
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    value={props.values.email}
                  />
                  {props.touched.email && props.errors.email ? (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {props.errors.email}
                    </p>
                  ) : null}
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Message</Label>
                  <Input
                    name="emailBody"
                    className="form-control"
                    placeholder="Enter your message"
                    type="textarea"
                    rows={6}
                    cols={30}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    value={props.values.emailBody}
                  />
                  {props.touched.emailBody && props.errors.emailBody ? (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {props.errors.emailBody}
                    </p>
                  ) : null}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="select2-container">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onCaptchaChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="row justify-content-end">
                  <Col sm={12}>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn btn-block"
                      disabled={!disableButton}
                    >
                      {loading ? "Sending..." : "Send"}
                    </Button>
                    <br />
                    <i style={{ color: "#000", fontSize: "11px" }}>
                      Kindly check the captcha checkbox to enable this "Send"
                      button
                    </i>
                  </Col>
                </FormGroup>
              </Col>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default BecomeAPartnerForm
