import logoImg from "assets/images/logo.svg"
import profileImg from "assets/images/profile-img.svg"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import Form from "./BecomeAPartnerForm"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const BecomeAPartner = () => {
  const [loading, setLoading] = useState(null)
  const [load, setLoad] = useState(null)
  const [userFirstName, setUserFirstName] = useState(null)
  const [userLastName, setUserLastName] = useState(null)
  const [userEmail, setUserEmail] = useState(null)

  const getUserAccountParameters = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setLoading(false)
      setUserFirstName(userParameters.firstName)
      setUserLastName(userParameters.lastName)
      setUserEmail(userParameters.email)
      setLoad(true)
    }
  }

  useEffect(() => {
    getUserAccountParameters()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Services"} breadcrumbItem={"Become A Partner"} />
          <Row className="justify-content-center">
            <Col lg={6}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Vomoz Value Added Partnership Program
                        </h5>
                        <p>
                          Do you want to become a Vomoz's Reseller or a brand
                          Ambassador or a Distributor or a Corporate Referral?
                          Please fill the form below and one of our business
                          development Directors will contact you.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-2 px-0">
                  <div className="pt-3">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {load ? (
                      <Form
                        initialFirstName={userFirstName}
                        initialLastName={userLastName}
                        initialEmail={userEmail}
                      />
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BecomeAPartner.propTypes = {
  t: PropTypes.any,
}

export default BecomeAPartner
