import PropTypes from "prop-types"
import React from "react"
import { Redirect, Route } from "react-router-dom"

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  match,
  ...rest
}) => {
  console.log(match)
  const userDetails = localStorage.getItem("userData")
  console.log(userDetails)
  return (
    <Route
      exact
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("userData")) {
          return <Redirect to="/login" />
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default AppRoute
