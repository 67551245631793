import profileImg from "assets/images/profile-img.svg"
import Breadcrumbs from "components/Common/Breadcrumb"
import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import externalLinks from "../../helpers/externalLinks"
import PINModal from "./showPinModal"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const PIN = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(null)
  const [initialValues, setInitialValues] = useState(null)
  const [password, setPassword] = useState()
  const [showButton, setShowButton] = useState(true)

  const showPassword = val => {
    setPassword(val)
    setShowButton(false)
  }

  const toggleModal = () => {
    setOpen(!open)
  }

  const getUserParameters = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setLoading(null)
      setInitialValues(userParameters)
    }
  }

  useEffect(() => {
    getUserParameters()
  }, [])

  console.log(initialValues)

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <PINModal isOpen={open} toggle={toggleModal} onSuccess={showPassword} />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Settings"} breadcrumbItem={"Show PIN"} />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Show PIN</h5>
                        {/* <p>Change Security PIN</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <h5>Your PIN</h5>
                    <p>{password || "xxxx"}</p>
                    {showButton ? (
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="button"
                          onClick={toggleModal}
                        >
                          Click to show your PIN
                        </button>
                      </div>
                    ) : null}
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        By visiting this page, you agree to Vomoz's{" "}
                        <a
                          href={externalLinks.vomozFlexLandingPagePrivacyPolicy}
                          className="text-primary"
                        >
                          Terms of Use
                        </a>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PIN.propTypes = {
  t: PropTypes.any,
}

export default PIN
