import Breadcrumbs from "components/Common/Breadcrumb"
import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { FaCaretDown, FaCaretUp, FaPhone } from "react-icons/fa"
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import accessNumbers from "./data.js"
import "./styles.scss"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const usStates = accessNumbers.unitedStates
const canadaStates = accessNumbers.canada
const ukStates = accessNumbers.unitedKingdom
const irelandStates = accessNumbers.ireland

const usAccessNumbers = (
  <div
    className="collapsible-mobile-container"
    style={{ display: "flex", alignItems: "center" }}
  >
    <div style={{ flex: "1" }} className="location-container">
      <h6
        className="location-header"
        style={{ marginBottom: "30px", fontSize: "15px" }}
      >
        Locations
      </h6>
      <ul className="location-list">
        {(() => {
          let items = []
          usStates.forEach((usState, index) => {
            console.log(usState)
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                }}
              >
                {usState.state}
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
    <div style={{ flex: "1" }}>
      <h6
        style={{ marginBottom: "30px", marginLeft: "40px", fontSize: "15px" }}
      >
        Access Number
      </h6>
      <ul>
        {(() => {
          let items = []
          usStates.forEach((usState, index) => {
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                  whiteSpace: "nowrap",
                }}
              >
                <span>
                  <a href={`tel:${usState.number}`}>
                    <FaPhone size={10} style={{ marginRight: "3px" }} />
                    {usState.number}
                  </a>
                </span>
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
  </div>
)

const canadaAccessNumbers = (
  <div
    className="collapsible-mobile-container"
    style={{ display: "flex", alignItems: "center" }}
  >
    <div style={{ flex: "1" }} className="location-container">
      <h6
        className="location-header"
        style={{ marginBottom: "30px", fontSize: "15px" }}
      >
        Location
      </h6>
      <ul className="location-list">
        {(() => {
          let items = []
          canadaStates.forEach((canadaState, index) => {
            console.log(canadaState)
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                }}
              >
                {canadaState.state}
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
    <div style={{ flex: "1" }}>
      <h6
        style={{ marginBottom: "30px", marginLeft: "40px", fontSize: "15px" }}
      >
        Access Number
      </h6>
      <ul>
        {(() => {
          let items = []
          canadaStates.forEach((canadaState, index) => {
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                  whiteSpace: "nowrap",
                }}
              >
                <span>
                  <a href={`tel:${canadaState.number}`}>
                    <FaPhone size={10} style={{ marginRight: "3px" }} />
                    {canadaState.number}
                  </a>
                </span>
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
  </div>
)

const ukAccessNumbers = (
  <div
    className="collapsible-mobile-container"
    style={{ display: "flex", alignItems: "center" }}
  >
    <div style={{ flex: "1" }} className="location-container">
      <h6
        className="location-header"
        style={{ marginBottom: "30px", fontSize: "15px" }}
      >
        Location
      </h6>
      <ul className="location-list">
        {(() => {
          let items = []
          ukStates.forEach((ukState, index) => {
            console.log(ukState)
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                }}
              >
                {ukState.state}
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
    <div style={{ flex: "1" }}>
      <h6
        style={{ marginBottom: "30px", marginLeft: "40px", fontSize: "15px" }}
      >
        Access Number
      </h6>
      <ul>
        {(() => {
          let items = []
          ukStates.forEach((ukState, index) => {
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                  whiteSpace: "nowrap",
                }}
              >
                <span>
                  <a href={`tel:${ukState.number}`}>
                    <FaPhone size={10} style={{ marginRight: "3px" }} />
                    {ukState.number}
                  </a>
                </span>
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
  </div>
)

const irelandAccessNumbers = (
  <div
    className="collapsible-mobile-container"
    style={{ display: "flex", alignItems: "center" }}
  >
    <div style={{ flex: "1" }} className="location-container">
      <h6
        className="location-header"
        style={{ marginBottom: "30px", fontSize: "15px" }}
      >
        Location
      </h6>
      <ul className="location-list">
        {(() => {
          let items = []
          irelandStates.forEach((irelandState, index) => {
            console.log(irelandState)
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                }}
              >
                {irelandState.state}
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
    <div style={{ flex: "1" }}>
      <h6
        style={{ marginBottom: "30px", marginLeft: "40px", fontSize: "15px" }}
      >
        Access Number
      </h6>
      <ul>
        {(() => {
          let items = []
          irelandStates.forEach((irelandState, index) => {
            items.push(
              <li
                key={index}
                style={{
                  listStyle: "none",
                  height: "40px",
                  whiteSpace: "nowrap",
                }}
              >
                <span>
                  <a href={`tel:${irelandState.number}`}>
                    <FaPhone size={10} style={{ marginRight: "3px" }} />
                    {irelandState.number}
                  </a>
                </span>
              </li>
            )
          })
          return items
        })()}
      </ul>
    </div>
  </div>
)

const AccessNumbers = props => {
  const [open, setOpen] = useState(0)
  const [openAccordion, setOpenAccordion] = useState(false)
  const [loading, setLoading] = useState(null)
  const [initialValues, setInitialValues] = useState(null)

  const handleAccordion = (event, accord) => {
    if (open !== accord) {
      setOpen(accord)
      setOpenAccordion(true)
    } else {
      setOpen(0)
      setOpenAccordion(false)
    }
  }

  const getUserParameters = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setLoading(null)
      setInitialValues(userParameters)
    }
  }

  useEffect(() => {
    getUserParameters()
  }, [])

  console.log(initialValues)

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Numbers"} breadcrumbItem={"Access Numbers"} />
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={8}>
              <Card>
                <CardBody>
                  <CardTitle>Access Numbers</CardTitle>
                  <CardSubtitle className="mb-3">
                    Note: For our users in the United States, please use any
                    state's Access Number to call in case your location is not
                    captured on the list.
                  </CardSubtitle>

                  <div id="accordion">
                    <Card className="mb-2">
                      <CardHeader className="p-3" id="headingOne">
                        <div style={{ display: "flex" }}>
                          <h6 className="m-0 font-14" style={{ flex: "2" }}>
                            <span
                              onClick={event => handleAccordion(event, "1")}
                              style={{ cursor: "pointer" }}
                              className="text-dark location-span"
                            >
                              United States
                            </span>
                          </h6>
                          <h6 className="m-0 font-14" style={{ flex: "1" }}>
                            <span
                              onClick={event => handleAccordion(event, "1")}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              {openAccordion ? <FaCaretUp /> : <FaCaretDown />}
                            </span>
                          </h6>
                        </div>
                      </CardHeader>

                      <Collapse isOpen={open === "1"}>
                        <Card>
                          <CardBody>{usAccessNumbers}</CardBody>
                        </Card>
                      </Collapse>
                    </Card>
                    <Card className="mb-2">
                      <CardHeader className="p-3" id="headingTwo">
                        <div style={{ display: "flex" }}>
                          <h6 className="m-0 font-14" style={{ flex: "2" }}>
                            <span
                              onClick={event => handleAccordion(event, "2")}
                              style={{ cursor: "pointer" }}
                              className="text-dark location-span"
                            >
                              Canada
                            </span>
                          </h6>
                          <h6 className="m-0 font-14" style={{ flex: "1" }}>
                            <span
                              onClick={event => handleAccordion(event, "2")}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              {openAccordion ? <FaCaretUp /> : <FaCaretDown />}
                            </span>
                          </h6>
                        </div>
                      </CardHeader>
                      <Collapse isOpen={open === "2"}>
                        <Card>
                          <CardBody>{canadaAccessNumbers}</CardBody>
                        </Card>
                      </Collapse>{" "}
                    </Card>
                    <Card className="mb-2">
                      <CardHeader className="p-3" id="headingThree">
                        <div style={{ display: "flex" }}>
                          <h6 className="m-0 font-14" style={{ flex: "2" }}>
                            <span
                              onClick={event => handleAccordion(event, "3")}
                              style={{ cursor: "pointer" }}
                              className="text-dark location-span"
                            >
                              United kingdom
                            </span>
                          </h6>
                          <h6 className="m-0 font-14" style={{ flex: "1" }}>
                            <span
                              onClick={event => handleAccordion(event, "3")}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              {openAccordion ? <FaCaretUp /> : <FaCaretDown />}
                            </span>
                          </h6>
                        </div>
                      </CardHeader>
                      <Collapse isOpen={open === "3"}>
                        <Card>
                          <CardBody>{ukAccessNumbers}</CardBody>
                        </Card>
                      </Collapse>{" "}
                    </Card>
                    <Card className="mb-2">
                      <CardHeader className="p-3" id="headingFour">
                        <div style={{ display: "flex" }}>
                          <h6 className="m-0 font-14" style={{ flex: "2" }}>
                            <span
                              onClick={event => handleAccordion(event, "4")}
                              style={{ cursor: "pointer" }}
                              className="text-dark location-span"
                            >
                              Ireland
                            </span>
                          </h6>
                          <h6 className="m-0 font-14" style={{ flex: "1" }}>
                            <span
                              onClick={event => handleAccordion(event, "4")}
                              style={{ cursor: "pointer" }}
                              className="text-dark"
                            >
                              {openAccordion ? <FaCaretUp /> : <FaCaretDown />}
                            </span>
                          </h6>
                        </div>
                      </CardHeader>
                      <Collapse isOpen={open === "4"}>
                        <Card>
                          <CardBody>{irelandAccessNumbers}</CardBody>
                        </Card>
                      </Collapse>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AccessNumbers.propTypes = {
  t: PropTypes.any,
}

export default AccessNumbers
