import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { verifyMobile } from "helpers/requests"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { Link, useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap"

const MobileVerification = () => {
  const [mobile, setMobile] = useState("")
  const [loading, setLoading] = useState(null)
  const [info, setInfo] = useState({
    confirm: null,
    success_dlg: null,
    error_dlg: null,
    dynamic_title: "",
    dynamic_description: "",
  })

  const history = useHistory()

  const handleVerification = async event => {
    event.preventDefault()
    setLoading(true)
    const data = {
      mobile,
      orgId: "939001",
      sendSms: "true",
    }
    const res = await verifyMobile(data)
    console.log("=>", res)
    if (!res.success) {
      setLoading(null)
      console.log("Failed", res)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed!",
        dynamic_description: res?.message,
      })
    } else {
      console.log("Success")
      localStorage.setItem("vomoz-code", res.data.code)
      localStorage.setItem("vomoz-mobile", mobile)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Code Sent!",
        dynamic_description: res?.message,
      })
      if (res.data.isExistingCustomer)
        localStorage.setItem("isExistingCustomer", res.data.isExistingCustomer)
      setTimeout(() => {
        history.push("/auth-mobile")
      }, 5000)
    }
  }

  return (
    <React.Fragment>
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      <div className="account-pages my-0 pt-sm-0">
        <Container>
          <Row
            className="justify-content-center"
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Verification</h5>
                        <p>First we need to validate your number.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mt-5">
                  <div
                    className="p-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Form
                      className="form-horizontal"
                      onSubmit={handleVerification}
                      // action="dashboard"
                      name="mobile-verification-form"
                      // method="post"
                    >
                      <FormGroup>
                        <Label style={{ marginLeft: "12px" }} for="useremail">
                          Enter Mobile Number
                        </Label>
                        <Col xs={12}>
                          <PhoneInput
                            country="us"
                            value={mobile}
                            onChange={setMobile}
                            inputProps={{
                              name: "mobile",
                              required: true,
                              autoFocus: true,
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Col xs={12} className="text-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? "Sending" : "Send PIN"}
                          </button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Already Verified?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login here
                  </Link>{" "}
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MobileVerification
