import {
  ACCOUNT_CALLER_ID,
  ACCOUNT_PARAMS,
  ACCOUNT_PARAMS_ERROR,
  ACCOUNT_PARAMS_SUCCESS,
} from "./actionTypes"

const initialState = {
  accountParamsSuccessMsg: null,
  accountParamsError: null,
  loading: false,
  user: null,
  callerId: null,
}

const accountParams = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_PARAMS:
      state = {
        ...state,
        loading: true,
      }
      break
    case ACCOUNT_PARAMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
      }
      break
    case ACCOUNT_PARAMS_ERROR:
      state = {
        ...state,
        loading: false,
        accountParamsError: action.payload,
      }
      break
    case ACCOUNT_CALLER_ID:
      state = {
        ...state,
        loading: false,
        callerId: action.payload,
      }
      break
    default:
      return state
      break
  }
  return state
}

export default accountParams
