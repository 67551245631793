import "assets/scss/datatables.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  convertAmountToTwoDecimalPlace,
  convertUSDollarsCurrency3LettersToCurrencySymbol,
  formatExpirationDate,
  getCardIcon
} from "helpers/helperFunctions"
import {
  deleteExistingCard,
  storedCardsTokens,
  userAccountParameters
} from "helpers/requests"
import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

import "react-datepicker/dist/react-datepicker.css"
import { useHistory, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row
} from "reactstrap"
import cardImg from "../../assets/images/card.png"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import PayWithExistingCard from "./components/PayWithExistingCard"
import PayWithOtherCardsModal from "./components/PayWithOtherCards"
import PayWithPaypalModal from "./components/PayWithPaypal"
import UpdateExistingCard from "./components/UpdateExistingCard"
import localNumberColumns from "./dataTableColumns"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const BuyCredit = props => {
  const history = useHistory()
  const [loading, setLoading] = useState(null)
  const [orders, setOrders] = useState([])
  const [openExistingCardModal, setOpenExistingCardModal] = useState({
    id: "",
    open: false,
  })
  const [openNewCardModal, setOpenNewCardModal] = useState(false)
  const [editModalInfo, setEditModalInfo] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [icon, setIcon] = useState(null)
  const [deleteB, setDeleteB] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [openOtherCardsModal, setOpenOtherCardsModal] = useState(false)
  const [openPayPalModal, setOpenPayPalModal] = useState(false)
  const [userDetails, setUserDetails] = useState({})

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const toggleNewCardModal = () => {
    setOpenNewCardModal(!openNewCardModal)
  }

  const toggleOtherCardsModal = () => {
    setOpenOtherCardsModal(!openOtherCardsModal)
  }

  const togglePayPalModal = () => {
    setOpenPayPalModal(!openPayPalModal)
  }

  const openExistingPaymentCardModal = id => () => {
    console.log(id)
    setOpenExistingCardModal({
      open: true,
      id: id,
    })
  }
  const closeExistingPaymentCardModal = () => {
    setOpenExistingCardModal({
      open: false,
      id: "",
    })
  }

  const toggleEditModal = order => {
    setEditModalInfo(order)
    setEditModal(!editModal)
  }

  const getStoredCardsTokens = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userIpAddress: "127.0.0.1",
      userGlobalId: user?.userGlobalId,
    }

    const Assigned = await storedCardsTokens(data)
    console.log(Assigned)
    const servedOrders = Assigned?.data?.returnedRecords
    console.log(servedOrders)
    if (servedOrders) {
      setOrders(servedOrders)
      setLoading(null)
    }
  }

  const fetchUserDetails = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    if (parameters) {
      console.log(parameters.data)
      setLoading(null)
      const userParameters = parameters?.data
      setUserDetails(userParameters)
    }
  }

  useEffect(() => {
    const widthen = window.innerWidth
    console.log(widthen)

    if (widthen < 720) setIcon(true)
    else setIcon(null)

    getStoredCardsTokens()
    fetchUserDetails()
  }, [])

  console.log(orders)

  const deleteButton = order => {
    setInfo({ confirm: true, id: order.id })
  }

  const status = {
    completed: (
      <span className="badge badge-success font-size-10">Completed</span>
    ),
    pending: <span className="badge badge-warning font-size-10">Pending</span>,
    failed: <span className="badge badge-danger font-size-10">Failed</span>,
  }

  const data = {
    columns: localNumberColumns,
    rows: [
      ...orders.map((row, order) => ({
        ...row,
        id: <>{order + 1}</>,
        firstName: row.firstName + " " + row.lastName,
        last4Digit: "XX" + row.last4Digits,
        expirationDate: formatExpirationDate(row.expirationDate),
        status: status[row.status],
        cardMaker: (
          <>
            <i className={getCardIcon(row.cardMaker) + " mr-1"}></i>{" "}
          </>
        ),
        pay: (
          <>
            <Button
              color="primary"
              onClick={openExistingPaymentCardModal(row.id)}
            >
              Buy Credit
            </Button>
          </>
        ),
        action: (
          <>
            <button
              type="button"
              className="btn noti-icon waves-effect"
              onClick={() => toggleEditModal(row)}
            >
              <i className="bx bx-pencil" />
            </button>
            <button
              type="button"
              className="btn noti-icon waves-effect"
              onClick={() => setInfo({ confirm: true, id: row.id })}
            >
              <i className="bx bx-trash" />
            </button>
          </>
        ),
      })),
    ],
  }

  const handleLocalNumberDelete = async event => {
    setDeleteB(info?.id)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: info?.id,
      userIpAddress: "127.0.0.1",
      userDeviceType: "Windows 10",
      userBrowserType: "Chrome",
      userRefererUrl: "www.google.com",
    }
    const Deleted = await deleteExistingCard(data)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      setOrders(orders.filter(order => order.id != info?.id))
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <PayWithOtherCardsModal
          isOpen={openOtherCardsModal}
          toggle={toggleOtherCardsModal}
          refreshTable={getStoredCardsTokens}
        />
        <PayWithPaypalModal
          isOpen={openPayPalModal}
          toggle={togglePayPalModal}
          refreshTable={getStoredCardsTokens}
        />
        <PayWithExistingCard
          isOpen={openExistingCardModal.open}
          cardId={openExistingCardModal.id}
          toggle={closeExistingPaymentCardModal}
          userDetails={userDetails}
          refreshTable={getStoredCardsTokens}
        />
        <UpdateExistingCard
          isOpen={editModal}
          toggle={toggleEditModal}
          editInfo={editModalInfo}
          refreshTable={getStoredCardsTokens}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Cards" breadcrumbItem="Saved Cards" />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="all-customer-card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <Button
                      type="button"
                      color="primary"
                      className="round-button"
                      onClick={() => {
                        history.push("/account/purchase-history")
                      }}
                    >
                      {" "}
                      <i className="bx bx-history" />
                      <span className="purchase-history">
                        {" "}
                        Purchase History
                      </span>
                    </Button>

                    {userDetails && (
                      <h4 className="mb-3 card-title">
                        Balance:{" "}
                        {convertUSDollarsCurrency3LettersToCurrencySymbol(
                          userDetails.currency3Letters
                        )}
                        {convertAmountToTwoDecimalPlace(
                          userDetails.accountBalance
                        )}
                      </h4>
                    )}

                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        caret
                        className="w-md local-number-add-button"
                        color="success"
                      >
                        <i className="mdi mdi-plus mr-1" />
                        <span>Buy Credit</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={toggleOtherCardsModal}>
                          Buy With Cards
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={togglePayPalModal}>
                          Buy With PayPal
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <div style={{ width: "100%", overflow: "auto" }}>
                    {loading ? (
                      ""
                    ) : !orders ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          textAlign: "justify",
                        }}
                      >
                        You have no saved card on this list, kindly buy credit
                        with your card and save it to add a card to this list.
                      </p>
                    ) : orders.length ? (
                      <MDBDataTable
                        responsive
                        striped
                        hover
                        searching={false}
                        paging={false}
                        style={{
                          marginTop: "10px",
                        }}
                        data={data}
                      />
                    ) : null}
                  </div>
                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.confirm && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleLocalNumberDelete}
                        onCancel={() => setInfo({})}
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                  {orders && (
                    <Col md="12" className="mb-1">
                      <div className="desktop">
                        <img
                          style={{
                            width: "20%",
                            height: "20%",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          className="card-image"
                          src={cardImg}
                          alt="Credit Cards"
                        />
                      </div>
                      <div className="mobile">
                        <img
                          style={{
                            width: "60%",
                            height: "60%",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          className="card-image"
                          src={cardImg}
                          alt="Credit Cards"
                        />
                      </div>
                    </Col>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(BuyCredit)
