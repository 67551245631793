const callsLogColumn = [
  {
    field: "id",
    label: "#",
    sort: "asc",
  },
  {
    field: "destinationCalled",
    label: "Destination",
    sort: "asc",
    width: 150,
  },
  {
    field: "durationInSeconds",
    label: "Duration",
    sort: "asc",
    width: 150,
  },
  {
    field: "rate",
    label: "Rate/min",
    sort: "asc",
    width: 250,
  },
  {
    field: "amountBilled",
    label: "Cost",
    sort: "asc",
    width: 150,
  },
  {
    field: "startTime",
    label: "Date & Time",
    sort: "asc",
    width: 500,
  },
  {
    field: "countryOperator",
    label: "Country Operator",
    sort: "asc",
    width: 500,
  },
]

export default callsLogColumn
