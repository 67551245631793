import "assets/scss/datatables.scss"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { deleteLocalNumber, localNumbersAssigned } from "helpers/requests"
import { map } from "lodash"
import { MDBDataTableV5 } from "mdbreact"
import React, { useEffect, useRef, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import "react-datepicker/dist/react-datepicker.css"
import { withRouter } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import AssignLocalNumber from "./components/assignLocalNumbers"
import EditLocalNumber from "./components/editLocalNumber"
import localNumberColumns from "./dataTableColumns"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const LocalNumbers = props => {
  const [loading, setLoading] = useState(null)
  const [orders, setOrders] = useState(null)
  const [addModal, setAddModal] = useState(false)
  const [editModalInfo, setEditModalInfo] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [icon, setIcon] = useState(null)
  const [dataID, setDataID] = useState(null)
  const [deleteB, setDeleteB] = useState("")
  const [checkedList, setCheckedList] = useState({})
  const [checkbox1, setCheckbox1] = useState(null)
  const [isShowingTable, setIsShowingTable] = useState(true)

  const [info, setInfo] = useState({
    confirm: null,
    group: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const showLogs2 = e => {
    setCheckbox1(e)
  }

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const toggleEditModal = order => {
    setEditModalInfo(order)
    setEditModal(!editModal)
  }

  const toggleAddModal = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    const widthen = window.innerWidth
    console.log(widthen)

    if (widthen < 500) setIcon(true)
    else setIcon(null)
  })

  const getAssignedLocalNumbers = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const Assigned = await localNumbersAssigned(data)
    const servedOrders = Assigned?.data?.returnedRecords
    console.log(servedOrders)
    if (servedOrders) {
      setOrders(servedOrders)
      setLoading(null)
      setDataID(servedOrders.map(servedOrderItem => servedOrderItem.id))
    }
  }

  useEffect(() => {
    getAssignedLocalNumbers()
    return null
  }, [])

  const deleteButton = order => {
    setInfo({ confirm: true, id: order.id })
  }

  // const { orders } = props

  const getRealDate = date => {
    const str = new Date(date).toDateString()

    let splitDate = str.split(" ")
    return (
      <>
        {splitDate[0] +
          " " +
          splitDate[1] +
          " " +
          splitDate[2] +
          "," +
          " " +
          splitDate[3]}
      </>
    )
  }

  const handleGroupDelete = async event => {
    // Object.values(checkedList).map(item => item.id)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: Object.values(checkedList).map(item => item.id),
    }
    console.log(Object.values(checkedList).map(item => item.id))
    const Deleted = await deleteLocalNumber(data)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        group: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      setOrders(
        orders.filter(
          order =>
            !Object.values(checkedList).find(
              (element, index) => element.id === order.id
            )
        )
      )
      setCheckedList({})
      setIsShowingTable(false)
      setTimeout(() => {
        setIsShowingTable(true)
      }, 100)
    } else {
      setLoading(false)
      setInfo({
        group: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  const handleCheckTableItem = (e, order) => {
    let temporaryCheckedList = {}
    if (e.target.checked) {
      temporaryCheckedList = { ...checkedList, [order.id]: order }
    } else {
      temporaryCheckedList = { ...checkedList }
      delete temporaryCheckedList[order.id]
      console.log(temporaryCheckedList)
    }
    setCheckedList(temporaryCheckedList)
  }

  const data = {
    columns: localNumberColumns,
    rows: map(orders, order => ({
      ...order,
      id: (
        <>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={order.id}
              onChange={e => {
                handleCheckTableItem(e, order)
              }}
            />
            <label className="custom-control-label" htmlFor={order.id}>
              &nbsp;
            </label>
          </div>
        </>
      ),
      date: getRealDate(order.date),
      destination: order.destination,

      action: (
        <>
          <button
            type="button"
            className="btn noti-icon waves-effect"
            onClick={() => toggleEditModal(order)}
          >
            <i className="bx bx-pencil" />
          </button>
          <button
            type="button"
            onClick={() => setInfo({ confirm: true, id: order.id })}
            id="sa-params"
            className="btn noti-icon waves-effect"
          >
            <i className="bx bx-trash" />
          </button>
        </>
      ),
    })),
  }

  const handleLocalNumberDelete = async () => {
    setDeleteB(info?.id)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: [info.id],
    }
    const Deleted = await deleteLocalNumber(data)
    console.log("Hi delete!", Deleted)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      setOrders(orders.filter(order => order.id != info?.id))
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  // style={{ transform: "scale(1) translate(-30%, -30%)" }}

  console.log("Hi", checkedList)

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <AssignLocalNumber
          isOpen={addModal}
          toggle={toggleAddModal}
          refreshTable={getAssignedLocalNumbers}
        />
        <EditLocalNumber
          isOpen={editModal}
          toggle={toggleEditModal}
          editInfo={editModalInfo}
          refreshTable={getAssignedLocalNumbers}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Numbers" breadcrumbItem="Local Numbers" />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4 className="card-title mb-3">Local Numbers</h4>
                  </div>
                  <Row>
                    <Col lg="3">
                      <Button
                        type="button"
                        color="success"
                        className="w-md local-number-add-button"
                        onClick={toggleAddModal}
                      >
                        <i className="mdi mdi-plus mr-1" />
                        <span> Add Number</span>
                      </Button>
                    </Col>

                    <Col lg="3">
                      {Object.values(checkedList).length > 0 && (
                        <>
                          {!icon ? (
                            <>
                              <Button
                                style={{
                                  transform: "scale(1) translate(-224%, -45%)",
                                }}
                                color="primary"
                                className="btn noti-icon waves-effect mt-3"
                                onClick={() =>
                                  setInfo({ group: true, id: orders.id })
                                }
                              >
                                Delete
                              </Button>
                            </>
                          ) : (
                            <>
                              <button
                                style={{
                                  transform: "scale(1) translate(-13%, 190%)",
                                }}
                                className="btn noti-icon waves-effect"
                                onClick={() =>
                                  setInfo({ confirm: true, id: orders.id })
                                }
                              >
                                <i className="bx bx-trash" />
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </Col>
                    <Col lg="4"></Col>
                    <Col lg="2">
                      {orders && (
                        <Button
                          type="button"
                          color="primary"
                          className="w-md btn-square waves-effect waves-light"
                          onClick={handlePrint}
                          block
                        >
                          Print
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <div ref={componentRef}>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h4>Loading...</h4>
                      </div>
                    ) : !orders ? (
                      <p className="mt-2">
                        You do not have a Local Number yet. Kindly add one today
                        to view your log.
                      </p>
                    ) : isShowingTable && orders ? (
                      <MDBDataTableV5
                        responsive
                        striped
                        hover
                        searchTop
                        searchBottom={false}
                        checked
                        multipleCheckboxes
                        pagingTop
                        headCheckboxID="id41"
                        bodyCheckboxID="checkboxes41"
                        getValueCheckBox={e => {
                          showLogs2(e)
                        }}
                        getValueAllCheckBoxes={e => {
                          showLogs2(e)
                        }}
                        proSelect
                        data={data}
                        className="mt-4"
                        pagesAmount={4}
                        entriesOptions={[100, 200, 500, 1000]}
                        entrieslabel="How Many?"
                        entries={50}
                      />
                    ) : null}
                  </div>
                  {checkbox1 && (
                    <p>
                      {JSON.stringify(
                        checkbox1.map(e => {
                          console.log(e)
                          delete e.checkbox
                          return e
                        }) && checkbox1
                      )}
                    </p>
                  )}
                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.confirm && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnText="No"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleLocalNumberDelete}
                        onCancel={() =>
                          setInfo({
                            error_dlg: false,
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.group && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnText="No"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleGroupDelete}
                        onCancel={() =>
                          setInfo({
                            error_dlg: false,
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(LocalNumbers)
