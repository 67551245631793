const accessNumbers = {
  unitedStates: [
    { state: "California", number: "+1-619-342-3935" },
    { state: "Delaware", number: "+1-302-258-2656" },
    { state: "Florida", number: "+1-786-522-9519" },
    { state: "Georgia", number: "+1-678-248-2228" },
    { state: "Georgia", number: "+1-404-935-4217" },
    { state: "Illinois", number: "+1-773-932-1642" },
    { state: "Maryland", number: "+1-240-823-3005" },
    { state: "Maryland", number: "+1-301-685-1752" },
    { state: "Maryland", number: "+1-410-202-2479" },
    { state: "Maryland", number: "+1-443-365-2892" },
    { state: "Massachusetts", number: "+1-413-512-2155" },
    { state: "Minnesota", number: "+1-612-225-1976" },
    { state: "New Jersey", number: "+1-732-720-8098" },
    { state: "New York", number: "+1-646-862-5179" },
    { state: "North Carolina", number: "+1-704-257-1787" },
    { state: "Ohio", number: "+1-614-600-3006" },
    { state: "Pennsylvania", number: "+1-215-525-0768" },
    { state: "Rhode Island", number: "+1-401-488-2244" },
    { state: "Tennese", number: "+1-615-349-9384" },
    { state: "Texas", number: "+1-214-453-1340" },
    { state: "Texas", number: "+1-281-971-3556" },
    { state: "Virginia", number: "+1-703-543-5573" },
  ],
  canada: [
    { state: "Canada", number: "+1-289-639-2114" },
    { state: "Canada", number: "+1-403-775-9686" },
    { state: "Canada", number: "+1-416-477-2280" },
    { state: "Canada", number: "+1-587-887-1220" },
    { state: "Canada", number: "+1-604-654-2560" },
    { state: "Canada", number: "+1-613-686-6409" },
    { state: "Canada", number: "+1-647-247-1299" },
    { state: "Canada", number: "+1-705-881-1526" },
    { state: "Canada", number: "+1-778-373-8444" },
    { state: "Canada", number: "+1-780-851-7009" },
  ],
  unitedKingdom: [{ state: "United Kingdom", number: "+44-203-318-2083" }],
  ireland: [{ state: "Ireland", number: "+353-1-653-0972" }],
}

console.log(accessNumbers)

export default accessNumbers
