import { getUserAccountParametersByToken } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import cancelImg from "../../assets/images/cancel.png"
import logo from "../../assets/images/logo.svg"
import profile from "../../assets/images/profile-img.png"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import externalLinks from "../../helpers/externalLinks"
import "./styles.scss"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const PayPalCancelled = () => {
  const [loading, setLoading] = useState(null)
  const [userAccountParams, setUserAccountParams] = useState(null)

  const tokenObject = new URLSearchParams(window.location.search)
  const token = tokenObject.get("t")
  console.log(token)

  const fetchGetUserAccountParamsByToken = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      token: token,
    }
    const res = await getUserAccountParametersByToken(data)
    const userParams = res?.data
    console.log(userParams)
    if (res) {
      setLoading(null)
      setUserAccountParams(userParams)
    }
  }

  useEffect(() => {
    fetchGetUserAccountParamsByToken()
  }, [])

  const goToBuyCredit = () => {
    localStorage.setItem("userData", JSON.stringify(userAccountParams || {}))
    window.location.href = "/account/buy-credit"
  }

  const goToHome = () => {
    localStorage.setItem("userData", JSON.stringify(userAccountParams || {}))
    window.location.href = "/dashboard"
  }

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          timeout={1000}
          showConfirm={false}
          onConfirm={() => setLoading(null)}
        >
          <img className="spinning loading-logo" src={vomozLogo} />
        </SweetAlert>
      )}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Welcome back{" "}
                          {token ? userAccountParams?.firstName : ""}!
                        </h5>
                        <p>Here's the update from your PayPal transaction.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <a
                      href={externalLinks.vomozFlexLandingPage}
                      rel="noopener noreferrer"
                    >
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                    <Row>
                      <Col md="12" className="mb-4">
                        <section>
                          <img
                            style={{
                              width: "30%",
                              height: "30%",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            src={cancelImg}
                            alt="Credit Cards"
                          />
                        </section>
                      </Col>
                      <Col>
                        <section
                          style={{ margin: "15px", textAlign: "center" }}
                        >
                          <h3>Cancelled Transaction</h3>
                          <p>
                            You have cancelled this transaction. To buy with a
                            card, please click on the Buy Credit button below.
                          </p>
                        </section>
                        <section
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "20px",
                          }}
                        >
                          <Button
                            type="button"
                            color="primary"
                            style={{ width: "43%" }}
                            onClick={goToHome}
                          >
                            Go Home
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            style={{ width: "43%" }}
                            onClick={goToBuyCredit}
                          >
                            Buy Credit
                          </Button>
                        </section>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Vomoz 4.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PayPalCancelled
