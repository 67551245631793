// Protected Routes
import AccessNumbers from "pages/AccessNumbers"
import LockScreen from "pages/Authentication/auth-lock-screen"
import MobileVerification from "pages/Authentication/auth-mobile-verification"
import EmailTwoStep from "pages/Authentication/email-two-step-verification"
import ForgetPassword from "pages/Authentication/ForgotPassword"
import Login from "pages/Authentication/Login"
import MobileTwoStep from "pages/Authentication/mobile-two-step-verification"
import ResetPassword from "pages/Authentication/ResetPassword"
import SelectPhone from "pages/Authentication/SelectPhone"
import SignUp from "pages/Authentication/SignUp"
import VerifyOTP from "pages/Authentication/VerifyOTP"
import AuthorizedCountries from "pages/AuthorizedCountries"
// Public Routes
import AutoTopUp from "pages/AutoTopUp"
import BecomeAPartner from "pages/BecomeAPartner"
import BuyCredit from "pages/BuyCredit"
import CallerId from "pages/CallerId"
import CallLog from "pages/CallLog"
import Contact from "pages/Contact"
import Dashboard from "pages/Dashboard"
import DeleteAccount from "pages/DeleteAccount"
import FollowMe from "pages/FollowMe"
import FollowUs from "pages/FollowUs"
import LocalNumbers from "pages/LocalNumbers"
import MyVomozWeb from "pages/MyVomozWeb"
import PayPalCancelled from "pages/PayPalCancelled"
import PayPalSuccess from "pages/PayPalSuccess"
import PurchaseHistory from "pages/PurchaseHistory"
import Rates from "pages/Rates"
import Referral from "pages/Referral"
import Settings from "pages/Settings"
import ShowPIN from "pages/ShowPIN"
import TransferCredit from "pages/TransferCreditHistory"
import VoucherRecharge from "pages/VoucherRecharge"
import { Redirect } from "react-router-dom"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/account/settings", component: Settings },
  { path: "/account/local-numbers", component: LocalNumbers },
  { path: "/account/authorized-countries", component: AuthorizedCountries },
  { path: "/account/auto-top-up", component: AutoTopUp },
  { path: "/account/access-numbers", component: AccessNumbers },
  { path: "/account/partner", component: BecomeAPartner },
  { path: "/account/buy-credit", component: BuyCredit },
  { path: "/account/call-log", component: CallLog },
  { path: "/account/caller-id", component: CallerId },
  { path: "/account/purchase-history", component: PurchaseHistory },
  { path: "/account/contact", component: Contact },
  { path: "/account/delete-account", component: DeleteAccount },
  { path: "/account/follow-me", component: FollowMe },
  { path: "/account/social", component: FollowUs },
  { path: "/account/show-pin", component: ShowPIN },
  { path: "/account/rates/:country?", component: Rates },
  { path: "/account/referral", component: Referral },
  { path: "/account/transfer-credit", component: TransferCredit },
  { path: "/account/vomoz-card", component: VoucherRecharge },
  { path: "/lock-screen", component: LockScreen },
  // this route should be at the end of all other routes
  { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const misRoutes = []

const publicRoutes = [
  // Input Mobile Number to Receive Code
  { path: "/mobile-verification", component: MobileVerification },

  // Enter Code sent to mobile
  { path: "/auth-mobile", component: MobileTwoStep },

  // Sign Up after Mobile Verification
  { path: "/join", component: SignUp },

  // Enter Code sent to Email
  { path: "/verify-two-factor-authentication", component: EmailTwoStep },

  // Select Phone to receive code
  { path: "/select-phone", component: SelectPhone },

  // Enter Code sent to Phone
  { path: "/verify-otp", component: VerifyOTP },

  // Login after Sign Up
  { path: "/login", component: Login },

  // Input email to get code sent to email
  { path: "/forgot-password", component: ForgetPassword },

  //Password Reset
  // Change Password from email link
  { path: "/reset-password/:userGlobalID/:token", component: ResetPassword },

  //Paypal success and cancelled pages
  { path: "/ppal-success", component: PayPalSuccess },
  { path: "/ppal-cancelled", component: PayPalCancelled },
  { path: "/rlogin", component: MyVomozWeb },
]

export { authProtectedRoutes, publicRoutes, misRoutes }
