const cardDetailsColumn = [
  {
    field: "id",
    label: "#",
    sort: "asc",
  },
  {
    field: "firstName",
    label: "Name",
    sort: "asc",
    width: 136,
  },
  {
    field: "last4Digit",
    label: "Card",
    sort: "asc",
    width: 100,
  },
  {
    field: "cardMaker",
    label: "Type",
    sort: "asc",
    width: 100,
  },

  {
    field: "expirationDate",
    label: "Expiration",
    sort: "asc",
    width: 136,
  },
  {
    field: "pay",
    label: "Payment",
    sort: "asc",
    width: 100,
  },
  {
    field: "action",
    label: "Action",
  },
]

export default cardDetailsColumn
