import { Field, Form, Formik } from "formik"
import { buyCreditNewCard, userAccountParameters } from "helpers/requests"
import { Base64 } from "js-base64"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../../components/CustomSelect"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const PayWithOtherCards = ({ editInfo, isOpen, toggle, refreshTable }) => {
  const [loading, setLoading] = useState(null)
  const [info, setInfo] = useState({})
  const [passwordType, setPasswordType] = useState("password")
  const [checked, setChecked] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [dataInfo, setDataInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const [amountCredUserCanBuy, setAmountCredUserCanBuy] = useState([])

  const formikInitialValues = {
    firstName: "",
    lastName: "",
    amount: "",
    cardNumber: "",
    expirationMonthYear: "",
    cvv: "",
    saveCard: "true",
    address: "",
    cityOrTown: "",
    stateOrProvince: "",
    zipcodeOrPostalcode: "",
    country: "",
  }

  const formikValidationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(64, "Maximum of 64 characters")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(64, "Maximum of 64 characters")
      .required("Required"),
    amount: Yup.string().required("Required"),
    cardNumber: Yup.string()
      .matches(/(^[0-9]+$)/, "Card Number can only contain digit number")
      .min(15, "Minimum of 15 characters")
      .max(16, "Maximum of 16 characters")
      .required("Required"),
    expirationMonthYear: Yup.string()
      .length(4, "Only 4 digit numbers (MM/YY) are accepted")
      .matches(/(^[0-9]+$)/, "This field can only contain digit number")
      .required("Required"),
    cvv: Yup.string()
      .required("Required")
      .matches(/(^[0-9]+$)/, "cvv can only contain digit number")
      .min(3, "Minimum of 3 characters")
      .max(4, "Maximum of 4 characters")
      .required("Required"),
    address: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(64, "Maximum of 64 characters")
      .required("Required"),
    cityOrTown: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(64, "Maximum of 64 characters")
      .required("Required"),
    stateOrProvince: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(64, "Maximum of 64 characters")
      .required("Required"),
    zipcodeOrPostalcode: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(20, "Maximum of 20 characters")
      .required("Required"),
  })

  const closeSuccessFeedbackModal = () => {
    setDataInfo({ success_dlg: false })
  }

  const closeErrorFeedbackModal = () => {
    setDataInfo({ error_dlg: false })
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const fetchAmountDetails = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    if (parameters) {
      console.log(parameters.data)
      setLoading(null)
      const userParameters = parameters?.data
      console.log(userParameters)
      const amountCreditUserCanBuy = userParameters?.amountCreditUserCanBuy
      if (amountCreditUserCanBuy) {
        setAmountCredUserCanBuy(amountCreditUserCanBuy)
        console.log(amountCredUserCanBuy)
      }
    }
  }

  useEffect(() => {
    fetchAmountDetails()
  }, [])

  useEffect(() => {
    setInfo(editInfo)
  }, [editInfo])

  const handleChange = event => {
    setChecked(event)
  }

  const handlePasswordType = () => {
    if (passwordType === "number") setPasswordType("password")
    else setPasswordType("number")
  }

  return (
    <>
      {dataInfo.success_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeSuccessFeedbackModal}
        >
          {dataInfo.dynamic_description}
        </SweetAlert>
      ) : null}

      {dataInfo.error_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          error
          confirmBtnBsStyle="danger"
          onConfirm={closeErrorFeedbackModal}
        >
          {dataInfo?.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Buy With Card</ModalHeader>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={formikValidationSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log(editInfo)
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                firstName: values.firstName,
                lastName: values.lastName,
                amount: values.amount,
                cardNumber: Base64.encode(values.cardNumber),
                expirationMonthYear: values.expirationMonthYear,
                cvv: values.cvv,
                saveCard: values.saveCard === false ? "false" : "true",
                address: values.address,
                cityOrTown: values.cityOrTown,
                stateOrProvince: values.stateOrProvince,
                zipcodeOrPostalcode: values.zipcodeOrPostalcode,
                country: values.country,
              }
              console.log(data)
              const res = await buyCreditNewCard(data)
              resetForm({ values: "" })
              setLoading(false)
              if (res === undefined) {
                alert("Please check your internet connection")
              }
              if (res?.success) {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  success_dlg: true,
                  dynamic_title: "Updated!",
                  dynamic_description: res?.message,
                })
                refreshTable()
                toggle()
              } else {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  error_dlg: true,
                  dynamic_title: "Failed",
                  dynamic_description: res?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          {/* <Label>First Name</Label> */}
                          <input
                            type="text"
                            className="form-control ml-0 mr-0"
                            id="formrow-email-Input"
                            placeholder={"First Name"}
                            name="firstName"
                            value={props.values.firstName}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                          />
                          {props.touched.firstName && props.errors.firstName ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.firstName}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col xs="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Last Name</Label> */}
                          <input
                            type="text"
                            className="form-control ml-0 mobile-input"
                            id="formrow-email-Input"
                            placeholder={"Last Name"}
                            name="lastName"
                            onChange={props.handleChange}
                            value={props.values.lastName}
                            onBlur={props.handleBlur}
                          />
                          {props.touched.lastName && props.errors.lastName ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.lastName}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col xs="6">
                        <FormGroup className="select2-container">
                          <Input
                            type="text"
                            className="form-control ml-0"
                            id="formrow-email-Input"
                            placeholder={"Card Number"}
                            name="cardNumber"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.cardNumber}
                          />
                          {props.touched.cardNumber &&
                          props.errors.cardNumber ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.cardNumber}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Amount</Label> */}
                          <CustomSelect
                            classNamePrefix="select2-selection"
                            name="amount"
                            placeholder={<div>Select Amount</div>}
                            value={props.values.amount}
                            options={amountCredUserCanBuy.map(item => ({
                              label: item,
                              value: item,
                            }))}
                            onChange={props.handleChange("amount")}
                          />
                          {props.touched.amount && props.errors.amount ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.amount}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          <Input
                            type="text"
                            className="form-control ml-0"
                            id="formrow-email-Input"
                            placeholder={"Expiration MM/YY"}
                            name="expirationMonthYear"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.expirationMonthYear}
                          />
                          <FormText>Example: 0224 for (02/24)</FormText>
                          {props.touched.expirationMonthYear &&
                          props.errors.expirationMonthYear ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.expirationMonthYear}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Last Name</Label> */}
                          <InputGroup>
                            <Input
                              type={passwordType}
                              className="form-control ml-0"
                              id="formrow-email-Input"
                              placeholder={"CVV"}
                              name="cvv"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.cvv}
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                              }}
                              onKeyPress={`return e.charCode >= 48 && e.charCode <= 57`}
                            />
                            <div
                              className="input-group-append"
                              style={{ cursor: "pointer" }}
                              onClick={handlePasswordType}
                            >
                              <span className="input-group-text">
                                {passwordType === "number" ? (
                                  <i class="fa fa-low-vision"></i>
                                ) : (
                                  <i class="fa fa-eye" aria-hidden="true"></i>
                                )}
                              </span>
                            </div>
                          </InputGroup>
                          {props.touched.cvv && props.errors.cvv ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.cvv}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col xs="6">
                        <FormGroup className="select2-container">
                          <Input
                            type="text"
                            className="form-control ml-0"
                            id="formrow-email-Input"
                            placeholder={"Address"}
                            name="address"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.address}
                          />
                          {props.touched.address && props.errors.address ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.address}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          <Input
                            type="text"
                            className="form-control ml-0 mobile-input"
                            id="formrow-email-Input"
                            placeholder={"City or Town"}
                            name="cityOrTown"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.cityOrTown}
                          />
                          {props.touched.cityOrTown &&
                          props.errors.cityOrTown ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.cityOrTown}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          <Input
                            type="text"
                            className="form-control ml-0"
                            id="formrow-email-Input"
                            placeholder={"State or Province"}
                            name="stateOrProvince"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.stateOrProvince}
                          />
                          {props.touched.stateOrProvince &&
                          props.errors.stateOrProvince ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.stateOrProvince}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          <Input
                            type="text"
                            className="form-control ml-0 mobile-input"
                            id="formrow-email-Input"
                            placeholder={"Zip/Postal Code"}
                            name="zipcodeOrPostalcode"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.zipcodeOrPostalcode}
                          />
                          {props.touched.zipcodeOrPostalcode &&
                          props.errors.zipcodeOrPostalcode ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.zipcodeOrPostalcode}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {console.log(props.values)}
                      <Col xs="6">
                        <FormGroup className="select2-container">
                          <Input
                            type="text"
                            className="form-control ml-0"
                            id="formrow-email-Input"
                            placeholder={"Country"}
                            name="country"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.country}
                          />
                          {props.touched.country && props.errors.country ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.country}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <div>
                            <label>
                              <Field
                                type="checkbox"
                                name="saveCard"
                                onChange={props.handleChange}
                                className="mr-2 mt-2"
                              />
                              Securely save card
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="6" md="12">
                        <FormGroup className="select2-container">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          color="primary"
                          disabled={!disableButton}
                        >
                          {loading ? "Submitting" : "Submit"}
                        </Button>
                      </Col>
                      <Col md={3} style={{ flex: "1" }}>
                        <Button color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <br />
                      <i
                        style={{
                          color: "#000",
                          fontSize: "11px",
                          marginLeft: "10px",
                        }}
                      >
                        Kindly check the captcha checkbox to enable this "Send"
                        button
                      </i>
                    </Row>
                  </ModalBody>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default PayWithOtherCards
