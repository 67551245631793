import React from "react"
import { Col, Row } from "reactstrap"
import externalLinks from "../../helpers/externalLinks"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block">
                © Copyright {new Date().getFullYear()}. VomozFlex All Rights
                Reserved. | 4.0.1 (1031)
              </div>
            </Col>
            <Col sm={6}>
              <div className="text-sm-center d-none d-sm-block">
                <a
                  style={{ color: "#74788d" }}
                  href={externalLinks.vomozFlexLandingPagePrivacyPolicy}
                >
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a
                  style={{ color: "#74788d" }}
                  href={externalLinks.vomozFlexLandingPagePrivacyPolicy}
                >
                  Terms of Service
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
