import "assets/scss/datatables.scss"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  availableFollowMeNumbers,
  canceFollowMeNumber,
  currentFollowMeService,
} from "helpers/requests"
import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import "react-datepicker/dist/react-datepicker.css"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import AssignFollowMeNumber from "./components/AssignFollowMe/index"
import EditFollowMeNumber from "./components/EditFollowMe/index"
import FollowMeNumberColumn from "./dataTableColumns"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const FollowMe = () => {
  const [loading, setLoading] = useState(null)
  const [orders, setOrders] = useState([])
  const [followMeLog, setFollowMeLog] = useState([])
  const [idInfo, setIdInfo] = useState({})
  const [addModal, setAddModal] = useState(false)
  const [editModalInfo, setEditModalInfo] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [icon, setIcon] = useState(null)
  const [deleteB, setDeleteB] = useState("")

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    recordId: "",
    deleting: null,
  })

  const toggleEditModal = order => {
    setEditModalInfo(order)
    setEditModal(!editModal)
  }

  const toggleAddModal = () => {
    setAddModal(!addModal)
    setIdInfo(followMeLog)
  }
  useEffect(() => {
    const widthen = window.innerWidth
    console.log(widthen)

    if (widthen < 500) setIcon(true)
    else setIcon(null)
  })

  const getCurrentFollowMeNumbers = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const Assigned = await currentFollowMeService(data)
    console.log(Assigned)
    const servedOrders = Assigned?.data?.returnedRecords
    console.log(servedOrders)
    if (servedOrders) {
      setOrders(servedOrders)
      setLoading(null)
    }
  }

  useEffect(() => {
    getCurrentFollowMeNumbers()
  }, [])

  const getAvailableFollowMeNumbers = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const Assigned = await availableFollowMeNumbers(data)
    const servedOrders = Assigned?.data?.returnedRecords
    if (servedOrders) {
      setFollowMeLog(servedOrders)
      setLoading(null)
    }
  }

  useEffect(() => {
    getAvailableFollowMeNumbers()
  }, [])

  const getRealDate = date => {
    if (date.length === 0) return ""
    const str = new Date(date).toDateString()
    let splitDate = str.split(" ")
    return (
      <>
        {splitDate[0] +
          " " +
          splitDate[1] +
          " " +
          splitDate[2] +
          "," +
          " " +
          splitDate[3]}
      </>
    )
  }

  const data = {
    columns: FollowMeNumberColumn,
    rows: [
      ...orders.map((row, order) => ({
        ...row,
        id: <>{order + 1}</>,
        returnDate: getRealDate(row?.returnDate),
        setupDate: getRealDate(row?.setupDate),
        destination: row?.destination,

        action: (
          <>
            <button
              type="button"
              className="btn noti-icon waves-effect"
              onClick={() => toggleEditModal(row)}
            >
              <i className="bx bx-pencil" />
            </button>
            <button
              type="button"
              className="btn noti-icon waves-effect"
              onClick={() => setInfo({ confirm: true, recordId: row.recordId })}
              recordId="sa-params"
            >
              <i className="bx bx-trash" />
            </button>
          </>
        ),
      })),
    ],
  }
  console.log(data)

  const handleLocalNumberDelete = async event => {
    setDeleteB(info?.recordId)
    setLoading(true)
    setInfo({
      dynamic_title: "Deleting",
      dynamic_description: "",
    })
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      recordId: info?.recordId,
    }
    const Deleted = await canceFollowMeNumber(data)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      setOrders(orders.filter(order => order.recordId != info?.recordId))
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <AssignFollowMeNumber
          isOpen={addModal}
          toggle={toggleAddModal}
          idInfo={idInfo}
          refreshTable={getCurrentFollowMeNumbers}
        />

        <EditFollowMeNumber
          isOpen={editModal}
          toggle={toggleEditModal}
          editInfo={editModalInfo}
          refreshTable={getCurrentFollowMeNumbers}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Numbers" breadcrumbItem="Follow Me Numbers" />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4 className="card-title mb-3">
                      Current Follow Me Service
                    </h4>

                    <Button
                      type="button"
                      color="success"
                      className="w-md local-number-add-button"
                      onClick={toggleAddModal}
                    >
                      <i className="mdi mdi-plus mr-1" />
                      <span> Add Number</span>
                    </Button>
                  </div>
                  {loading ? (
                    ""
                  ) : orders.length === 0 ? (
                    "You do not have a Follow-Me Number yet. Kindly add one now to view your log."
                  ) : orders.length > 0 ? (
                    <MDBDataTable
                      responsive
                      striped
                      hover
                      data={data}
                      className="mt-4"
                      pagesAmount={4}
                      entriesOptions={[100, 200, 500, 1000]}
                      entrieslabel="How Many?"
                      entries={50}
                    />
                  ) : null}

                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.confirm && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleLocalNumberDelete}
                        onCancel={() =>
                          setInfo({
                            error_dlg: false,
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(FollowMe)
