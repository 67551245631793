import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Modal, ModalHeader } from "reactstrap"
import "../../styles.scss"
import Form from "./AssignFollowMeForm"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const AssignFollowMeNumber = props => {
  const [dataInfo, setDataInfo] = useState({})
  const [initialVoicemailOption, setInitialVoicemailOption] = useState("")

  const getUserAccountParameters = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const res = await userAccountParameters(data)
    console.log(res)
    const userParameters = res?.data
    if (userParameters) {
      setInitialVoicemailOption(userParameters?.email)
    }
  }
  console.log(initialVoicemailOption)

  useEffect(() => {
    getUserAccountParameters()
  }, [])

  useEffect(() => {
    setDataInfo(idInfo)
  }, [])

  const { isOpen, toggle, idInfo, refreshTable } = props
  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Assign Follow Me Number</ModalHeader>
          <Form
            toggle={toggle}
            refreshTable={refreshTable}
            initialVoicemailOption={initialVoicemailOption}
          />
        </div>
      </Modal>
    </>
  )
}

AssignFollowMeNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AssignFollowMeNumber
