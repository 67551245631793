import { Form, Formik } from "formik"
import {
  assignFollowMeNumber,
  availableFollowMeNumbers,
} from "helpers/requests"
import { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import "react-phone-input-2/lib/style.css"
import { RadioButton, RadioGroup } from "react-radio-buttons"
import Select from "react-select"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import "../../styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const AssignFollowMeForm = props => {
  const { initialVoicemailOption, refreshTable, toggle } = props

  const [loading, setLoading] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [followMeNumberLabel, setFollowMeNumberLabel] = useState("")
  const [options, setOptions] = useState([])
  const [values, setValues] = useState("")
  const [showReturnDate, setShowReturnDate] = useState(false)
  const [showVoicemailOption, setShowVoicemailOption] = useState(false)

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const cleanObject = obj => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName]
      }
    }
    return obj
  }

  const onCaptchaChange = value => {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const getAvailableFollowMeNumbers = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const assigned = await availableFollowMeNumbers(data)
    const res = assigned?.data?.returnedRecords
    if (res) {
      setOptions(
        res.map(follow => ({
          label: follow.followMeNumber,
          value: follow.id,
        }))
      )
    }
  }

  useEffect(() => {
    getAvailableFollowMeNumbers()
  }, [])

  const handleReturnDateRadio = e => {
    setValues(prev => ({
      ...prev,
      returnRadio: e === "returnDate" ? "true" : "false",
    }))
    if (e === "returnDate") {
      setShowReturnDate(true)
    } else {
      setShowReturnDate(false)
    }
  }

  const handleVoicemailRadio = e => {
    setValues(prev => ({
      ...prev,
      voicemailRadio: e === "voicemail" ? "true" : "false",
    }))
    if (e === "voicemail") {
      setShowVoicemailOption(true)
    } else {
      setShowVoicemailOption(false)
    }
  }

  const formikInitialValues = {
    followMeNumber: "",
    destinationNumber: "",
    voicemailOption: showVoicemailOption === true ? initialVoicemailOption : "",
    pickUpPhone: true,
    returnDateOption: true,
    returnDate: "",
    voicemailRadio: "",
  }

  const formatCalendar = oldFormat => {
    const returnDateYear = oldFormat.slice(0, 4)
    const returnDateMonth = oldFormat.slice(5, 7)
    const returnDateDay = oldFormat.slice(8, 10)
    const formatReturnDateMonth = returnDateMonth => {
      switch (returnDateMonth) {
        case "01":
          return "January"
          break
        case "02":
          return "February"
          break
        case "03":
          return "March"
          break
        case "04":
          return "April"
          break
        case "05":
          return "May"
          break
        case "06":
          return "June"
          break
        case "07":
          return "July"
          break
        case "08":
          return "August"
          break
        case "09":
          return "September"
          break
        case "10":
          return "October"
          break
        case "11":
          return "November"
          break
        case "12":
          return "December"
          break
        default:
          return "Invalid month"
      }
    }
    const formattedMonth = formatReturnDateMonth(returnDateMonth)
    return oldFormat === ""
      ? "No date selected"
      : formattedMonth + " " + returnDateDay + ", " + returnDateYear
  }

  const [dataValues, setDataValues] = useState({
    destinationNumber: "",
  })

  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Formik
        initialValues={{
          followMeNumber: "",
          destinationNumber: "",
          voicemailOption: initialVoicemailOption,
          pickUpPhone: null,
          returnDateOption: null,
          returnDate: "",
          voicemailRadio: "",
        }}
        validationSchema={Yup.object({
          followMeNumber: Yup.string().required("Required"),
          destinationNumber: Yup.string()
            .matches(/(^[0-9]+$)/, "This field can only contain digit number")
            .min(10, "Minimum of 10 digits")
            .max(20, "Maximum of 20 digits")
            .required("Required"),
          voicemailOption: Yup.string().email("Invalid email address"),
          returnDate: Yup.date(),
        })}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true)
          const data = {
            orgId: "939001",
            userGlobalId: user?.userGlobalId,
            id: values.followMeNumber,
            destination: values.destinationNumber,
            voicemailOption:
              showVoicemailOption === true ? values.voicemailOption : "hangup",
            returnDate: showReturnDate === true ? values.returnDate : "",
          }
          console.log(data)
          const Assigned = await assignFollowMeNumber(data)
          // console.log(values)
          resetForm({ values: "" })
          setLoading(false)
          if (Assigned === undefined) {
            alert("Please check your internet connection")
          }
          if (Assigned?.success) {
            console.log(Assigned)
            setLoading(false)
            setInfo({
              confirm: false,
              success_dlg: true,
              dynamic_title: "Added!",
              dynamic_description: Assigned?.message,
            })
            refreshTable()
            toggle()
          } else {
            setLoading(false)
            setInfo({
              confirm: false,
              error_dlg: true,
              dynamic_title: "Failed!",
              dynamic_description: Assigned?.message,
            })
          }
        }}
      >
        {props => {
          return (
            <Form>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <FormGroup className="select2-container">
                      <Label>Select Follow Me Number</Label>
                      <Select
                        name="followMeNumber"
                        // value={props.values.followMeNumber}
                        options={options}
                        onChange={value => {
                          setFollowMeNumberLabel(value.label)
                          props.setFieldValue("followMeNumber", value.value)
                        }}
                        onBlur={() =>
                          props.setFieldTouched("followMeNumber", true)
                        }
                      />
                      {props.touched.followMeNumber &&
                      props.errors.followMeNumber ? (
                        <p style={{ color: "red", fontSize: "11px" }}>
                          {props.errors.followMeNumber}
                        </p>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="formrow-destination-Input">
                        Destination Number
                      </Label>

                      <Input
                        type="text"
                        value={props.values.destinationNumber}
                        name="destinationNumber"
                        id="formrow-destination-Input"
                        className="form-control"
                        placeholder="Enter your destination number"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />

                      {props.touched.destinationNumber &&
                      props.errors.destinationNumber ? (
                        <p style={{ color: "red", fontSize: "11px" }}>
                          {props.errors.destinationNumber}
                        </p>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup className="select2-container">
                      <Label for="formrow-email-Input">
                        When Am I Returning?
                      </Label>
                      <RadioGroup onChange={handleReturnDateRadio} horizontal>
                        <RadioButton
                          name="returnRadio"
                          value="returnDate"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          Return Date
                        </RadioButton>

                        <RadioButton
                          name="returnRadio"
                          value="iDontKnow"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          I don't Know
                        </RadioButton>
                      </RadioGroup>
                    </FormGroup>
                  </Col>

                  {showReturnDate ? (
                    <Col md={12}>
                      <FormGroup>
                        <div className="form-group row mt-3">
                          <label
                            htmlFor="example-date-input"
                            className="col-md-3 col-form-label"
                          >
                            Return Date
                          </label>
                          <div className="col-md-9">
                            <input
                              name="returnDate"
                              className="form-control"
                              type="date"
                              id="example-date-input"
                              onChange={props.handleChange}
                              value={props.values.returnDate}
                              onBlur={props.handleBlur}
                            />
                            {props.touched.returnDate &&
                            props.errors.returnDate ? (
                              <p style={{ color: "red", fontSize: "11px" }}>
                                {props.errors.returnDate}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  ) : null}

                  <Col>
                    <FormGroup className="select2-container">
                      <Label for="formrow-email-Input">
                        If I Didn't Pick Up My Phone
                      </Label>
                      <RadioGroup onChange={handleVoicemailRadio} horizontal>
                        <RadioButton
                          name="voicemailRadio"
                          value="voicemail"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          Go to My Voicemail
                        </RadioButton>
                        <RadioButton
                          name="voicemailRadio"
                          value="hangup"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          Hangup!
                        </RadioButton>
                      </RadioGroup>
                    </FormGroup>
                  </Col>

                  {showVoicemailOption ? (
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-email-Input">
                          Voicemail Option
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="formrow-name-Input"
                          placeholder={"Enter Voicemail Details"}
                          name="voicemailOption"
                          onChange={props.handleChange}
                          value={props.values.voicemailOption}
                          onBlur={props.handleBlur}
                        />
                        {props.touched.voicemailOption &&
                        props.errors.voicemailOption ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.voicemailOption}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col lg="6">
                    <FormGroup className="select2-container">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Col md={2} style={{ flex: "1" }}></Col>
                  <Col md={5} style={{ flex: "2" }}>
                    <Button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      onClick={() => setInfo({ confirm: true })}
                      color="primary"
                      disabled={!disableButton}
                    >
                      {loading ? "Sending..." : "Send"}
                    </Button>
                  </Col>
                  <Col md={3} style={{ flex: "1" }}>
                    <Button color="link" onClick={toggle}>
                      Cancel
                    </Button>
                  </Col>
                  <Col md={2} style={{ flex: "1" }}></Col>
                  <br />
                  <i
                    style={{
                      color: "#000",
                      fontSize: "11px",
                      marginLeft: "10px",
                    }}
                  >
                    Kindly check the captcha checkbox to enable this "Send"
                    button
                  </i>
                </Row>
                {console.info(props.values)}
              </ModalBody>
              {info.confirm ? (
                <SweetAlert
                  title="Verify Your Information"
                  showCancel
                  confirmBtnText="Activate"
                  confirmBtnBsStyle="success"
                  cancelBtnText="Cancel"
                  cancelBtnBsStyle="danger"
                  onConfirm={props.handleSubmit}
                  onCancel={() =>
                    setInfo({
                      confirm: false,
                      error_dlg: true,
                      dynamic_title: "Cancelled",
                      dynamic_description: "Process has been terminated!",
                    })
                  }
                >
                  <span>
                    You are about to setup a <b>Follow Me Service</b>. Here's
                    the summary of your information. Please click "Activate"
                    button to confirm your selection or "Cancel" to exit. <br />
                    <br />
                    <ol>
                      <li>
                        Follow Me Number:&nbsp;
                        {followMeNumberLabel}{" "}
                      </li>
                      <li>
                        Destination Number:&nbsp;
                        {props.values.destinationNumber}{" "}
                      </li>
                      <li>
                        Voicemail Option:&nbsp;
                        {props.values.voicemailOption
                          ? props.values.voicemailOption
                          : "None"}
                      </li>
                      <li>
                        Return Date:&nbsp;{" "}
                        {formatCalendar(props.values.returnDate)}
                      </li>
                    </ol>
                    <br />
                    <strong>
                      Note: This service attracts a non-refundable service
                      charge of $9.99 monthly. The service will be automatically
                      deactivated 24 hours after your Return Date to avoid the
                      monthly charge.{" "}
                    </strong>
                  </span>
                </SweetAlert>
              ) : null}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default AssignFollowMeForm
