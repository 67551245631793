const FooterMessage = () => {
  return (
    <footer>
      <p>
        Copyright © {new Date().getFullYear()} Vomoz Platforms | 4.0.1 (1031).
        All Rights Reserved.
      </p>
    </footer>
  )
}

export default FooterMessage
