import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { Form, Formik } from "formik"
import { resetPassword } from "helpers/requests"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import logo from "../../assets/images/logo.svg"
import profile from "../../assets/images/profile-img.png"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const ResetPassword = () => {
  const [loading, setLoading] = useState(null)
  const { token, userGlobalID } = useParams()
  const [password1Type, setPassword1Type] = useState("password")
  const [password2Type, setPassword2Type] = useState("password")

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  console.log(navigator)

  const handlePassword1Type = () => {
    if (password1Type === "text") setPassword1Type("password")
    else setPassword1Type("text")
  }
  const handlePassword2Type = () => {
    if (password2Type === "text") setPassword2Type("password")
    else setPassword2Type("text")
  }

  return (
    <React.Fragment>
      {info.success_dlg ? (
        <SweetAlert
          title={info.dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.error_dlg ? (
        <SweetAlert
          title={info.dynamic_title}
          error
          confirmBtnBsStyle="danger"
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Reset Password</h5>
                        <span>
                          <p>Enter your details</p>
                        </span>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="dashboard">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Formik
                      initialValues={{
                        newPassword: "",
                        confirmNewPassword: "",
                      }}
                      validationSchema={Yup.object({
                        newPassword: Yup.string()
                          .min(8, "Must be 8 characters or more")
                          .max(32, "Must not exceed 32 characters")
                          .matches(
                            /([0-9])/,
                            "Must contain at least one number"
                          )
                          .matches(
                            /([a-z])/,
                            "Must contain at least one lowercase letter"
                          )
                          .matches(
                            /([A-Z])/,
                            "Must contain at least one uppercase letter"
                          )
                          .matches(
                            /([@#$%!])/,
                            "Must contain at least one special character (@#$%!)"
                          )
                          .required("Required"),
                        confirmNewPassword: Yup.string().when("newPassword", {
                          is: val => (val && val.length > 0 ? true : false),
                          then: Yup.string().oneOf(
                            [Yup.ref("newPassword")],
                            "Both passwords need to be the same"
                          ),
                        }),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        console.log(values)
                        setLoading(true)
                        const data = {
                          orgId: "939001",
                          userGlobalId: userGlobalID,
                          token: token,
                          newPassword: values.newPassword,
                        }
                        console.log(data)
                        const response = await resetPassword(data)
                        resetForm({ values: "" })
                        setLoading(false)
                        if (response === undefined) {
                          alert("Please check your internet connection")
                        }
                        if (response.success === false) {
                          console.log(response)
                          setLoading(false)
                          setInfo({
                            confirm: false,
                            error_dlg: true,
                            dynamic_title: "Failed!",
                            dynamic_description: response?.message,
                          })
                        } else {
                          setLoading(false)
                          setInfo({
                            confirm: false,
                            success_dlg: true,
                            dynamic_title: "Done!",
                            dynamic_description: response?.message,
                          })
                          setTimeout(() => {
                            window.location.href = "/login"
                          }, 200)
                        }
                      }}
                    >
                      {props => {
                        return (
                          <Form className="form-horizontal">
                            <FormGroup>
                              <Label for="newPassword">New Password</Label>
                              <InputGroup>
                                <Input
                                  name="newPassword"
                                  className="form-control mr-0 ml-0"
                                  id="newPassword"
                                  placeholder="Enter New Password"
                                  onBlur={props.handleBlur}
                                  onChange={props.handleChange}
                                  type={password1Type}
                                  value={props.values.newPassword}
                                />
                                <div
                                  className="input-group-append"
                                  style={{ cursor: "pointer" }}
                                  onClick={handlePassword1Type}
                                >
                                  <span className="input-group-text">
                                    {password1Type === "text" ? (
                                      <i class="fa fa-low-vision"></i>
                                    ) : (
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </InputGroup>
                              {props.touched.newPassword &&
                              props.errors.newPassword ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.newPassword}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label for="confirmNewPassword">
                                Confirm New Password
                              </Label>
                              <InputGroup>
                                <Input
                                  name="confirmNewPassword"
                                  className="form-control mr-0 ml-0"
                                  id="confirmNewPassword"
                                  placeholder="Enter New Password again"
                                  onChange={props.handleChange}
                                  type={password2Type}
                                  onBlur={props.handleBlur}
                                  value={props.values.confirmNewPassword}
                                />
                                <div
                                  className="input-group-append"
                                  style={{ cursor: "pointer" }}
                                  onClick={handlePassword2Type}
                                >
                                  <span className="input-group-text">
                                    {password2Type === "text" ? (
                                      <i class="fa fa-low-vision"></i>
                                    ) : (
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </InputGroup>
                              {props.touched.confirmNewPassword &&
                              props.errors.confirmNewPassword ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.confirmNewPassword}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup className="row mb-0">
                              <Col xs={12} className="text-right">
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                >
                                  {loading ? "Resetting..." : "Reset Password"}
                                </button>
                              </Col>
                            </FormGroup>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Never mind!{" "}
                  <Link
                    to="page-login"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Take me back to login
                  </Link>{" "}
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
