import { Form, Formik } from "formik"
import { changeEmail } from "helpers/requests"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "react-router-dom"
import { Button, FormGroup, Input, InputGroup, Label } from "reactstrap"
import * as Yup from "yup"

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
  },
}

const user = JSON.parse(localStorage.getItem("userData")) || {}

const EmailForm = ({ initialEmail }) => {
  const [loading, setLoading] = useState(null)
  const [passwordType, setPasswordType] = useState("password")
  const [disableButton, setDisableButton] = useState(false)
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const handlePasswordType = () => {
    if (passwordType === "text") setPasswordType("password")
    else setPasswordType("text")
  }

  const formikInitialValues = {
    currentEmail: initialEmail,
    newEmail: "",
    password: "",
  }

  return (
    <>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={Yup.object({
          newEmail: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={async (values, { resetForm }) => {
          console.log(values)
          setLoading(true)
          const data = {
            orgId: "939001",
            userGlobalId: user?.userGlobalId,
            currentEmail: initialEmail,
            newEmail: values.newEmail,
            password: values.password,
          }
          console.log(data)
          const res = await changeEmail(data)
          resetForm({ values: "" })
          setLoading(null)
          if (res === undefined) {
            alert("Please check your internet connection")
            setLoading(null)
          }
          if (res?.success === false) {
            console.log(res)
            setLoading(null)
            setWarningFeedbackModal(prev => ({
              ...prev,
              open: true,
              openFor: "mobile",
              success: false,
              error: true,
              title: "Failed!",
              message: res.message,
            }))
          } else if (res?.success === true) {
            console.log(res)
            setFeedbackModal(prev => ({
              ...prev,
              open: true,
              openFor: "mobile",
              success: true,
              error: false,
              title: "Done!",
              message: res.message,
            }))
          }
        }}
      >
        {props => {
          return (
            <Form className="form-horizontal">
              <FormGroup>
                <Label>Current Email</Label>
                <p>{initialEmail}</p>
              </FormGroup>

              <FormGroup>
                <Label>New Email</Label>
                <InputGroup>
                  <Input
                    name="newEmail"
                    type="text"
                    className="form-control mr-0 ml-0"
                    placeholder="Enter your new email address"
                    onChange={props.handleChange}
                    value={props.values.newEmail}
                    onBlur={props.handleBlur}
                  />
                </InputGroup>
                {props.touched.newEmail && props.errors.newEmail ? (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {props.errors.newEmail}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label>Password</Label>
                <InputGroup>
                  <Input
                    name="password"
                    type={passwordType}
                    className="form-control"
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                    placeholder="Enter your password"
                    onChange={props.handleChange}
                    value={props.values.password}
                    onBlur={props.handleBlur}
                  />
                  <div
                    className="input-group-append"
                    style={{ cursor: "pointer" }}
                    onClick={handlePasswordType}
                  >
                    <span className="input-group-text">
                      {passwordType === "text" ? (
                        <i class="fa fa-low-vision"></i>
                      ) : (
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                </InputGroup>
                {props.touched.password && props.errors.password ? (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {props.errors.password}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup className="select2-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onCaptchaChange}
                />
              </FormGroup>

              <FormGroup>
                <div
                  className="mt-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <button
                    className="btn btn-primary waves-effect waves-light "
                    type="submit"
                    style={{ width: "42%" }}
                    disabled={!disableButton}
                  >
                    {loading ? "Submitting" : "Submit"}
                  </button>

                  <Button style={{ width: "42%" }} type="button" color="link">
                    <Link to="/">{loading ? "Cancelling" : "Cancel"}</Link>
                  </Button>
                </div>

                <i style={{ color: "#000", fontSize: "11px" }}>
                  Kindly click on the captcha field to enable the "Submit"
                  button
                </i>
              </FormGroup>
            </Form>
          )
        }}
      </Formik>
      {feedbackModal.open && (
        <SweetAlert
          title={feedbackModal.title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeFeedbackModal}
        >
          {feedbackModal.message}
        </SweetAlert>
      )}
      <>
        {warningFeedbackModal.open && (
          <SweetAlert
            title={warningFeedbackModal.title}
            danger
            confirmBtnBsStyle="danger"
            onConfirm={closeWarningFeedbackModal}
          >
            {warningFeedbackModal.message}
          </SweetAlert>
        )}
      </>
    </>
  )
}

export default EmailForm
