/** @format */

const externalLinks = {
  vomozFlexLandingPage: "https://vomozflex.com/",
  vomozFlexLandingPagePrivacyPolicy:
    "https://vomozflex.com/terms-and-conditions",
  vomozFlexAppJoin: "https://app.vomozflex.com/join",
  vomozFlexAppLogin: "https://app.vomozflex.com/login",
  vomozFlexResetPassword: "https://app.vomozflex.com/reset-password/",
  vomozFlexAndroidApp:
    "https://play.google.com/store/apps/details?id=com.vomoz.systems.apps.android",
  vomozFlexIosApp:
    "https://apps.apple.com/us/app/vomoz-communications/id960996015",
  vomozGroupLandingPage: "https://vomozgroup.com",
}

export default externalLinks
