import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Form from "./ChangeEmailForm"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const ChangeEmail = () => {
  const [load, setLoad] = useState(null)
  const [userEmail, setUserEmail] = useState(null)

  const getUserParameters = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setUserEmail(userParameters.email)
      setLoad(true)
    }
  }

  useEffect(() => {
    getUserParameters()
  }, [])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary">
          <Row>
            <Col>
              <div className="text-white p-4">
                <p>Change Your Email</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-2 px-0">
          <div className="pt-3">
            {load ? <Form initialEmail={userEmail} /> : null}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ChangeEmail.propTypes = {
  t: PropTypes.any,
}

export default ChangeEmail
