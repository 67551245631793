const followMeNumberColumn = [
  {
    field: "id",
    label: "#",
    sort: "asc",
  },
  {
    field: "followMeNumber",
    label: "Follow Me Number",
    sort: "asc",
    width: 136,
  },
  {
    field: "destination",
    label: "Destination",
    sort: "asc",
    width: 100,
  },
  {
    field: "voicemailOption",
    label: "Voicemail Option",
    sort: "asc",
  },
  {
    label: "Setup Date",
    field: "setupDate",
    sort: "asc",
    width: 136,
  },
  {
    label: "Return Date",
    field: "returnDate",
    sort: "asc",
    width: 136,
  },
  {
    label: "Action",
    field: "action",
  },
]

export default followMeNumberColumn
