const localNumberColumn = [
  {
    field: "id",
    label: "",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Name",
    },
  },
  {
    field: "localNumber",
    label: "Local Number",
    sort: "asc",
    width: 136,
  },
  {
    field: "destination",
    label: "Destination",
    sort: "asc",
    width: 100,
  },
  {
    field: "nickname",
    label: "Nickname",
    sort: "asc",
  },
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 136,
  },
  {
    label: "Action",
    field: "action",
  },
]

export default localNumberColumn
