import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const EcommerceOrdersModal = props => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  // const [logs, setLogs] = useState([])
  const { isOpen, toggle, transaction = {} } = props
  const {
    senderFirstName,
    senderLastName,
    senderMobile,
    senderEmail,
    receiverFirstName,
    receiverLastName,
    receiverMobile,
    receiverEmail,
    amount,
    transactionFee,
    date,
    comment,
  } = transaction

  const localDate = new Date(date).toDateString()
  const realDate = str => {
    let splitDate = str.split(" ")
    return (
      splitDate[0] +
      " " +
      splitDate[1] +
      " " +
      splitDate[2] +
      "," +
      " " +
      splitDate[3]
    )
  }

  const localTime = new Date(date).toLocaleTimeString()
  const realTime = date => {
    return date.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1")
  }

  const calcAmount = amount => {
    return Number.parseFloat(amount).toFixed(2)
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        {/* {logs.map((transaction, index) => ( */}
        <div>
          <ModalHeader toggle={toggle}>Transfer Details</ModalHeader>
          <ModalBody>
            <p className="mb-4">
              Receiver Name:{" "}
              <span className="text-primary">
                {receiverFirstName}&nbsp;
                {receiverLastName}
              </span>
            </p>
            <p className="mb-4">
              Receiver Phone Number:{" "}
              <span className="text-primary">{receiverMobile}</span>
            </p>
            <p className="mb-4">
              Receiver Email Address:{" "}
              <span className="text-primary">{receiverEmail}</span>
            </p>
            <p className="mb-4">
              Sender Name:{" "}
              <span className="text-primary">
                {senderFirstName}&nbsp;
                {senderLastName}
              </span>
            </p>
            <p className="mb-4">
              Sender Phone Number:{" "}
              <span className="text-primary">{senderMobile}</span>
            </p>
            <p className="mb-4">
              Sender Email Address:{" "}
              <span className="text-primary">{senderEmail}</span>
            </p>
            <p className="mb-4">
              Transaction Amount:{" "}
              <span className="text-primary">${calcAmount(amount)}</span>
            </p>
            <p className="mb-4">
              Transaction Charges:{" "}
              <span className="text-primary">${transactionFee}</span>
            </p>

            <p className="mb-4">
              Transaction Date:{" "}
              <span className="text-primary">{realDate(localDate)}</span>
            </p>
            <p className="mb-4">
              Transaction Time:{" "}
              <span className="text-primary">{realTime(localTime)}</span>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="danger" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </div>
        {/* ))} */}
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
