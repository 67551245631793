//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { callerIdsVerified, deleteCallerId } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import AddCallerIdProcess from "./components/AddCallerIdProcessModal"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const CallerId = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])

  const toggleModal = () => {
    setOpen(!open)
  }

  const [info, setInfo] = useState({
    confirm: false,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    deleting: null,
    param: "",
  })

  const getCallerIdVerified = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const callerIdentity = await callerIdsVerified(data)
    const callerIdList = callerIdentity?.data?.returnedRecords
    if (callerIdList) {
      setLogs(callerIdList)
      setLoading(false)
    }
  }

  useEffect(() => {
    getCallerIdVerified()
  }, [])

  const deleteButton = order => {
    setInfo({ confirm: true, id: order.id })
  }

  const handleDeleteDialogOpen = id => () => {
    setInfo(prev => ({ ...prev, confirm: true, param: id }))
  }

  const handleDelete = async () => {
    setLoading(true)
    console.log(info)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      id: info.param,
    }

    const Deleted = await deleteCallerId(data)
    console.log(Deleted)
    if (Deleted?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Deleted",
        dynamic_description: Deleted?.message,
      })
      getCallerIdVerified()
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed",
        dynamic_description: Deleted?.message,
      })
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          timeout={2000}
          showConfirm={false}
          onConfirm={() => setLoading(null)}
        >
          <img className="spinning loading-logo" src={vomozLogo} />
        </SweetAlert>
      )}
      <div className="page-content">
        <AddCallerIdProcess
          isOpen={open}
          toggle={toggleModal}
          refetch={getCallerIdVerified}
        />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Account"} breadcrumbItem={"Caller IDs"} />
          {info.success_dlg ? (
            <SweetAlert
              success
              title={info.dynamic_title}
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}

          {info.error_dlg ? (
            <SweetAlert
              error
              title={info.dynamic_title}
              onConfirm={() => setInfo({ error_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}
          <Row className="justify-content-center">
            <Col lg={7}>
              <Row className="mb-2">
                <Col sm="4"></Col>
                <Col sm="8">
                  <div className="text-sm-right">
                    <Button
                      type="button"
                      color="success"
                      className="btn-square waves-effect waves-light mb-2 mr-2 authorized-countries-add-button"
                      onClick={toggleModal}
                    >
                      <i className="mdi mdi-plus mr-1" />
                      <span>Add A New Caller ID</span>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">My Caller IDs</CardTitle>

                  <div className="table-responsive">
                    {loading ? (
                      ""
                    ) : logs.length === 0 ? (
                      "You do not a registered Caller ID yet. Please add one today to view your log."
                    ) : logs.length > 0 ? (
                      <Table className="table table-centered table-nowrap">
                        <tbody>
                          {logs.map((item, index) => (
                            <tr key={"_item_" + index}>
                              <td>{index + 1}</td>

                              <td>
                                <h5 className="font-size-14 m-0">
                                  <Link to="" className="text-dark">
                                    {item.callerId}
                                  </Link>
                                </h5>
                              </td>
                              <td>
                                <Button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  color="primary"
                                  onClick={handleDeleteDialogOpen(item.id)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>
                  <Col xl="3" lg="4" sm="6" className="mb-2">
                    {info.confirm && (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleDelete}
                        onCancel={() =>
                          setInfo({
                            error_dlg: false,
                          })
                        }
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CallerId.propTypes = {
  t: PropTypes.any,
}

export default CallerId
