const referralListColumn = [
  {
    field: "n",
    label: "",
    sort: "asc",
  },
  {
    field: "firstName",
    label: "Name",
    sort: "asc",
    width: 136,
  },
  {
    field: "mobile",
    label: "Phone",
    sort: "asc",
    width: 100,
  },
  {
    field: "email",
    label: "Email",
    sort: "asc",
  },
  {
    label: "Sign Up Date",
    field: "signUpDate",
    sort: "asc",
    width: 136,
  },
  {
    label: "Purchased Credit Date",
    field: "purchasedCreditDate",
    sort: "asc",
    width: 136,
  },
  {
    label: "Referral Commission",
    field: "referralCommission",
    sort: "asc",
    width: 136,
  },
  {
    label: "Action",
    field: "action",
  },
]

export default referralListColumn
