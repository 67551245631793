import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { Form, Formik } from "formik"
import { ORG_ID } from "helpers/constants"
import { sendOtp, SignIn } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
// Redux
import { connect } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import logo from "../../assets/images/logo.svg"
// import images
import profile from "../../assets/images/profile-img.png"
import externalLinks from "../../helpers/externalLinks"
// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"

const Login = props => {
  const history = useHistory()
  const [loading, setLoading] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [passwordType, setPasswordType] = useState("password")
  const [serverError, setServerError] = useState("")
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    pending_dlg: null,
    suspended_dlg: null,
    deactivated_dlg: null,
    maintenance_dlg: null,
  })

  const handleSendOtp = async (callerId, userGlobalId) => {
    const data = {
      orgId: ORG_ID,
      userGlobalId,
      callerId,
    }

    try {
      const res = await sendOtp(data)

      if (!res) {
        alert("Please Check your internet connection")
        return
      }
      console.log("res", res)
      if (res?.responseCode === "01") {
        // Case: Too many attempts or other errors
        setInfo({
          confirm: false,
          error_dlg: true,
          dynamic_title: "Error",
          dynamic_description: res?.responseMessage || "OTP code was not sent.",
        })
      } else if (res?.success === false) {
        setInfo({
          confirm: false,
          error_dlg: true,
          dynamic_title: "Failed!",
          dynamic_description: res?.message,
        })
      } else if (res?.success === true) {
        setInfo({
          confirm: false,
          success_dlg: true,
          dynamic_title: "Verified!",
          dynamic_description: res?.message,
        })
        setTimeout(() => {
          history.push("/verify-otp")
        }, 500)
      }
    } catch (error) {
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Error",
        dynamic_description: "An unexpected error occurred. Please try again.",
      })
    }
    const res = await sendOtp(data)
  }

  function onCaptchaChange(value) {
    setDisableButton(true)
  }

  useEffect(() => {
    props.apiError("")
  }, [])

  const handlePasswordType = () => {
    if (passwordType === "text") setPasswordType("password")
    else setPasswordType("text")
  }

  return (
    <React.Fragment>
      {info.error_dlg && (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ ...info, error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      )}
      {info.pending_dlg ? (
        <SweetAlert
          warning
          confirmBtnBsStyle="warning"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ pending_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.suspended_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ suspended_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.deactivated_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ deactivated_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.maintenance_dlg ? (
        <SweetAlert
          warning
          confirmBtnBsStyle="warning"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ maintenance_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="account-pages my-4 pt-sm-4">
        <Container
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome!</h5>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <a
                      href={externalLinks.vomozFlexLandingPage}
                      rel="noopener noreferrer"
                    >
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .email("Invalid email address")
                          .required("Required"),
                        password: Yup.string()
                          .min(6, "Must be 6 characters or more")
                          .required("Required"),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        setLoading(true)
                        const data = {
                          orgId: ORG_ID,
                          email: values.email,
                          password: values.password,
                          referenceId: "3RB3t99390ZI322B18712",
                        }

                        if (values.email && values.password) {
                          try {
                            const res = await SignIn(data)
                            resetForm({ values: "" })
                            if (res === undefined)
                              alert("Please Check your internet connection")
                            setLoading(null)
                            if (!res.success) {
                              setServerError(res.message)
                            } else if (res.success) {
                              switch (res?.data?.status) {
                                case "active":
                                  if (res?.data?.callerIdList.length > 1) {
                                    history.push("/select-phone")
                                  } else {
                                    handleSendOtp(
                                      res?.data?.callerIdList[0]?.callerId,
                                      res?.data?.userGlobalId
                                    )
                                  }
                                  break
                                case "pending":
                                  setInfo({
                                    confirm: false,
                                    pending_dlg: true,
                                    dynamic_title: "Oops!",
                                    dynamic_description:
                                      "This account is still on pending. Kindly contact our customer care service for confirmation.",
                                  })
                                  break
                                case "suspended":
                                  setInfo({
                                    confirm: false,
                                    suspended_dlg: true,
                                    dynamic_title: "Oops!",
                                    dynamic_description:
                                      "This account is on suspension. Kindly contact our customer care service for reactivation.",
                                  })
                                  break
                                case "deactivated":
                                  setInfo({
                                    confirm: false,
                                    deactivated_dlg: true,
                                    dynamic_title: "Oops!",
                                    dynamic_description:
                                      "This account is deactivated. Kindly contact our customer care service for reactivation.",
                                  })
                                  break
                                case "maintenance":
                                  setInfo({
                                    confirm: false,
                                    maintenance_dlg: true,
                                    dynamic_title: "Oops!",
                                    dynamic_description:
                                      "This account is on maintenance. Kindly contact our customer care service for more details.",
                                  })
                                  break
                                default:
                                  console.log(res)
                              }
                            }
                          } catch (error) {
                            setLoading(null)
                            console.error(
                              "An error occurred during sign-in:",
                              error
                            )
                          }
                        }
                      }}
                    >
                      {props => {
                        return (
                          <Form className="form-horizontal">
                            {serverError ? (
                              <Alert color="danger">{serverError}</Alert>
                            ) : null}
                            <FormGroup>
                              <Label>Email</Label>
                              <InputGroup>
                                <input
                                  name="email"
                                  value={props.values.email}
                                  className="form-control mr-0 ml-0"
                                  placeholder="Enter email"
                                  onChange={values => {
                                    props.handleChange(values)
                                    if (serverError) {
                                      setServerError("")
                                    }
                                  }}
                                  type="email"
                                  onBlur={props.handleBlur}
                                />
                              </InputGroup>
                              {props.touched.email && props.errors.email ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.email}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label>Password</Label>
                              <InputGroup>
                                <input
                                  name="password"
                                  value={props.values.password}
                                  style={{
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                  onChange={values => {
                                    props.handleChange(values)
                                    if (serverError) {
                                      setServerError("")
                                    }
                                  }}
                                  type={passwordType}
                                  placeholder="Enter password"
                                  className="form-control mr-0"
                                  onBlur={props.handleBlur}
                                />

                                <div
                                  className="input-group-append"
                                  style={{ cursor: "pointer" }}
                                  onClick={handlePasswordType}
                                >
                                  <span className="input-group-text">
                                    {passwordType === "text" ? (
                                      <i class="fa fa-low-vision"></i>
                                    ) : (
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </InputGroup>
                              {props.touched.password &&
                              props.errors.password ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.password}
                                </p>
                              ) : null}
                            </FormGroup>

                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customControlInline"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>
                            <div className="mt-3">
                              <FormGroup
                                className="select2-container"
                                style={{ width: "60%", height: "60%" }}
                              >
                                <ReCAPTCHA
                                  sitekey={
                                    process.env
                                      .REACT_APP_RECAPTCHA_TESTING_SITE_KEY
                                  }
                                  onChange={onCaptchaChange}
                                />
                              </FormGroup>
                            </div>
                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                disabled={!disableButton}
                              >
                                {loading ? "Login..." : "Login"}
                              </button>
                              <i style={{ fontSize: "11px" }}>
                                Kindly check the captcha checkbox to enable this
                                "Login" button
                              </i>
                            </div>

                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-primary"
                              >
                                <i className="mdi mdi-lock mr-1" /> Forgot your
                                password?
                              </Link>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Don't have an account yet?{" "}
                  <Link
                    to="mobile-verification"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Sign up now{" "}
                  </Link>{" "}
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
