import { searchRates } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useParams } from "react-router-dom"

const Main = () => {
  const [country, setCountry] = useState("")
  const [rates, setRates] = useState([])
  const [screenState, setScreenState] = useState(false)
  const [empty, setEmpty] = useState(true)
  const params = useParams()

  console.log(params)

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  useEffect(() => {
    const wide = window.innerWidth
    console.log(wide)
    if (wide < 500) setScreenState(true)
    else setScreenState(false)
  }, [])

  useEffect(() => {
    const getRates = async () => {
      const data = {
        orgId: "939001",
        searchString: params.country.toLowerCase(),
      }
      let res = await searchRates(data)
      console.log(res)
      if (res.success === false) {
        setInfo({
          confirm: false,
          error_dlg: true,
          dynamic_title: "Failed",
          dynamic_description: res?.message,
        })
      } else if (res.data.returnedRecords === null) {
        setEmpty(true)
        setInfo({
          confirm: false,
          error_dlg: true,
          dynamic_title: "Failed",
          dynamic_description: "There are no rates for the selected country",
        })
      } else {
        setRates(res.data.returnedRecords)
        setEmpty(false)
      }
    }
    if (location) {
      setCountry(location)
    }
    if (country) {
      getRates()
    }
  }, [location.pathname, country])

  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
          confirmBtnBsStyle="success"
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
          confirmBtnBsStyle="danger"
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {!empty ? (
        <div className="table-responsive">
          <table className="table table-centered table-striped table-nowrap mb-0">
            <thead>
              <tr>
                {/* <th style={{ width: "5px" }}>#</th> */}

                <th>Country</th>
                <th>Rate/Min</th>
                <th>$10 Gives</th>
                <th>$20 Gives</th>
              </tr>
            </thead>
            <tbody>
              {rates.map((rate, index) => (
                <tr key={"_tr_" + index}>
                  {/* <td>{index + 1}</td> */}
                  <td>{rate.operator}</td>
                  <td>${rate.rate}</td>
                  <td>{rate.minutesTen} mins</td>
                  <td>{rate.minutesTwenty} mins</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  )
}

export default Main
