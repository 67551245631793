//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { topUpVoucherHistory } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import AddVoucher from "./components/AddVoucher"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const VoucherHistory = () => {
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])
  const [deleteB, setDeleteB] = useState("")

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleChange = date => {
    setStartDate(date)
  }
  const handleChange2 = date => {
    setEndDate(date)
  }

  const getTopUpVoucherHistory = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userIpAddress: "127.0.0.1",
      startDate: startDate?.toLocaleDateString("en-CA") || null,
      endDate: endDate?.toLocaleDateString("en-CA") || null,
      userGlobalId: user?.userGlobalId,
    }
    console.log(data)

    const countries = await topUpVoucherHistory(data)
    const countriesList = countries?.data?.returnedRecords
    console.log(countriesList)
    if (countriesList) {
      setLogs(countriesList)
      setLoading(false)
    }
  }

  useEffect(() => {
    getTopUpVoucherHistory()
  }, [endDate])

  const handleAmountBilled = billed => {
    if (billed === "-0") {
      return "0.00"
    } else {
      return billed
    }
  }

  const calcLocalDate = date => {
    let splitDate = new Date(date).toDateString().split(" ")
    return (
      splitDate[0] +
      " " +
      splitDate[1] +
      " " +
      splitDate[2] +
      "," +
      " " +
      splitDate[3]
    )
  }

  const calcLocalTime = date => {
    return new Date(date)
      .toLocaleTimeString()
      .replace(/(\d{1,2}:\d{2}):\d{2}/, "$1")
  }

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          timeout={2000}
          showConfirm={false}
          onConfirm={() => setLoading(null)}
        >
          <img className="spinning loading-logo" src={vomozLogo} />
        </SweetAlert>
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Account"}
            breadcrumbItem={"Vomoz Card History"}
          />
          <Row>
            <Col>
              <React.Fragment>
                <AddVoucher
                  userId={user.userGlobalId}
                  isOpen={open}
                  toggle={toggleModal}
                  refetch={getTopUpVoucherHistory}
                />
                <Card>
                  <CardBody className="all-customer-card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        {/* <Form className="mb-3"> */}
                        {logs.length > 0 && (
                          <Row>
                            <div className="col-xl col-sm-6 all-customers-start-date">
                              <FormGroup className="mt-3 mb-0 start-date">
                                <DatePicker
                                  selected={startDate}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholderText="Start date"
                                />
                              </FormGroup>
                            </div>
                            <div className="col-xl col-sm-6">
                              <FormGroup className="mt-3 mb-0 end-date">
                                <DatePicker
                                  selected={endDate}
                                  onChange={handleChange2}
                                  className="form-control"
                                  placeholderText="End date"
                                />
                              </FormGroup>
                            </div>
                          </Row>
                        )}

                        <Button
                          type="button"
                          color="success"
                          className="btn-square waves-effect waves-light all-customers-add-button adjust-button"
                          onClick={toggleModal}
                        >
                          <i className="mdi mdi-plus mr-1" />
                          <span>Load Vomoz Card</span>
                        </Button>
                        {/* </Form> */}
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        ""
                      ) : logs.length === 0 ? (
                        <p className="mt-2 ml-2">
                          You are yet to top up your account with a voucher.
                          Kindly click on the Top up button to load a card.
                        </p>
                      ) : logs.length > 0 ? (
                        <table className="table table-centered table-striped table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "20px" }}>#</th>

                              <th>Voucher</th>
                              <th>Amount ($)</th>
                              <th>Date</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {logs.map((transaction, index) => (
                              <tr key={"_tr_" + index}>
                                <td>{index + 1}</td>
                                <td>{transaction.voucher}</td>
                                <td>
                                  {handleAmountBilled(transaction.amount)}
                                </td>

                                <td>{calcLocalDate(transaction.usedDate)}</td>
                                <td>{calcLocalTime(transaction.usedDate)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VoucherHistory.propTypes = {
  t: PropTypes.any,
}

export default VoucherHistory
