import { addCallerId, addCallerIdProcess } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const intialStates = {
  values: {
    mobile: "",
    verificationCode: "",
  },
  hasPin: false,
  feedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
}

const AddCallerIdProcessModal = props => {
  const { isOpen, toggle, refetch } = props
  const [values, setValues] = useState(intialStates.values)
  const [hasPin, setHasPin] = useState(intialStates.hasPin)
  const [location, setLocation] = useState("")
  const [mobile, setMobile] = useState("")
  const [feedbackModal, setFeedbackModal] = useState(intialStates.feedbackModal)
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    intialStates.warningFeedbackModal
  )

  const toggleHasPin = () => {
    setHasPin(prev => !prev)
  }

  const closeFeedbackModal = () => {
    setFeedbackModal(intialStates.feedbackModal)
    if (feedbackModal.openFor === "pin") {
      toggle()
    }
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(intialStates.warningFeedbackModal)
    if (warningFeedbackModal.openFor === "pin") {
      toggle()
    }
  }

  const handlePhoneNumberChange = val => {
    console.log(val)
    setValues(prev => ({ ...prev, mobile: val }))
  }
  const handleChange = e => {
    setValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    console.log(hasPin)
    console.log(values)
    if (!hasPin) {
      const data = {
        orgId: "939001",
        userGlobalId: user?.userGlobalId,
        mobile: values.mobile,
        sendSms: "true",
      }

      const callerId = await addCallerId(data)
      console.log("Hello Emmanuel")
      if (callerId?.success) {
        setFeedbackModal(prev => ({
          ...prev,
          open: true,
          success: true,
          error: false,
          openFor: "mobile",
          title: "Verification Code Sent",
          message: callerId.message,
        }))
        toggleHasPin()
      } else {
        setWarningFeedbackModal(prev => ({
          ...prev,
          open: true,
          success: false,
          error: true,
          openFor: "mobile",
          title: "Verification Code Not Sent",
          message: callerId.message,
        }))
      }
      console.log(callerId)
    } else {
      const data = {
        orgId: "939001",
        userGlobalId: user?.userGlobalId,
        mobile: values.mobile,
        verificationCode: values.verificationCode,
        sendSms: "true",
      }
      const callIdProcess = await addCallerIdProcess(data)
      if (callIdProcess.success) {
        refetch()
        setFeedbackModal(prev => ({
          ...prev,
          open: true,
          openFor: "pin",
          success: true,
          error: false,
          title: "Number Added Successful",
          message: `${values.mobile} has been added to your Caller ID successfully.`,
        }))
        setTimeout(() => toggle(), 2000)
      }
    }
  }

  useEffect(() => {
    setValues(intialStates.values)
    setHasPin(intialStates.hasPin)
    setFeedbackModal(intialStates.feedbackModal)
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Add a New Caller ID</ModalHeader>
        <Form onSubmit={handleSubmit} className="form-horizontal">
          <ModalBody>
            <Row>
              {!hasPin && (
                <>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="formrow-email-Input">
                        International Mobile Number
                      </Label>
                      <Col xs={12}>
                        <PhoneInput
                          country="us"
                          value={mobile}
                          inputProps={{
                            name: "mobile",
                            autoFocus: true,
                            required: true,
                          }}
                          placeholder="Select country code and enter your number"
                          onChange={handlePhoneNumberChange}
                          inputClass="form-control"
                          inputStyle={{ width: "100%" }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          Verify
                        </Button>
                      </Col>
                      <Col md={3} style={{ flex: "1" }}>
                        <Button color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                    </Row>
                  </Col>
                </>
              )}
              {hasPin && (
                <Col md={12}>
                  <FormGroup>
                    <Label for="formrow-email-Input">Verification PIN</Label>
                    <Input
                      name="verificationCode"
                      type="text"
                      className="form-control"
                      placeholder="Enter the PIN sent to your phone"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            {hasPin && (
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Col md={2} style={{ flex: "1" }}></Col>
                <Col md={5} style={{ flex: "2" }}>
                  <Button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    color="primary"
                  >
                    Send
                  </Button>
                </Col>
                <Col md={3} style={{ flex: "1" }}>
                  <Button color="link" onClick={toggle}>
                    Cancel
                  </Button>
                </Col>
                <Col md={2} style={{ flex: "1" }}></Col>
              </Row>
            )}
          </ModalFooter>
        </Form>
        {feedbackModal.open && (
          <SweetAlert
            title={feedbackModal.title}
            success
            confirmBtnBsStyle="success"
            onConfirm={closeFeedbackModal}
          >
            {feedbackModal.message}
          </SweetAlert>
        )}
        <div>
          {warningFeedbackModal.open && (
            <SweetAlert
              title={warningFeedbackModal.title}
              danger
              confirmBtnBsStyle="danger"
              onConfirm={closeWarningFeedbackModal}
            >
              {warningFeedbackModal.message}
            </SweetAlert>
          )}
        </div>
      </div>
    </Modal>
  )
}

AddCallerIdProcessModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddCallerIdProcessModal
