import { Form, Formik } from "formik"
import { setupAutoRecharge, userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../../components/CustomSelect"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const AssignLocalNumber = props => {
  const [loading, setLoading] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [amountCredUserCanBuy, setAmountCredUserCanBuy] = useState([])
  const [
    minAccountBalanceBeforeAutoRecharge,
    setMinAccountBalanceBeforeAutoRecharge,
  ] = useState([])

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const onCaptchaChange = value => {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const fetchRechargeDetails = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    if (parameters) {
      console.log(parameters.data)
      setLoading(null)
      const userParameters = parameters?.data
      console.log(userParameters)
      const amountCreditUserCanBuy = userParameters?.amountCreditUserCanBuy
      const minimumAccountBalanceBeforeAutoRecharge =
        userParameters?.minimumAccountBalanceBeforeAutoRecharge
      if (amountCreditUserCanBuy) {
        setAmountCredUserCanBuy(amountCreditUserCanBuy)
        console.log(amountCredUserCanBuy)
      }
      if (minimumAccountBalanceBeforeAutoRecharge) {
        setMinAccountBalanceBeforeAutoRecharge(
          minimumAccountBalanceBeforeAutoRecharge
        )
        console.log(minAccountBalanceBeforeAutoRecharge)
      }
    }
  }

  useEffect(() => {
    fetchRechargeDetails()
  }, [])

  const { isOpen, toggle, refreshTable } = props
  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
          confirmBtnBsStyle="success"
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
          confirmBtnBsStyle="danger"
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Setup Auto Recharge</ModalHeader>
          <Formik
            initialValues={{
              amountUserCanBuy: "",
              accountBalanceBeforeRecharge: "",
            }}
            validationSchema={Yup.object({
              amountUserCanBuy: Yup.string().required("Required"),
              accountBalanceBeforeRecharge: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                accountBalanceBeforeRecharge:
                  values.accountBalanceBeforeRecharge,
                amountToRecharge: values.amountUserCanBuy,
              }
              console.log(data)
              const Assigned = await setupAutoRecharge(data)
              resetForm({ values: "" })
              setLoading(false)
              if (Assigned === undefined) {
                alert("Please check your internet connection")
              }
              if (Assigned?.success) {
                setLoading(false)
                setInfo({
                  confirm: false,
                  success_dlg: true,
                  dynamic_title: "Added!",
                  dynamic_description: Assigned?.message,
                })
                refreshTable()
                toggle()
              } else {
                setLoading(false)
                setInfo({
                  confirm: false,
                  error_dlg: true,
                  dynamic_title: "Failed!",
                  dynamic_description: Assigned?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Select Amount to Recharge</Label>
                          <CustomSelect
                            name="amountUserCanBuy"
                            value={props.values.amountUserCanBuy}
                            onChange={props.handleChange("amountUserCanBuy")}
                            options={amountCredUserCanBuy.map(item => ({
                              label: item,
                              value: item,
                            }))}
                            classNamePrefix="select2-selection"
                          />
                          {props.touched.amountUserCanBuy &&
                          props.errors.amountUserCanBuy ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.amountUserCanBuy}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Minimum Amount Before Auto Recharge</Label>
                          <CustomSelect
                            name="accountBalanceBeforeRecharge"
                            value={props.values.accountBalanceBeforeRecharge}
                            options={minAccountBalanceBeforeAutoRecharge.map(
                              item => ({
                                label: item,
                                value: item,
                              })
                            )}
                            onChange={props.handleChange(
                              "accountBalanceBeforeRecharge"
                            )}
                            classNamePrefix="select2-selection"
                          />
                          {props.touched.accountBalanceBeforeRecharge &&
                          props.errors.accountBalanceBeforeRecharge ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.accountBalanceBeforeRecharge}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Country</Label> */}
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          color="primary"
                          disabled={!disableButton}
                        >
                          {loading ? "Submitting" : "Submit"}
                        </Button>
                      </Col>
                      <Col md={3} style={{ flex: "1" }}>
                        <Button type="button" color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                    </Row>
                  </ModalBody>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

AssignLocalNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AssignLocalNumber
