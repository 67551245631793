import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"
import accountParamsSaga from "./auth/accountparams/saga"

export default function* rootSaga() {
  yield all([
    //public

    fork(LayoutSaga),

    fork(accountParamsSaga),
  ])
}
