import { useFormik } from "formik"
import { updateProfile } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "react-router-dom"
import { Button, FormGroup, Input, InputGroup, Label } from "reactstrap"
import * as Yup from "yup"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
  },
}

const UpdateProfileForm = ({ initialValues, refresh, load }) => {
  const [loading, setLoading] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }
  console.log(initialValues.firstName)

  const formikValidationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(64, "Maximum of 64 characters")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Minimum of 2 characters")
      .max(64, "Maximum of 64 characters")
      .required("Required"),
  })

  const props = useFormik({
    initialValues,
    validationSchema: formikValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values)
      setLoading(true)
      const data = {
        orgId: "939001",
        userGlobalId: user?.userGlobalId,
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: initialValues.mobile,
        email: initialValues.email,
      }

      const res = await updateProfile(data)
      resetForm({ values: "" })
      setLoading(null)
      if (res === undefined) {
        alert("Please check your internet connection")
        setLoading(null)
      }
      if (res?.success) {
        console.log(res)
        setLoading(false)
        setFeedbackModal(prev => ({
          ...prev,
          open: true,
          openFor: "mobile",
          success: true,
          error: false,
          title: "Done!",
          message: res.message,
        }))
        console.log(refresh())
        refresh()
      } else {
        {
          console.log(res)
          setLoading(false)
          setWarningFeedbackModal(prev => ({
            ...prev,
            open: true,
            openFor: "mobile",
            success: false,
            error: true,
            title: "Failed!",
            message: res.message,
          }))
        }
      }
    },
  })

  useEffect(() => {
    props.setFieldValue("firstName", initialValues.firstName)
  }, [initialValues.firstName])

  useEffect(() => {
    props.setFieldValue("lastName", initialValues.lastName)
  }, [initialValues.lastName])

  return (
    <>
      <form onSubmit={props.handleSubmit} className="form-horizontal">
        <FormGroup>
          <Label>First Name</Label>
          <InputGroup>
            <Input
              name="firstName"
              type="text"
              className="form-control mx-0"
              placeholder="Enter your First Name"
              onChange={props.handleChange}
              value={props.values.firstName}
              onBlur={props.handleBlur}
            />
          </InputGroup>
          {props.touched.firstName && props.errors.firstName ? (
            <p style={{ color: "red", fontSize: "11px" }}>
              {props.errors.firstName}
            </p>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Last Name</Label>
          <InputGroup>
            <Input
              name="lastName"
              type="text"
              className="form-control mx-0"
              placeholder="Enter your Last Name"
              onChange={props.handleChange}
              value={props.values.lastName}
              onBlur={props.handleBlur}
            />
          </InputGroup>
          {props.touched.lastName && props.errors.lastName ? (
            <p style={{ color: "red", fontSize: "11px" }}>
              {props.errors.lastName}
            </p>
          ) : null}
        </FormGroup>

        <FormGroup className="select2-container">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={onCaptchaChange}
          />
        </FormGroup>

        <FormGroup>
          <div
            className="mt-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <button
              className="btn btn-primary waves-effect waves-light "
              type="submit"
              style={{ width: "42%" }}
              disabled={!disableButton}
            >
              {loading ? "Submitting" : "Submit"}
            </button>

            <Button style={{ width: "42%" }} type="button" color="link">
              <Link to="/">{loading ? "Cancelling" : "Cancel"}</Link>
            </Button>
          </div>

          <i style={{ color: "#000", fontSize: "11px" }}>
            Kindly click on the captcha field to enable the "Submit" button
          </i>
        </FormGroup>
      </form>

      {feedbackModal.open && (
        <SweetAlert
          title={feedbackModal.title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeFeedbackModal}
        >
          {feedbackModal.message}
        </SweetAlert>
      )}
      <>
        {warningFeedbackModal.open && (
          <SweetAlert
            title={warningFeedbackModal.title}
            danger
            confirmBtnBsStyle="danger"
            onConfirm={closeWarningFeedbackModal}
          >
            {warningFeedbackModal.message}
          </SweetAlert>
        )}
      </>
    </>
  )
}

export default UpdateProfileForm
