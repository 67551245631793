import { existingAutoRecharge } from "helpers/requests"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Card, CardBody, Media } from "reactstrap"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const AutoRechargeCard = () => {
  const [loading, setLoading] = useState(null)
  const [order, setOrder] = useState([])
  const history = useHistory()

  const getExistingAutoRecharge = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const assigned = await existingAutoRecharge(data)
    const servedOrder = assigned?.data
    console.log(servedOrder)
    setLoading(false)
    if (servedOrder) {
      setOrder(servedOrder)
      setLoading(false)
    }
  }

  console.log(order)

  useEffect(() => {
    getExistingAutoRecharge()
  }, [])

  const getRealDate = date => {
    if (date === "") {
      return "-"
    } else {
      const str = new Date(date).toDateString()

      let splitDate = str.split(" ")
      return (
        <>
          {splitDate[0] +
            " " +
            splitDate[1] +
            " " +
            splitDate[2] +
            "," +
            " " +
            splitDate[3]}
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <Card className="plan-box">
        <CardBody className="p-4">
          <Media>
            <Media body>
              <h5>Auto Top Up</h5>
              <br />
            </Media>
            <div className="ml-3">
              <i className={"bx bx-purchase-tag" + " h1 text-primary"} />
            </div>
          </Media>
          {console.log(order)}

          {loading ? (
            ""
          ) : order.isExistingFound === "false" ? (
            <div className="text-muted justify-content-start">
              <p>You have no item on this list, please set up one now.</p>
              <Button
                type="button"
                color="success"
                className="w-md local-number-add-button"
                onClick={() => history.push("/account/auto-top-up")}
              >
                <i className="mdi mdi-plus mr-1" />
                <span> Setup Auto Top Up</span>
              </Button>
            </div>
          ) : (
            <div className="plan-features mt-2">
              <p>
                <i className="bx bx-checkbox-square text-primary mr-2" />
                Balance before recharge:{" "}
                <strong>${order.accountBalanceBeforeRecharge}</strong>
              </p>
              <p>
                <i className="bx bx-checkbox-square text-primary mr-2" /> Amount
                to recharge: <strong>${order.amountToRecharge}</strong>
              </p>
              <p>
                <i className="bx bx-checkbox-square text-primary mr-2" /> Setup
                date: {getRealDate(order.setupDate)}
              </p>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AutoRechargeCard
