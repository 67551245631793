import Breadcrumbs from "components/Common/Breadcrumb"
import { transferCreditHistory } from "helpers/requests"
import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Card, CardBody, Container, Row } from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import TransferCreditModal from "./components/TransCredit"
import CallCreditColumn from "./dataTableColumns"
import TransferCreditHistoryModal from "./Modal"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const transferCreditHis = () => {
  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])
  const [transaction, setTransaction] = useState({})
  const openModal = transaction => () => {
    setTransaction(transaction)
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
  }
  const toggleModal = () => {
    setOpen(!open)
  }

  const handleChange = date => {
    setStartDate(date)
    console.log(date.toLocaleDateString("en-CA"))
  }
  const handleChange2 = date => {
    setEndDate(date)
  }

  const getTransferCreditHistoryLogs = async () => {
    setLoading(true)
    console.log(endDate?.toLocaleDateString("en-CA"))
    console.log(startDate?.toLocaleDateString("en-CA"))
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      startDate: startDate?.toLocaleDateString("en-CA") || null,
      endDate: endDate?.toLocaleDateString("en-CA") || null,
    }

    const log = await transferCreditHistory(data)
    console.log(log)
    const TransferCreditHistoryLog = log?.data?.returnedRecords
    console.log(TransferCreditHistoryLog)
    if (TransferCreditHistoryLog) setLogs(TransferCreditHistoryLog)
    setLoading(false)
  }

  useEffect(() => {
    getTransferCreditHistoryLogs()
  }, [endDate])

  const getRealDate = date => {
    const str = new Date(date).toDateString()

    let splitDate = str.split(" ")
    return (
      <>
        {splitDate[0] +
          " " +
          splitDate[1] +
          " " +
          splitDate[2] +
          "," +
          " " +
          splitDate[3]}
      </>
    )
  }

  const convertAmountToTwoDecimalPlace = amount => parseFloat(amount).toFixed(2)

  const data = {
    columns: CallCreditColumn,
    rows: [
      ...logs.map((row, order) => ({
        ...row,
        id: <>{order + 1}</>,
        receiverMobile: row?.receiverMobile,
        email:
          row?.email?.length > 20
            ? row?.email?.slice(0, 20) + "..."
            : row?.email,
        amount: convertAmountToTwoDecimalPlace(row?.amount),
        date: getRealDate(row?.date),
        more: (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-square waves-effect waves-light"
            onClick={openModal(row)}
          >
            View Details
          </Button>
        ),
      })),
    ],
  }
  console.log(data)

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <TransferCreditHistoryModal
          isOpen={modal}
          transaction={transaction}
          toggle={closeModal}
        />
        <TransferCreditModal
          isOpen={open}
          toggle={toggleModal}
          refetch={getTransferCreditHistoryLogs}
        />
        <Container fluid>
          <Breadcrumbs title="Call Credit" breadcrumbItem="Transfer" />
          <Card>
            <CardBody className="all-customer-card-body">
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "11px",
                  }}
                >
                  {logs.length > 0 && (
                    <Row>
                      <div className="col-xl col-sm-6 all-customers-start-date">
                        <DatePicker
                          selected={startDate}
                          onChange={handleChange}
                          className="form-control"
                          placeholderText="Start date"
                        />
                      </div>
                      <div className="col-xl col-sm-6">
                        <DatePicker
                          selected={endDate}
                          onChange={handleChange2}
                          className="form-control"
                          placeholderText="End date"
                        />
                      </div>
                    </Row>
                  )}

                  <Button
                    type="button"
                    color="success"
                    className="btn-square waves-effect waves-light all-customers-add-button"
                    onClick={toggleModal}
                  >
                    <i className="mdi mdi-plus mr-1" />
                    <span>Transfer Credit</span>
                  </Button>
                </div>
              </div>
              <div style={{ width: "100%", overflow: "auto" }}>
                {loading ? (
                  ""
                ) : logs.length === 0 ? (
                  <p>
                    You do not have a transfer credit log yet. You are yet to
                    transfer credit with your account. Kindly click on the
                    Transfer Credit button to initiate a transfer.
                  </p>
                ) : logs.length > 0 ? (
                  <MDBDataTable
                    hover
                    entriesOptions={[100, 200, 500, 1000]}
                    entries={100}
                    pagesAmount={4}
                    data={data}
                    striped
                    style={{
                      marginTop: "10px",
                    }}
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

// transferCreditHis.propTypes = {
//   t: PropTypes.any,
// }

export default transferCreditHis
