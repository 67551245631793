import PublicLayout from "components/NonAuthLayout"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import "./assets/scss/theme.scss"
import HorizontalLayout from "./components/HorizontalLayout/"
import VerticalLayout from "./components/VerticalLayout/"
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getLayout = this.getLayout.bind(this)
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout

    const width = window.innerWidth
    if (width < 500) layoutCls = HorizontalLayout
    return layoutCls
  }

  render() {
    const Layout = this.getLayout()

    return (
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={PublicLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={route.path === "/lock-screen" ? PublicLayout : Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

App.propTypes = {
  layout: PropTypes.object,
}

export default connect(mapStateToProps, null)(App)
