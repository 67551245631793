import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { ORG_ID } from "helpers/constants"
import { maskString } from "helpers/helperFunctions"
import { sendOtp } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import logo from "../../assets/images/logo.svg"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}
const phoneNumbers = user?.callerIdList
const userGlobalId = user?.userGlobalId

const SelectPhone = () => {
  const history = useHistory()
  const [selectedPhone, setSelectedPhone] = useState("")
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(null)
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
  })

  //   useEffect(() => {
  //     setLoading(true)
  //   }, [])

  useEffect(() => {
    if (user && phoneNumbers && phoneNumbers.length > 0) {
      setLoading(false) // Stop loading when phone numbers are available
    } else {
      // If there are no phoneNumbers or they are not loaded properly, we can stop loading and show a message.
      setLoading(false)
    }
  }, [user, phoneNumbers])

  const handleChange = event => {
    setSelectedPhone(event.target.value)
  }

  const handleSubmit = async () => {
    setLoading2(true)
    const data = {
      orgId: ORG_ID,
      userGlobalId,
      callerId: selectedPhone,
    }
    console.log("Hello world1")
    const res = await sendOtp(data)
    if (res === undefined) {
      setLoading2(null)
      console.log("Hello world2")
      alert("Please Check your internet connection")
    }
    if (res?.success === false) {
      setLoading2(null)
      console.log("Hello world3")
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed!",
        dynamic_description: res?.message,
      })
    } else if (res?.success === true) {
      setLoading2(null)
      localStorage.setItem("phoneCallerId", selectedPhone)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Verified!",
        dynamic_description: res?.message,
      })
      setTimeout(() => {
        history.push("/verify-otp")
      }, 500)
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          //   timeout={1000}
          showConfirm={false}
          onConfirm={() => setLoading(null)}
        >
          <img className="spinning loading-logo" src={logo} />
        </SweetAlert>
      )}
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="account-pages my-4 pt-sm-4">
        <Container
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ minHeight: "100vh" }}
        >
          <Card
            className="p-4 shadow-lg rounded-lg"
            style={{ width: "100%", maxWidth: "500px" }}
          >
            <CardBody>
              <div className="p-2">
                <div className="text-center">
                  <div className="avatar-md mx-auto">
                    <div className="avatar-title rounded-circle bg-light">
                      <i className="bx bxs-phone-incoming h1 mb-0 text-primary"></i>
                    </div>
                  </div>
                  <div className="p-2 mt-3">
                    <h4>Select Phone Number</h4>
                    <p className="mb-5">
                      Almost there! Please choose the phone number for receiving
                      OTP.
                    </p>

                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit()
                      }}
                    >
                      <FormGroup tag="fieldset">
                        {Array.isArray(phoneNumbers) &&
                        phoneNumbers.length > 0 ? (
                          phoneNumbers.map((phone, index) => (
                            <FormGroup
                              check
                              key={index}
                              className="radio-option"
                            >
                              <Label
                                check
                                className="d-flex align-items-center"
                              >
                                <Input
                                  type="radio"
                                  name="phoneNumbers"
                                  value={phone.callerId}
                                  checked={selectedPhone === phone.callerId}
                                  onChange={handleChange}
                                  className="custom-radio-input"
                                />
                                <span className="ml-3 pt-1">
                                  {maskString(phone?.callerId)}
                                </span>
                              </Label>
                            </FormGroup>
                          ))
                        ) : (
                          <p className="text-danger">
                            No phone numbers available for selection. Kindly
                            reload the page or go back to login.
                          </p>
                        )}
                      </FormGroup>

                      <div className="text-center mt-4">
                        <Button
                          color="primary"
                          className="btn-custom"
                          type="submit"
                          disabled={!selectedPhone}
                        >
                          {loading2 ? "Sending..." : "Send OTP"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="mt-4 text-center">
            <p>
              Need to sign in again?{" "}
              <Link to="/login">
                <span className="font-weight-medium text-primary">
                  {" "}
                  Back to Login{" "}
                </span>{" "}
              </Link>
            </p>
            <FooterMessage />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SelectPhone
