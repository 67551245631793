import { Form, Formik } from "formik"
import { referAFriend } from "helpers/requests"
import { MDBDataTable } from "mdbreact"
import PropTypes from "prop-types"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import "react-phone-input-2/lib/style.css"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import "./styles.scss"
import TableColumn from "./TableColumn"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

const AssignLocalNumber = props => {
  const { isOpen, toggle, refreshTable } = props

  const [loading, setLoading] = useState(null)
  const [referralList, setReferralList] = useState([])
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
    qualifiedCandidate: null,
    unqualifiedCandidate: null,
  })

  const formikInitialValues = {
    firstName: "",
    mobile: "",
    email: "",
  }

  const handleSave = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      friendsList: referralList,
    }
    const referral = await referAFriend(data)
    setLoading(false)
    console.log(referral)
    if (referral?.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Done!",
        dynamic_description: capitalizeFirstLetter(referral?.message),
        qualifiedCandidate: referral?.data.qualifiedFriendsList.length,
        unqualifiedCandidate: referral?.data.unQualifiedFriendsList.length,
      })
      refreshTable(console.log("Hi"))
      setReferralList([])
    } else {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed!",
        dynamic_description: referral?.message,
      })
    }
  }

  const data = {
    columns: TableColumn,
    rows: [
      ...referralList.map((list, index) => ({
        ...list,
        n: <>{index + 1}</>,
        action: (
          <>
            <button
              className="btn noti-icon waves-effect"
              onClick={() => {
                delete referralList[index]
                setReferralList([...referralList.filter(item => item)])
              }}
            >
              <i className="bx bx-trash" />
            </button>
          </>
        ),
      })),
    ],
  }

  const handleToggle = () => {
    toggle()
    setReferralList([])
  }

  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
          <br />
          {info.qualifiedCandidate} referred added <br />
          {info.unqualifiedCandidate} referred already added
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal expandModal"
        tabIndex="-1"
        toggle={toggle}
        size="lg"
      >
        <div className="modal-content">
          <ModalHeader toggle={handleToggle}>Refer A Friend</ModalHeader>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(64, "Maximum of 64 characters")
                .required("Required"),
              mobile: Yup.string().required("Required"),
              email: Yup.string().email("Invalid email address"),
            })}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true)
              setReferralList(prev => [...prev, values])
              resetForm({ values: "" })
              setLoading(false)
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col xs="12" md={12} lg={12}>
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td>
                                <div>
                                  <Row>
                                    <Col lg="3" className="form-group">
                                      <FormGroup className="row mb-1 ml-1">
                                        <Label htmlFor="name">First Name</Label>
                                        <Input
                                          type="text"
                                          value={props.values.firstName}
                                          name="firstName"
                                          id="name"
                                          placeholder="Enter name"
                                          className="form-control"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}
                                        />
                                        {props.touched.firstName &&
                                        props.errors.firstName ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {props.errors.firstName}
                                          </p>
                                        ) : null}
                                      </FormGroup>
                                    </Col>

                                    <Col lg="5" className="form-group">
                                      <FormGroup className="row mb-1 ml-1">
                                        <Label htmlFor="mobile">Mobile</Label>
                                        <Input
                                          type="text"
                                          value={props.values.mobile}
                                          name="mobile"
                                          id="mobile"
                                          placeholder="Enter mobile number"
                                          className="form-control"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}
                                        />
                                        {props.touched.mobile &&
                                        props.errors.mobile ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {props.errors.mobile}
                                          </p>
                                        ) : null}
                                      </FormGroup>
                                    </Col>

                                    <Col lg="3" className="form-group">
                                      <FormGroup className="row mb-1 ml-1">
                                        <Label htmlFor="email">Email</Label>
                                        <Input
                                          type="email"
                                          value={props.values.email}
                                          name="email"
                                          id="email"
                                          placeholder="Enter email"
                                          className="form-control"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}
                                        />
                                        {props.touched.email &&
                                        props.errors.email ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {props.errors.email}
                                          </p>
                                        ) : null}
                                      </FormGroup>
                                    </Col>

                                    <Col
                                      lg="1"
                                      className="form-group align-self-center"
                                    >
                                      <Button
                                        color="primary"
                                        className="mt-3"
                                        style={{
                                          padding: "6px",
                                          width: "100%",
                                          transform:
                                            "scale(1) translate(0%, 10%)",
                                        }}
                                        block
                                        type="submit"
                                      >
                                        {" "}
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Note: Please add the form details before you
                                    save them.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </ModalBody>
                </Form>
              )
            }}
          </Formik>
          <Row>
            {referralList.length ? (
              <>
                <Col lg={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: "1", width: "100%" }}>
                      <MDBDataTable
                        responsive
                        hover
                        data={data}
                        paging={false}
                        searching={false}
                        bordered
                        small
                      />
                    </div>
                  </div>
                </Col>
              </>
            ) : null}
          </Row>
          <Row style={{ display: "flex", alignItems: "center", margin: "6px" }}>
            <Col style={{ flex: "1" }}></Col>
            <Col style={{ flex: "2" }}>
              <Button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                color="primary"
                onClick={handleSave}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Col>
            <Col style={{ flex: "1" }}></Col>
            <Col style={{ flex: "2" }}>
              <Button type="button" color="link" onClick={handleToggle}>
                Cancel
              </Button>
            </Col>
            <Col style={{ flex: "1" }}></Col>
          </Row>
          <ModalFooter style={{ visibility: "hidden" }}>
            <Col>
              <Button type="button" color="danger" onClick={handleToggle}>
                Cancel
              </Button>
            </Col>
          </ModalFooter>
        </div>
      </Modal>
    </>
  )
}

AssignLocalNumber.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AssignLocalNumber
