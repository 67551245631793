import { uploadProfilePictureOrCoverPhoto } from "helpers/requests"
import PropTypes from "prop-types"
import { useCallback, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import Cropper from "react-easy-crop"
import { FaCamera } from "react-icons/fa"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", error => reject(error))
    image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve(file)
    }, "image/jpeg")
  })
}

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const ProfilePictureModal = props => {
  const { isOpen, toggle, refreshData, profilePhoto } = props

  const [open, setOpen] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [imageSrc, setImageSrc] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
    if (feedbackModal.openFor === "pin") {
      toggle()
    }
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
    if (warningFeedbackModal.openFor === "pin") {
      toggle()
    }
  }

  let formData = new FormData()

  const handleChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl)
    }
    setShowButtons(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
    formData.append("orgId", "939001")
    formData.append("userGlobalId", user?.userGlobalId)
    formData.append("imageMedia", croppedImage)
    formData.append("mediaType", "profilePicture")

    const res = await uploadProfilePictureOrCoverPhoto(formData)

    setLoading(false)
    if (res === undefined) {
      alert("Please check your internet connection")
      setLoading(null)
    }
    if (res?.success === false) {
      setLoading(false)
      setWarningFeedbackModal(prev => ({
        ...prev,
        open: true,
        openFor: "mobile",
        success: false,
        error: true,
        title: "Failed!",
        message: res.message,
      }))
    } else if (res?.success === true) {
      setLoading(false)
      setOpen(!open)
      setFeedbackModal(prev => ({
        ...prev,
        open: true,
        openFor: "mobile",
        success: true,
        error: false,
        title: "Profile Picture Modified!",
        message: res.message,
      }))
      refreshData()
      toggle()
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    console.log(croppedArea, croppedAreaPixels)
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>
            Click on the picture to upload a new one
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col lg={12}>
                <br />
                <form
                  onSubmit={handleSubmit}
                  className={"justify-content-center"}
                >
                  <FormGroup className="row mb-4">
                    {imageSrc ? (
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "300px",
                        }}
                      >
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={4 / 3}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                    ) : (
                      <div
                        className="profile-user-wid mb-4 upload-btn"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={profilePhoto}
                          alt=""
                          className="img-thumbnail rounded-circle avatar-xxl"
                        />
                        <div
                          className="profile-photo-input-container p-0"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 12,
                          }}
                        >
                          <Label
                            for="profilePicture"
                            className="col-sm-12 col-form-Label"
                          >
                            <input
                              id="profilePicture"
                              name="profilePicture"
                              onChange={handleChange}
                              type="file"
                              accept="image/png, image/jpeg"
                              className="form-control no-display profile-photo-input"
                              hidden
                            />
                            <FaCamera size="2em" className="camera-icon" />
                          </Label>
                        </div>
                      </div>
                    )}
                  </FormGroup>

                  {showButtons && (
                    <FormGroup className="row justify-content-center">
                      <Button
                        type="submit"
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        {loading ? "Uploading..." : "Upload"}
                      </Button>

                      <Button type="button" color="link" onClick={toggle}>
                        Cancel
                      </Button>
                    </FormGroup>
                  )}
                </form>

                {feedbackModal.open && (
                  <SweetAlert
                    title={feedbackModal.title}
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={closeFeedbackModal}
                    timeout={2000}
                  >
                    {feedbackModal.message}
                  </SweetAlert>
                )}
                <div>
                  {warningFeedbackModal.open && (
                    <SweetAlert
                      title={warningFeedbackModal.title}
                      danger
                      confirmBtnBsStyle="danger"
                      onConfirm={closeWarningFeedbackModal}
                    >
                      {warningFeedbackModal.message}
                    </SweetAlert>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

ProfilePictureModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ProfilePictureModal
