import { Form, Formik } from "formik"
import { changePin } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
  },
}

const user = JSON.parse(localStorage.getItem("userData")) || {}

const PIN = () => {
  const [loading, setLoading] = useState(null)
  const [password1Type, setPassword1Type] = useState("password")
  const [password2Type, setPassword2Type] = useState("password")
  const [disableButton, setDisableButton] = useState(false)
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const handlePassword1Type = () => {
    if (password1Type === "text") setPassword1Type("password")
    else setPassword1Type("text")
  }
  const handlePassword2Type = () => {
    if (password2Type === "text") setPassword2Type("password")
    else setPassword2Type("text")
  }

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary">
          <Row>
            <Col>
              <div className="text-white p-4">
                <p>Change Your PIN</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-2 px-0">
          <div className="pt-3">
            <Formik
              initialValues={{
                currentPin: "",
                newPin: "",
              }}
              validationSchema={Yup.object({
                currentPin: Yup.string().matches(
                  /(^[0-9]+$)/,
                  "PIN can only contain digit number"
                ),
                newPin: Yup.string().matches(
                  /(^[0-9]+$)/,
                  "PIN can only contain digit number"
                ),
              })}
              onSubmit={async (values, { resetForm }) => {
                console.log(values)
                setLoading(true)
                const data = {
                  orgId: "939001",
                  userGlobalId: user?.userGlobalId,
                  existingPin: values.currentPin,
                  newPin: values.newPin,
                }

                const res = await changePin(data)
                resetForm({ values: "" })
                setLoading(null)
                if (res === undefined) {
                  alert("Please check your internet connection")
                  setLoading(null)
                }
                if (res?.success === false) {
                  console.log(res)
                  setLoading(null)
                  setWarningFeedbackModal(prev => ({
                    ...prev,
                    open: true,
                    openFor: "mobile",
                    success: false,
                    error: true,
                    title: "Failed!",
                    message: res.message,
                  }))
                } else if (res?.success === true) {
                  console.log(res)
                  setFeedbackModal(prev => ({
                    ...prev,
                    open: true,
                    openFor: "mobile",
                    success: true,
                    error: false,
                    title: "Done!",
                    message: res.message,
                  }))
                }
              }}
            >
              {props => {
                return (
                  <Form className="form-horizontal">
                    <FormGroup>
                      <Label>Existing PIN</Label>
                      <InputGroup>
                        <Input
                          name="currentPin"
                          type={password1Type}
                          className="form-control mx-0"
                          placeholder="Enter your current PIN"
                          onChange={props.handleChange}
                          value={props.values.currentPin}
                          onBlur={props.handleBlur}
                        />
                        <div
                          className="input-group-append"
                          style={{ cursor: "pointer" }}
                          onClick={handlePassword1Type}
                        >
                          <span className="input-group-text">
                            {password1Type === "text" ? (
                              <i class="fa fa-low-vision"></i>
                            ) : (
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            )}
                          </span>
                        </div>
                      </InputGroup>
                      {props.touched.currentPin && props.errors.currentPin ? (
                        <p style={{ color: "red", fontSize: "11px" }}>
                          {props.errors.currentPin}
                        </p>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>New PIN</Label>
                      <InputGroup>
                        <Input
                          name="newPin"
                          type={password2Type}
                          className="form-control mx-0"
                          placeholder="Enter your new PIN"
                          onChange={props.handleChange}
                          value={props.values.newPin}
                          onBlur={props.handleBlur}
                        />
                        <div
                          className="input-group-append"
                          style={{ cursor: "pointer" }}
                          onClick={handlePassword2Type}
                        >
                          <span className="input-group-text">
                            {password2Type === "text" ? (
                              <i class="fa fa-low-vision"></i>
                            ) : (
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            )}
                          </span>
                        </div>
                      </InputGroup>
                      {props.touched.newPin && props.errors.newPin ? (
                        <p style={{ color: "red", fontSize: "11px" }}>
                          {props.errors.newPin}
                        </p>
                      ) : null}
                    </FormGroup>

                    <FormGroup className="select2-container">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <div
                        className="mt-4"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          className="btn btn-primary waves-effect waves-light "
                          type="submit"
                          style={{ width: "42%" }}
                          disabled={!disableButton}
                        >
                          {loading ? "Submitting" : "Submit"}
                        </button>

                        <Button
                          style={{ width: "42%" }}
                          type="button"
                          color="link"
                        >
                          <Link to="/">
                            {loading ? "Cancelling" : "Cancel"}
                          </Link>
                        </Button>
                      </div>

                      <i style={{ color: "#000", fontSize: "11px" }}>
                        Kindly click on the captcha field to enable the "Submit"
                        button
                      </i>
                    </FormGroup>
                  </Form>
                )
              }}
            </Formik>
            {feedbackModal.open && (
              <SweetAlert
                title={feedbackModal.title}
                success
                confirmBtnBsStyle="success"
                onConfirm={closeFeedbackModal}
              >
                {feedbackModal.message}
              </SweetAlert>
            )}
            <>
              {warningFeedbackModal.open && (
                <SweetAlert
                  title={warningFeedbackModal.title}
                  danger
                  confirmBtnBsStyle="danger"
                  onConfirm={closeWarningFeedbackModal}
                >
                  {warningFeedbackModal.message}
                </SweetAlert>
              )}
            </>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

PIN.propTypes = {
  t: PropTypes.any,
}

export default PIN
