import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import IdleTimer from "react-idle-timer"
import SweetAlert from "react-bootstrap-sweetalert"
import { Col } from "reactstrap"
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/Rightbar"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      timeout: 1000 * 2000 * 1,
      userLoggedIn: false,
      isTimedOut: false,
      confirm: null,
      dynamic_description: "",
      dynamic_title: "",
      success_dlg: null,
      error_dlg: null,
    }
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this)
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  toggleRightSidebar() {
    this.props.toggleRightSidebar()
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scroll Top to 0
    window.scrollTo(0, 0)
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

    document.title = currentage + " | Vomoz - Dashboard"
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme)
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth)
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType)
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme)
    }

    if (this.props.showRightSidebar) {
      this.toggleRightSidebar()
    }
  }
  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile)
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile)
    }
  }
  _onAction(e) {
    // console.log("user did something", e)
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    // console.log("user is active", e)
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    // console.log("user is idle", e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      this.props.history.push("/lock-screen")
    } else {
      this.setState({ confirm: true })
      this.idleTimer.reset()
      this.setState({ isTimedOut: true })
    }
  }

  handleClose() {
    this.setState({ confirm: false })
  }

  handleLogout() {
    this.setState({ confirm: false })
    this.props.history.push("/lock-screen")
  }

  render() {
    const { confirm } = this.state
    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header
            toggleMenuCallback={this.toggleMenuCallback}
            toggleRightSidebar={this.toggleRightSidebar}
          />
          <Sidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          />
          <div className="main-content">{this.props.children}</div>
          <Footer />
        </div>
        <Rightbar />
        <Col xl="3" lg="4" sm="6" className="mb-2">
          {confirm && (
            <SweetAlert
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={this.handleClose}
              onCancel={() => this.setState({ confirm: false })}
              confirmBtnText="Stay Connected"
              cancelBtnText="Logout"
              title="Your Session is About to Expire!"
            >
              Logging off in 5s seconds.<br></br>
            </SweetAlert>
          )}
        </Col>
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.bool,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  toggleRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout))
