import { LANDING_PAGE } from "helpers/constants"
import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const [name, setName] = useState("Admin")
  const [userParams, setUserParams] = useState(null)

  const toggle = () => {
    setMenu(!menu)
  }

  const logout = () => {
    console.log("Logging Out")
    localStorage.removeItem("userData")
    // localStorage.setItem("userData", JSON.stringify({}))
    window.location.href = LANDING_PAGE
    return null
  }

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("userData"))
        setName(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("userData"))
        setName(obj.username)
      }
    }
  }, [])

  const getUserAccountParameters = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const res = await userAccountParameters(data)
    if (res) setUserParams(res?.data)
  }

  useEffect(() => {
    getUserAccountParameters()
  }, [])

  console.log(userParams)

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={userParams?.profilePicture}
            alt={userParams?.firstName}
          />
          <span className="d-none d-xl-inline-block ml-2 mr-1">{name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        {/* <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle> */}
        {/* <DropdownMenu right> */}
        <DropdownMenu left>
          <DropdownItem tag="a" href="/account/settings">
            <i className="bx bx-user font-size-16 align-middle mr-1" />
            {props.t("Update Profile")}
          </DropdownItem>
          <DropdownItem tag="a" href="/account/voucher-recharge">
            <i className="bx bx-gift font-size-16 align-middle mr-1" />
            {props.t("My Voucher")}
          </DropdownItem>
          <DropdownItem tag="a" href="/account/settings">
            <span className="badge badge-success float-right mt-1"></span>
            <i className="bx bx-wrench font-size-17 align-middle mr-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="/lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle mr-1" />
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Button onClick={logout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            <span style={{ color: "#000" }}>{props.t("Logout")}</span>
          </Button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileMenu))
