//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { userAccountParameters } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import Form from "./DeleteAccountForm"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const DeleteAccount = props => {
  const [loading, setLoading] = useState(null)
  const [load, setLoad] = useState(null)
  const [userFirstName, setUserFirstName] = useState(null)
  const [userLastName, setUserLastName] = useState(null)
  const [userEmail, setUserEmail] = useState(null)

  const fetchUserAccountParameters = async () => {
    setLoading(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    if (userParameters) {
      setLoading(false)
      setUserFirstName(userParameters.firstName)
      setUserLastName(userParameters.lastName)
      setUserEmail(userParameters.email)
      setLoad(true)
    }
  }

  useEffect(() => {
    fetchUserAccountParameters()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Account"} breadcrumbItem={"Delete"} />

          <Row className={"justify-content-center"}>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Account Deletion Page</CardTitle>
                  <CardSubtitle></CardSubtitle>
                  <br />
                  {load ? (
                    <Form
                      initialFirstName={userFirstName}
                      initialLastName={userLastName}
                      initialEmail={userEmail}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DeleteAccount.propTypes = {
  t: PropTypes.any,
}

export default DeleteAccount
