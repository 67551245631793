import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { userAccountParameters } from "helpers/requests"

// Redux Account Params  States
import { ACCOUNT_PARAMS } from "./actionTypes"
import { userAccountParamsSuccess, userAccountParamsError } from "./actions"

// Incluse Helpers & Methods needed
import axios, { config } from "helpers/axios"

// Generator functions
function* accountParams({ payload }) {
  try {
    const response = yield call(userAccountParameters, payload)
    console.log(response)
    if (response.success === true) {
      yield put(userAccountParamsSuccess(response.data))
      localStorage.setItem("userData", JSON.stringify(response.data))
    }
  } catch (error) {
    console.log("Error")
    yield put(userAccountParamsError(error))
  }
} 

function* accountParamsSaga() {
  yield takeLatest(ACCOUNT_PARAMS, accountParams)
}

export default accountParamsSaga
