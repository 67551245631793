import { Form, Formik } from "formik"
import {
  existingBeneficiariesTransferCredit,
  systemSettings,
  transferCredit,
  userAccountParameters,
} from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../../components/CustomSelect"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const ExistingBeneficiaries = props => {
  const { toggle, refetch } = props

  const [userAccountDetails, setUserAccountDetails] = useState({})
  const [systemSettingsDetails, setSystemSettingsDetails] = useState(null)
  const [loading, setLoading] = useState(null)
  const [creditValue, setCreditValue] = useState(null)
  const [currencyCode, setCurrencyCode] = useState("")
  const [minimumAmountToTransfer, setMinimumAmountToTransfer] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState({
    data: [],
    error: false,
  })
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const convertAmountToTwoDecimalPlace = amount => parseFloat(amount).toFixed(2)
  const convertUSDollarsCurrency3LettersToCurrencySymbol = currencyLetters => {
    if (currencyLetters === "USD") {
      return "$"
    } else {
      return currencyLetters
    }
  }

  const onCaptchaChange = value => {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const fetchBeneficiary = async () => {
    setBeneficiaries(prev => ({ ...prev }))
    const data = { orgId: "939001", userGlobalId: user?.userGlobalId }
    const res = await existingBeneficiariesTransferCredit(data)
    if (res.success === true) {
      console.log(res.data)
      setLoading(null)
      setBeneficiaries(prev => ({
        ...prev,
        data: res.data.returnedRecords.map(ben => ({
          value: ben.email,
          label: `${ben.firstName} ${ben.lastName}`,
        })),
      }))
    } else {
      setBeneficiaries(prev => ({ ...prev, loading: false, error: true }))
    }
    console.log(beneficiaries?.data)
  }
  useEffect(() => {
    fetchBeneficiary()
  }, [])

  const getAccountDetails = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    console.log(userParameters)
    if (userParameters) {
      setUserAccountDetails(userParameters)
    }
  }

  useEffect(() => {
    getAccountDetails()
  }, [])

  const getSystemSettings = async () => {
    const data = {
      orgId: "939001",
    }
    const details = await systemSettings(data)
    const systemDetails = details?.data.companyFinancials
    console.log(systemDetails)
    if (systemDetails) {
      setSystemSettingsDetails(systemDetails)
      setMinimumAmountToTransfer(systemDetails?.transferCreditMinimunAmount)
      setCurrencyCode(systemDetails?.currency3LetterCode)
    }
  }

  useEffect(() => {
    getSystemSettings()
  }, [])

  console.log(systemSettingsDetails)
  const minimum = Number(minimumAmountToTransfer)
  console.log(minimum)

  const formikInitialValues = {
    beneficiaryEmailOrPhone: "",
    amount: "",
    comment: "",
  }

  return (
    <div>
      <div className="modal-content">
        <ModalHeader>
          Balance:
          {" " +
            convertUSDollarsCurrency3LettersToCurrencySymbol(
              userAccountDetails.currency3Letters
            ) +
            convertAmountToTwoDecimalPlace(userAccountDetails.accountBalance)}
        </ModalHeader>
        <Formik
          initialValues={formikInitialValues}
          validationSchema={Yup.object({
            beneficiaryEmailOrPhone: Yup.string().required("Required"),
            amount: Yup.number()
              .min(2, "Minimum amount to transfer is $2")
              .required("Required"),
            comment: Yup.string().max(
              200,
              "Comment must not exceed 200 characters"
            ),
          })}
          onSubmit={async (values, { resetForm }) => {
            setCreditValue(values.amount)
            const data = {
              orgId: "939001",
              userGlobalId: user?.userGlobalId,
              isNewBeneficiary: "false",
              saveBeneficiaryInfoIfNew: "false",
              beneficiaryEmailOrPhone: values.beneficiaryEmailOrPhone,
              amount: values.amount,
              comment: values.comment,
            }
            console.log(data)
            const res = await transferCredit(data)
            resetForm({ values: "" })
            setLoading(null)
            if (res === undefined) {
              alert("Please check your internet connection")
              setLoading(false)
            }
            if (res?.success === false) {
              console.log(res)
              setLoading(false)
              setInfo({
                confirm: false,
                error_dlg: true,
                dynamic_title: "Failed!",
                dynamic_description: res?.message,
              })
              getAccountDetails()
            } else if (res?.success === true) {
              console.log(res)
              setLoading(false)
              setInfo({
                confirm: false,
                success_dlg: true,
                dynamic_title: "Added!",
                dynamic_description: res?.message,
              })
              refetch()
              getAccountDetails()
            }
          }}
        >
          {props => {
            return (
              <Form className="justify-content-center">
                <ModalBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup className="select2-container">
                        {console.log(beneficiaries.data)}
                        <Label for="formrow-beneficiary-Input">
                          Beneficiary
                        </Label>
                        <CustomSelect
                          classNamePrefix="select2-selection"
                          name="beneficiaryEmailOrPhone"
                          value={props.values.beneficiaryEmailOrPhone}
                          options={beneficiaries.data}
                          onChange={props.handleChange(
                            "beneficiaryEmailOrPhone"
                          )}
                        />

                        {props.touched.beneficiaryEmailOrPhone &&
                        props.errors.beneficiaryEmailOrPhone ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.beneficiaryEmailOrPhone}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-email-Input"> Amount</Label>

                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-email-Input"
                          placeholder={"Enter Amount"}
                          name="amount"
                          onChange={props.handleChange}
                          value={props.values.amount}
                          onBlur={props.handleBlur}
                        />

                        {props.touched.amount && props.errors.amount ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.amount}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-comment-Input">Comment</Label>

                        <Input
                          type="textarea"
                          rows={5}
                          cols={30}
                          className="form-control"
                          id="formrow-comment-Input"
                          placeholder="Enter your comment"
                          name="comment"
                          onChange={props.handleChange}
                          value={props.values.comment}
                          onBlur={props.handleBlur}
                        />

                        {props.touched.comment && props.errors.comment ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.comment}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>{" "}
                    <Col md={12}>
                      <FormGroup
                        className="select2-container"
                        style={{ maxWidth: "50%" }}
                      >
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={onCaptchaChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col md={2} style={{ flex: "1" }}></Col>
                    <Col md={5} style={{ flex: "2" }}>
                      <Button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        onClick={() => setInfo({ confirm: true })}
                        color="primary"
                        disabled={!disableButton}
                      >
                        {loading ? "Sending..." : "Send"}
                      </Button>
                    </Col>
                    <Col md={3} style={{ flex: "1" }}>
                      <Button color="link" onClick={toggle}>
                        Cancel
                      </Button>
                    </Col>
                    <Col md={2} style={{ flex: "1" }}></Col>
                    <br />
                    <i
                      style={{
                        color: "#000",
                        fontSize: "11px",
                        marginLeft: "10px",
                      }}
                    >
                      Kindly check the captcha checkbox to enable this "Send"
                      button
                    </i>
                  </Row>
                </ModalBody>
                {info.confirm ? (
                  <SweetAlert
                    title="Are you sure?"
                    showCancel
                    confirmBtnText="Send"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancel"
                    cancelBtnBsStyle="danger"
                    onConfirm={props.handleSubmit}
                    onCancel={() =>
                      setInfo({
                        confirm: false,
                        error_dlg: true,
                        dynamic_title: "Cancelled",
                        dynamic_description: "Process has been terminated!",
                      })
                    }
                  >
                    <span>
                      You are about to transfer ${props.values.amount} to{" "}
                      {props.values.beneficiaryEmailOrPhone}. Please click
                      "Send" button to confirm your transaction or "Cancel" to
                      exit.
                      <br />
                      <br />
                      <strong>
                        Note: This service attracts a non-refundable transaction
                        fee of{" "}
                        {convertUSDollarsCurrency3LettersToCurrencySymbol(
                          systemSettingsDetails.currency3LetterCode
                        ) + systemSettingsDetails.transferCreditTransactionFee}
                        .{" "}
                      </strong>
                    </span>
                  </SweetAlert>
                ) : null}
              </Form>
            )
          }}
        </Formik>
        <Col lg={12}>
          {info.success_dlg ? (
            <SweetAlert
              title={info.dynamic_title}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}
          <div>
            {info.error_dlg ? (
              <SweetAlert
                title={info.dynamic_title}
                danger
                confirmBtnBsStyle="danger"
                onConfirm={() => setInfo({ error_dlg: false })}
              >
                {info.dynamic_description}
              </SweetAlert>
            ) : null}
          </div>
        </Col>
      </div>
    </div>
  )
}

export default ExistingBeneficiaries
