import React from "react"
import { Button, Card, CardBody, Col, Media } from "reactstrap"

const AutoRechargeCard = props => {
  const { pricing, handleDelete, handleEdit } = props
  const getRealDate = date => {
    if (date === "") {
      return "-"
    } else {
      const str = new Date(date).toDateString()

      let splitDate = str.split(" ")
      return (
        <>
          {splitDate[0] +
            " " +
            splitDate[1] +
            " " +
            splitDate[2] +
            "," +
            " " +
            splitDate[3]}
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <Col lg={6}>
        <Card className="plan-box">
          <CardBody className="p-4">
            <Media>
              <Media body>
                <h5>Auto Recharge</h5>
                {/* <p className="text-muted">Your one-time recharge option</p> */}
              </Media>
              <div className="ml-3">
                <i className={"bx bx-purchase-tag" + " h1 text-primary"} />
              </div>
            </Media>
            {console.log(pricing)}

            <div className="plan-features mt-2">
              <p>
                <i className="bx bx-checkbox-square text-primary mr-2" />
                Balance before recharge:{" "}
                <strong>${pricing.accountBalanceBeforeRecharge}</strong>
              </p>
              <p>
                <i className="bx bx-checkbox-square text-primary mr-2" /> Amount
                to recharge: <strong>${pricing.amountToRecharge}</strong>
              </p>
              <p>
                <i className="bx bx-checkbox-square text-primary mr-2" /> Setup
                date: {getRealDate(pricing.setupDate)}
              </p>
            </div>

            <div className="text-center plan-btn">
              <Button
                color="primary"
                onClick={handleEdit}
                className="btn btn-primary btn-sm waves-effect waves-light mr-4"
              >
                Update
              </Button>

              <Button
                color="primary"
                onClick={handleDelete}
                className="btn btn-primary btn-sm waves-effect waves-light ml-4"
              >
                Delete
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default AutoRechargeCard
