import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { verifyMobile } from "helpers/requests"
import React, { useState } from "react"
import AuthCode from "react-auth-code-input"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap"
// import images
import {
  default as logodark,
  default as logolight,
} from "../../assets/images/vomoz/ball.png"

const TwostepVerification = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(null)
  const [code, setCode] = useState("")
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
  })

  const handleResend = async () => {
    const data = {
      mobile: localStorage.getItem("vomoz-mobile"),
      orgId: "939001",
      sendSms: "true",
    }
    const res = await verifyMobile(data)
    console.log(res)
    if (!res.success) {
      setLoading(false)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed!",
        dynamic_description: res?.message,
      })
      console.log("error")
    } else {
      setLoading(false)
      console.log("success")
      localStorage.setItem("vomoz-code", res.data.code)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Done!",
        dynamic_description: res?.message,
      })
      setTimeout(() => {
        history.push("/auth-mobile")
      }, 5000)
    }
  }

  const VerifyOTP = event => {
    setLoading(true)
    event.preventDefault()
    console.log("OTP Verification")
    const savedCode = localStorage.getItem("vomoz-code")
    console.log(savedCode)

    if (code === savedCode) {
      console.log("Number successfully verified")
      localStorage.setItem("vomoz-verified", true)
      const existingCustomer = localStorage.getItem("isExistingCustomer")
      setLoading(false)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Verified!",
        dynamic_description: "Number successfully verified",
      })
      setTimeout(() => {
        if (existingCustomer === "false") history.push("/join")
        else history.push("/login")
      }, 3000)
    } else {
      setLoading(false)
      console.log("error")
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed!",
        dynamic_description: "Error",
      })
    }
  }

  return (
    <React.Fragment>
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="20"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3">Mobile Verification</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your mobile</h4>
                        <p className="mb-5">
                          Please enter the code sent to{" "}
                          <span className="font-weight-semibold">
                            your number <br />
                            <Link to="/mobile-verification">change mobile</Link>
                          </span>
                        </p>

                        <Form onSubmit={VerifyOTP}>
                          <Row>
                            <Col xs={12}>
                              <FormGroup className="verification">
                                <label
                                  htmlFor="digit1-input"
                                  className="sr-only"
                                >
                                  Digit 1
                                </label>
                                <AuthCode
                                  characters={6}
                                  className="form-control form-control-lg text-center"
                                  inputStyle={{
                                    width: "50px",
                                    height: "50px",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    margin: "4px",
                                    border: "1px solid #ced4da",
                                    textTransform: "uppercase",
                                  }}
                                  onChange={value => setCode(value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div className="mt-4">
                            <Button
                              type="submit"
                              className="btn btn-success btn-block"
                            >
                              {loading ? "Verifying" : "Verify"}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Didn't receive a code ?{" "}
                  <a
                    // href="#"
                    className="font-weight-medium text-primary"
                    onClick={handleResend}
                  >
                    {" "}
                    {loading ? "Resending" : "Resend"}{" "}
                  </a>{" "}
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TwostepVerification
