import { Form, Formik } from "formik"
import { buyCreditWithPayPal, userAccountParameters } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../../components/CustomSelect"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const PayWithPaypal = ({ editInfo, isOpen, toggle, refreshTable }) => {
  const [loading, setLoading] = useState(null)
  const [info, setInfo] = useState({})
  const [checked, setChecked] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [dataInfo, setDataInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const [amountCredUserCanBuy, setAmountCredUserCanBuy] = useState([])

  const closeSuccessFeedbackModal = () => {
    setDataInfo({ success_dlg: false })
  }

  const closeErrorFeedbackModal = () => {
    setDataInfo({ error_dlg: false })
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const fetchAmountDetails = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    if (parameters) {
      console.log(parameters.data)
      setLoading(null)
      const userParameters = parameters?.data
      console.log(userParameters)
      const amountCreditUserCanBuy = userParameters?.amountCreditUserCanBuy
      if (amountCreditUserCanBuy) {
        setAmountCredUserCanBuy(amountCreditUserCanBuy)
        console.log(amountCredUserCanBuy)
      }
    }
  }

  useEffect(() => {
    fetchAmountDetails()
  }, [])

  useEffect(() => {
    setInfo(editInfo)
  }, [editInfo])

  const handleChange = event => {
    setChecked(event)
  }

  return (
    <>
      {dataInfo.success_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeSuccessFeedbackModal}
        >
          {dataInfo.dynamic_description}
        </SweetAlert>
      ) : null}

      {dataInfo.error_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          error
          confirmBtnBsStyle="danger"
          onConfirm={closeErrorFeedbackModal}
        >
          {dataInfo.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader>Buy With Paypal</ModalHeader>
          <Formik
            initialValues={{
              amount: "",
            }}
            validationSchema={Yup.object({
              amount: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              console.log(editInfo)
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                amount: values.amount,
              }
              console.log(data)
              const res = await buyCreditWithPayPal(data)
              resetForm({ values: "" })
              setLoading(false)
              if (res === undefined) {
                alert("Please check your internet connection")
              }
              if (res?.success) {
                setLoading(false)
                console.log(res)
                setDataInfo({
                  confirm: false,
                  success_dlg: true,
                  dynamic_title: "Success!",
                  dynamic_description: res?.message,
                })
                refreshTable()
                toggle()
                setTimeout(() => {
                  window.location.href = res?.data.payPalUrlString
                }, 1000)
              } else {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  error_dlg: true,
                  dynamic_title: "Failed",
                  dynamic_description: res?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Amount</Label>
                          <CustomSelect
                            classNamePrefix="select2-selection"
                            name="amount"
                            value={props.values.amount}
                            options={amountCredUserCanBuy.map(item => ({
                              label: item,
                              value: item,
                            }))}
                            onChange={props.handleChange("amount")}
                          />
                          {props.touched.amount && props.errors.amount ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.amount}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          color="primary"
                          disabled={!disableButton}
                        >
                          {loading ? "Submitting" : "Submit"}
                        </Button>
                      </Col>
                      <Col md={3} style={{ flex: "1" }}>
                        <Button color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <br />
                      <i
                        style={{
                          color: "#000",
                          fontSize: "11px",
                          marginLeft: "10px",
                        }}
                      >
                        Kindly check the captcha checkbox to enable this "Send"
                        button
                      </i>
                    </Row>
                  </ModalBody>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default PayWithPaypal
