import { uploadProfilePictureOrCoverPhoto } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { FaCamera } from "react-icons/fa"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Label, Row } from "reactstrap"
import { userAccountParams } from "store/actions"
import "./dashboard.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", error => reject(error))
    image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve(file)
    }, "image/jpeg")
  })
}

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const WelcomeComp = ({ user, loading, onGetAccountParams }) => {
  const [accountBalance, setAccountBalance] = useState(0)
  const [rewardPoints, setRewardPoints] = useState(0)
  const [open, setOpen] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [mobile, setMobile] = useState("")
  const [profilePicture, setProfilePicture] = useState("")
  const [coverPicture, setCoverPicture] = useState("")
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [imageSrc, setImageSrc] = useState(null)

  const toggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData")) || {}

    if (user) {
      const data = {
        orgId: "939001",
        userGlobalId: user?.userGlobalId,
        userIpAddress: "127.0.0.1",
        userDeviceType: "Windows 10",
        userBrowserType: "Chrome",
        userRefererUrl: "www.google.com",
      }
      onGetAccountParams(data)
    }
  }, [])

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setAccountBalance(user.accountBalance)
      setRewardPoints(user.rewardPointsBalance)
      setMobile(user.mobile)
      setProfilePicture(user.profilePicture)
      setCoverPicture(user.coverPicture)
    }
  }, [user])

  const handleShowDialog = e => {
    setOpen(!open)
  }

  const handleChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl)
    }
    setShowButtons(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
    formData.append("orgId", "939001")
    formData.append("userGlobalId", user?.userGlobalId)
    formData.append("imageMedia", croppedImage)
    formData.append("mediaType", "profilePicture")

    const res = await uploadProfilePictureOrCoverPhoto(formData)

    setLoading(false)
    if (res === undefined) {
      alert("Please check your internet connection")
      setLoading(null)
    }
    if (res?.success === false) {
      setLoading(false)
      setWarningFeedbackModal(prev => ({
        ...prev,
        open: true,
        openFor: "mobile",
        success: false,
        error: true,
        title: "Failed!",
        message: res.message,
      }))
    } else if (res?.success === true) {
      setLoading(false)
      setOpen(!open)
      setFeedbackModal(prev => ({
        ...prev,
        open: true,
        openFor: "mobile",
        success: true,
        error: false,
        title: "Profile Picture Modified!",
        message: res.message,
      }))
      refreshData()
      toggle()
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    console.log(croppedArea, croppedAreaPixels)
  }, [])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5>Welcome {firstName},</h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={coverPicture} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="6">
              <div
                className="profile-user-wid mb-4"
                style={{ position: "relative" }}
              >
                <img
                  src={profilePicture}
                  alt=""
                  className="img-thumbnail rounded-circle avatar-xxl"
                  onClick={handleShowDialog}
                />
                <div
                  className="p-0"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 50,
                  }}
                >
                  <Label
                    for="profilePicture"
                    className="col-sm-12 col-form-Label"
                  >
                    <input
                      id="profilePicture"
                      name="profilePicture"
                      onChange={handleChange}
                      type="file"
                      accept="image/png, image/jpeg"
                      className="form-control no-display profile-photo-input"
                      hidden
                    />
                    <Link to="/account/settings">
                      <FaCamera size="2em" className="camera-icon" />
                    </Link>
                  </Label>
                </div>
                {open && (
                  <dialog
                    className="dialog"
                    style={{ position: "absolute" }}
                    open
                    onClick={handleShowDialog}
                  >
                    <img
                      className="image"
                      src={profilePicture}
                      alt=""
                      onClick={handleShowDialog}
                    />
                  </dialog>
                )}
              </div>

              <h5 className="font-size-15 text-truncate">
                {firstName} {lastName}
              </h5>
              <p className="text-muted mb-0 text-truncate">{mobile}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4 mb-1">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">
                      {" "}
                      {Number(rewardPoints).toFixed(0)}
                    </h5>
                    <p className="text-muted mb-0">Points</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">
                      ${Number(accountBalance).toFixed(2)}
                    </h5>

                    <p className="text-muted mb-0">Balance</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div className="mt-4">
                      <Link
                        to="/account/call-log"
                        className="btn btn-primary waves-effect waves-light btn-sm"
                      >
                        Call Log <i className="mdi mdi-arrow-right ml-1" />
                      </Link>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="mt-4">
                      <Link
                        to="/account/buy-credit"
                        className="btn btn-primary waves-effect waves-light btn-sm"
                      >
                        Buy Credit <i className="mdi mdi-arrow-right ml-1" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

WelcomeComp.propTypes = {
  user: PropTypes.any,
  accountParams: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.AccountParams.user,
  loading: state.AccountParams.loading,
})

const mapDispatchToProps = dispatch => ({
  onGetAccountParams: data => dispatch(userAccountParams(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeComp)
