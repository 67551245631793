//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { callLogs } from "helpers/requests"
import { MDBDataTable } from "mdbreact"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useReactToPrint } from "react-to-print"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import vomozLogo from "../../assets/images/vomoz/ball.png"
import CallsLogColumn from "./dataTableColumns"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}
console.log("Hi!", user)

const CallsLog = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(null)
  const [logs, setLogs] = useState([])

  const handleChange = date => {
    setStartDate(date)
    // console.log(date.toLocaleDateString("en-CA"))
  }
  const handleChange2 = date => {
    setEndDate(date)
  }

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const getCallsLogs = async () => {
    setLoading(true)
    // console.log(endDate?.toLocaleDateString("en-CA"))
    const data = {
      orgId: "939001",
      userIpAddress: "127.0.0.1",
      userGlobalId: user?.userGlobalId,
      startDate: startDate?.toLocaleDateString("en-CA") || null,
      endDate: endDate?.toLocaleDateString("en-CA") || null,
    }

    const log = await callLogs(data)
    console.log(log)
    const Callslog = log?.data?.returnedRecords
    if (Callslog) setLogs(Callslog)
    setLoading(false)
  }
  useEffect(() => {
    getCallsLogs()
  }, [endDate])

  const handleAmountBilled = billed => {
    if (billed === "-0") {
      return "0.00"
    } else {
      return Number.parseFloat(billed).toFixed(2)
    }
  }

  const calcLocalDate = startTime => {
    let splitDate = new Date(startTime).toDateString().split(" ")
    return (
      splitDate[0] +
      " " +
      splitDate[1] +
      " " +
      splitDate[2] +
      "," +
      " " +
      splitDate[3]
    )
  }

  const calcDurationInMin = duration => {
    let durationInMin = Math.floor(duration / 60)
    let durationInSec = duration % 60
    return durationInMin + "m" + " " + durationInSec + "s"
  }

  const calcLocalTime = startTime => {
    let realTime = new Date(startTime).toLocaleTimeString()
    return realTime.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1")
  }

  const data = {
    columns: CallsLogColumn,
    rows: [
      ...logs.map((row, index) => ({
        ...row,
        id: <>{index + 1}</>,
        durationInSeconds: calcDurationInMin(row?.durationInSeconds),
        rate: row.rate + " " + "cents",
        amountBilled: "$" + handleAmountBilled(row?.amountBilled),
        startTime: (
          <>
            {calcLocalDate(row?.startTime)} <br />{" "}
            {calcLocalTime(row?.startTime)}
          </>
        ),
      })),
    ],
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading && (
          <SweetAlert
            title="Loading"
            timeout={2000}
            showConfirm={false}
            onConfirm={() => setLoading(null)}
          >
            <img className="spinning loading-logo" src={vomozLogo} />
          </SweetAlert>
        )}
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Calls"} breadcrumbItem={"Calls Log"} />
          <Row>
            <Col>
              <React.Fragment>
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <CardTitle className="mb-4">Manage Call Log</CardTitle>
                      {logs.length > 0 && (
                        <Form className="mb-3">
                          <Row>
                            <div className="col-xl col-sm-4">
                              <FormGroup className="mt-0 mb-1">
                                <Label>Start Date :</Label>
                                <DatePicker
                                  selected={startDate}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholderText="Select date"
                                />
                              </FormGroup>
                            </div>
                            <div className="col-xl col-sm-4">
                              <FormGroup className="mt-0 mb-1">
                                <Label>End Date :</Label>
                                <DatePicker
                                  selected={endDate}
                                  onChange={handleChange2}
                                  className="form-control"
                                  placeholderText="Select date"
                                />
                              </FormGroup>
                            </div>

                            <div className="col-xl col-sm-4 mt-1 mb-1">
                              <Button
                                type="button"
                                color="primary"
                                className="btn-square waves-effect waves-light mb-0 mt-4"
                                onClick={handlePrint}
                                block
                              >
                                Print
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      )}
                    </div>

                    <div
                      style={{ width: "100%", overflow: "auto" }}
                      ref={componentRef}
                    >
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <h4>Loading...</h4>
                        </div>
                      ) : !logs ? (
                        "You do not have a call log yet. You are yet to make a call with your account. Kindly make a call with it now to have a call history."
                      ) : logs.length > 0 ? (
                        <MDBDataTable
                          hover
                          entriesOptions={[100, 200, 500, 1000]}
                          entries={100}
                          pagesAmount={4}
                          data={data}
                          striped
                          style={{
                            marginTop: "10px",
                          }}
                        />
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </React.Fragment>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CallsLog.propTypes = {
  t: PropTypes.any,
}

export default CallsLog
