import { Form, Formik } from "formik"
import { contactCustomerService, systemSettings } from "helpers/requests"
import { Base64 } from "js-base64"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import { useHistory } from "react-router-dom"
import { Col, FormGroup, Input, Label } from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../components/CustomSelect"
import "./styles.scss"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
  },
}

const ContactForm = props => {
  const history = useHistory()
  const { initialFullName, initialEmail } = props
  const [loading, setLoading] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [subject, setSubject] = useState(null)
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }
  console.log(initialFullName)

  const formikInitialValues = {
    fullName: initialFullName,
    email: initialEmail,
    subject: "",
    emailBody: "",
  }

  const fetchSubject = async () => {
    const data = {
      orgId: "939001",
    }

    const response = await systemSettings(data)
    const subjectMenu = response?.data?.userDashboardContactUsSubjectMenu
    if (subjectMenu) {
      setSubject(
        subjectMenu.map(subjectMenuItem => ({
          label: subjectMenuItem,
          value: subjectMenuItem,
        }))
      )
    }
  }

  useEffect(() => {
    fetchSubject()
  }, [])

  return (
    <>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={Yup.object({
          fullName: Yup.string(),
          email: Yup.string().email("Invalid email address"),
          subject: Yup.string().required("Required"),
          emailBody: Yup.string()
            .max(500, "Must be 500 characters or less")
            .required("Required"),
        })}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true)
          const data = {
            orgId: "939001",
            userGlobalId: user?.userGlobalId,
            subject: values.subject,
            emailBody: Base64.encode(values.emailBody),
          }
          console.log(data)
          const res = await contactCustomerService(data)
          resetForm({ values: "" })
          setLoading(null)
          if (res === undefined) {
            alert("Please check your internet connection")
            setLoading(null)
          }
          if (!res?.success) {
            setLoading(null)
            setWarningFeedbackModal(prev => ({
              ...prev,
              open: true,
              success: false,
              error: true,
              title: "Failed!",
              message: res.message,
            }))
          } else if (res.success) {
            console.log(res)
            setFeedbackModal(prev => ({
              ...prev,
              open: true,
              success: true,
              error: false,
              title: "Sent!",
              message: res.message,
            }))
            setTimeout(() => {
              history.push("/")
            }, 2000)
          }
        }}
      >
        {props => {
          return (
            <Form className={"justify-content-center"}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="fullName"
                  className="form-control"
                  style={{
                    width: "100%",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                  placeholder="Enter Full Name"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  type="text"
                  value={props.values.fullName}
                />
                {props.touched.fullName && props.errors.fullName ? (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {props.errors.fullName}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label>Email</Label>
                <Input
                  name="email"
                  className="form-control"
                  style={{
                    width: "100%",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                  placeholder="Enter contact email"
                  type="email"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.email}
                />
                {props.touched.email && props.errors.email ? (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {props.errors.email}
                  </p>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>Subject</Label>
                <CustomSelect
                  name="subject"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange("subject")}
                  value={props.values.subject}
                  options={subject}
                />

                {props.touched.subject && props.errors.subject ? (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {props.errors.subject}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label>Message Body</Label>
                <Input
                  name="emailBody"
                  className="form-control"
                  placeholder="Kindly let us know why you want to delete your account"
                  type="textarea"
                  rows={6}
                  cols={30}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.emailBody}
                />
                {props.touched.emailBody && props.errors.emailBody ? (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {props.errors.emailBody}
                  </p>
                ) : null}
              </FormGroup>
              <FormGroup className="select2-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onCaptchaChange}
                />
              </FormGroup>

              <FormGroup className="row justify-content-end">
                <Col sm={12}>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light btn-block"
                    disabled={!disableButton}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                  <br />
                  <i style={{ color: "#000", fontSize: "11px" }}>
                    Kindly drop your message for us. We promise to get back to
                    you.
                  </i>
                </Col>
              </FormGroup>
            </Form>
          )
        }}
      </Formik>
      <Col lg={12}>
        {feedbackModal.open && (
          <SweetAlert
            title={feedbackModal.title}
            success
            confirmBtnBsStyle="success"
            onConfirm={closeFeedbackModal}
          >
            {feedbackModal.message}
          </SweetAlert>
        )}
        <div>
          {warningFeedbackModal.open && (
            <SweetAlert
              title={warningFeedbackModal.title}
              danger
              confirmBtnBsStyle="danger"
              onConfirm={closeWarningFeedbackModal}
            >
              {warningFeedbackModal.message}
            </SweetAlert>
          )}
        </div>
      </Col>
    </>
  )
}

export default ContactForm
