import {
  ACCOUNT_CALLER_ID,
  ACCOUNT_PARAMS,
  ACCOUNT_PARAMS_ERROR,
  ACCOUNT_PARAMS_SUCCESS,
} from "./actionTypes"

export const userAccountParams = data => {
  return {
    type: ACCOUNT_PARAMS,
    payload: data,
  }
}

export const userAccountParamsSuccess = user => {
  return {
    type: ACCOUNT_PARAMS_SUCCESS,
    payload: user,
  }
}

export const userAccountParamsError = error => {
  return {
    type: ACCOUNT_PARAMS_ERROR,
    payload: error,
  }
}

export const userCallerID = data => {
  return {
    type: ACCOUNT_CALLER_ID,
    payload: data,
  }
}
