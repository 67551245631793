import { Form, Formik } from "formik"
import { showPin } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const initialStates = {
  feedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
  warningFeedbackModal: {
    open: false,
    message: "",
    title: "",
    openFor: "",
  },
}

const ShowPinModal = props => {
  const { isOpen, toggle, onSuccess } = props

  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(null)
  const [passwordType, setPasswordType] = useState("password")
  const [feedbackModal, setFeedbackModal] = useState(
    initialStates.feedbackModal
  )
  const [warningFeedbackModal, setWarningFeedbackModal] = useState(
    initialStates.warningFeedbackModal
  )

  const closeFeedbackModal = () => {
    setFeedbackModal(initialStates.feedbackModal)
    if (feedbackModal.openFor === "pin") {
      toggle()
    }
  }
  const closeWarningFeedbackModal = () => {
    setWarningFeedbackModal(initialStates.warningFeedbackModal)
    if (warningFeedbackModal.openFor === "pin") {
      toggle()
    }
  }

  const handlePasswordType = () => {
    if (passwordType === "text") setPasswordType("password")
    else setPasswordType("text")
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      password: password,
    }
    const passowrdReq = await showPin(data)
    if (passowrdReq.success) {
      onSuccess(passowrdReq.data.pin)
      toggle()
    } else {
      console.log(passowrdReq)
    }
  }

  //  N0L0g1n890@$

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Show PIN Procedure</ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col lg={12}>
                <br />
                <Formik
                  initialValues={{
                    password: "",
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    console.log(values)
                    setLoading(true)
                    const data = {
                      orgId: "939001",
                      userGlobalId: user?.userGlobalId,
                      password: values.password,
                    }
                    if (values.password) {
                      const res = await showPin(data)
                      resetForm({ values: "" })
                      setLoading(null)
                      if (res === undefined) {
                        alert("Please check your internet connection")
                        setLoading(null)
                      }
                      if (res?.success === false) {
                        console.log(res)
                        setLoading(null)
                        setWarningFeedbackModal(prev => ({
                          ...prev,
                          open: true,
                          openFor: "mobile",
                          success: false,
                          error: true,
                          title: "Failed!",
                          message: res.message,
                        }))
                      } else if (res?.success === true) {
                        console.log(res)
                        onSuccess(res.data.pin)
                        toggle()
                      }
                    }
                  }}
                >
                  {props => {
                    return (
                      <Form className={"justify-content-center"}>
                        <FormGroup className="row mb-4">
                          <Col sm={2}></Col>
                          <Col sm={8}>
                            <Label
                              for="horizontal-firstname-Input"
                              className="col-sm-12 col-form-Label"
                            >
                              Input Password
                            </Label>
                            <InputGroup>
                              <input
                                name="password"
                                value={props.values.password}
                                onChange={props.handleChange}
                                type={passwordType}
                                placeholder="Enter your login password"
                                className="form-control"
                                onBlur={props.handleBlur}
                              />
                              <div
                                className="input-group-append"
                                style={{ cursor: "pointer" }}
                                onClick={handlePasswordType}
                              >
                                <span className="input-group-text">
                                  {passwordType === "text" ? (
                                    <i class="fa fa-low-vision"></i>
                                  ) : (
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                  )}
                                </span>
                              </div>
                            </InputGroup>
                            {props.touched.password && props.errors.password ? (
                              <p style={{ color: "red", fontSize: "11px" }}>
                                {props.errors.password}
                              </p>
                            ) : null}
                          </Col>
                          <Col sm={2}></Col>
                        </FormGroup>
                        <Row>
                          <Col sm={2}></Col>
                          <Col sm={8}>
                            <FormGroup className="row justify-content-center">
                              <Button
                                type="submit"
                                color="primary"
                                className="btn btn-primary btn-block waves-effect waves-light"
                              >
                                {loading ? "Loading..." : "Load"}
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col sm={2}></Col>
                        </Row>
                      </Form>
                    )
                  }}
                </Formik>
                {feedbackModal.open && (
                  <SweetAlert
                    title={feedbackModal.title}
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={closeFeedbackModal}
                    timeout={2000}
                  >
                    {feedbackModal.message}
                  </SweetAlert>
                )}
                <div>
                  {warningFeedbackModal.open && (
                    <SweetAlert
                      title={warningFeedbackModal.title}
                      danger
                      confirmBtnBsStyle="danger"
                      onConfirm={closeWarningFeedbackModal}
                    >
                      {warningFeedbackModal.message}
                    </SweetAlert>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="danger" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  )
}

ShowPinModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ShowPinModal
