import { Form, Formik } from "formik"
import { addCountry, listOfCountries } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import CustomSelect from "../../../components/CustomSelect"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const AddCountry = props => {
  const [loading, setLoading] = useState(null)
  const [availableCountries, setAvailableCountries] = useState([])

  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const getListOfCountries = async () => {
    let data = {
      orgId: "939001",
    }
    const available = await listOfCountries(data)
    if (available) {
      setLoading(null)
      const AvailableCountries = available?.data?.returnedRecords
      console.log(AvailableCountries)
      if (AvailableCountries) {
        setAvailableCountries(
          AvailableCountries.map(memberCountry => ({
            label: memberCountry.country,
            value: memberCountry.country,
          }))
        )
        console.log(availableCountries)
      }
    }
  }

  useEffect(() => {
    getListOfCountries()
  }, [])

  const { isOpen, toggle, refreshTable } = props
  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
          confirmBtnBsStyle="success"
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
          confirmBtnBsStyle="danger"
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Add Country</ModalHeader>
          <Formik
            initialValues={{
              country: "",
            }}
            validationSchema={Yup.object({
              country: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                country: values?.country,
              }
              console.log(data)
              const assigned = await addCountry(data)
              resetForm({ values: "" })
              setLoading(false)
              if (assigned === undefined) {
                alert("Please check your internet connection")
              }
              if (assigned?.success) {
                setLoading(false)
                setInfo({
                  confirm: false,
                  success_dlg: true,
                  error_dlg: false,
                  dynamic_title: "Added!",
                  dynamic_description: assigned?.message,
                })
                setTimeout(() => {
                  refreshTable()
                  toggle()
                }, 1500)
              } else {
                setLoading(false)
                setInfo({
                  confirm: false,
                  success_dlg: false,
                  error_dlg: true,
                  dynamic_title: "Failed!",
                  dynamic_description: assigned?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="select2-container">
                          <Label>Select New Country</Label>
                          <CustomSelect
                            name="country"
                            value={props.values.country}
                            onChange={props.handleChange("country")}
                            options={availableCountries}
                            classNamePrefix="select2-selection"
                          />
                          {props.touched.country && props.errors.country ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.country}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          color="primary"
                        >
                          {loading ? "Adding" : "Add"}
                        </Button>
                      </Col>
                      <Col md={3} style={{ flex: "1" }}>
                        <Button type="button" color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter></ModalFooter>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

AddCountry.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddCountry
