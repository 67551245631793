import { Field, Form, Formik } from "formik"
import {
  systemSettings,
  transferCredit,
  userAccountParameters,
} from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const NewBeneficiaries = props => {
  const { toggle, refetch } = props
  const logicOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const [values, setValues] = useState({
    beneficiaryEmailOrPhone: "",
    amount: "",
    comment: "",
  })

  const [disableButton, setDisableButton] = useState(false)
  const [loading, setLoading] = useState(null)
  const [checked, setChecked] = useState(true)
  const [userAccountDetails, setUserAccountDetails] = useState({})
  const [systemSettingsDetails, setSystemSettingsDetails] = useState(null)
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const convertAmountToTwoDecimalPlace = amount => parseFloat(amount).toFixed(2)
  const convertUSDollarsCurrency3LettersToCurrencySymbol = currencyLetters => {
    if (currencyLetters === "USD") {
      return "$"
    } else {
      return currencyLetters
    }
  }

  const onCaptchaChange = value => {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const getAccountDetails = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const parameters = await userAccountParameters(data)
    const userParameters = parameters?.data
    console.log(userParameters)
    if (userParameters) {
      setUserAccountDetails(userParameters)
    }
  }

  useEffect(() => {
    getAccountDetails()
  }, [])

  const getSystemSettings = async () => {
    const data = {
      orgId: "939001",
    }
    const details = await systemSettings(data)
    const systemDetails = details?.data.companyFinancials
    console.log(systemDetails)
    if (systemDetails) {
      setSystemSettingsDetails(systemDetails)
    }
  }

  useEffect(() => {
    getSystemSettings()
  }, [])

  const formikInitialValues = {
    beneficiaryEmailOrPhone: "",
    amount: "",
    saveBeneficiary: "true",
    comment: "",
  }

  const formikValidationSchema = Yup.object({
    beneficiaryEmailOrPhone: Yup.string().required("Required"),
    amount: Yup.number()
      .min(2, "Minimum amount to transfer is $2")
      .required("Required"),
    comment: Yup.string().max(200, "Comment must not exceed 200 characters"),
  })

  return (
    <div>
      <div className="modal-content">
        <ModalHeader>
          Balance:
          {" " +
            convertUSDollarsCurrency3LettersToCurrencySymbol(
              userAccountDetails.currency3Letters
            ) +
            convertAmountToTwoDecimalPlace(userAccountDetails.accountBalance)}
        </ModalHeader>
        <Formik
          initialValues={formikInitialValues}
          validationSchema={formikValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            const data = {
              orgId: "939001",
              userGlobalId: user?.userGlobalId,
              isNewBeneficiary: "true",
              saveBeneficiaryInfoIfNew:
                values.saveBeneficiary === false ? "false" : "true",
              beneficiaryEmailOrPhone: values.beneficiaryEmailOrPhone,
              amount: values.amount,
              comment: values.comment,
            }
            console.log(data)
            const res = await transferCredit(data)
            console.log(res)
            resetForm({ values: "" })
            setLoading(null)
            if (res === undefined) {
              alert("Please check your internet connection")
              setLoading(null)
            }
            if (res?.success === false) {
              console.log(res)
              setLoading(false)
              setInfo({
                confirm: false,
                error_dlg: true,
                dynamic_title: "Failed!",
                dynamic_description: res?.message,
              })
              getAccountDetails()
            } else if (res?.success === true) {
              console.log(res)
              setLoading(false)
              setInfo({
                confirm: false,
                success_dlg: true,
                dynamic_title: "Added!",
                dynamic_description: res?.message,
              })
              refetch()
              getAccountDetails()
            }
          }}
        >
          {props => {
            return (
              <Form className={"justify-content-center"}>
                <ModalBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup className="select2-container">
                        <Label for="formrow-beneficiary-Input">
                          Beneficiary
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-beneficiary-Input"
                          placeholder={"Enter email or number"}
                          name="beneficiaryEmailOrPhone"
                          onChange={props.handleChange}
                          value={props.values.beneficiaryEmailOrPhone}
                          onBlur={props.handleBlur}
                        />
                        {props.touched.beneficiaryEmailOrPhone &&
                        props.errors.beneficiaryEmailOrPhone ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.beneficiaryEmailOrPhone}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-amount-Input">Amount</Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-amount-Input"
                          placeholder={"Enter Amount"}
                          name="amount"
                          onChange={props.handleChange}
                          value={props.values.amount}
                          onBlur={props.handleBlur}
                        />
                        {props.touched.amount && props.errors.amount ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.amount}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-comment-Input">Comment</Label>
                        <Input
                          type="textarea"
                          rows={5}
                          cols={30}
                          className="form-control"
                          id="formrow-comment-Input"
                          placeholder="Enter your comment"
                          name="comment"
                          onChange={props.handleChange}
                          value={props.values.comment}
                          onBlur={props.handleBlur}
                        />
                        {props.touched.comment && props.errors.comment ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.comment}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>{" "}
                    <Col lg="12">
                      <FormGroup>
                        <div>
                          <label>
                            <Field
                              type="checkbox"
                              name="saveBeneficiary"
                              onChange={props.handleChange}
                            />
                            Securely save this beneficiary
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="select2-container">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={onCaptchaChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col md={2} style={{ flex: "1" }}></Col>
                    <Col md={5} style={{ flex: "2" }}>
                      <Button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        // type="submit"
                        onClick={() => setInfo({ confirm: true })}
                        color="primary"
                        disabled={!disableButton}
                      >
                        {loading ? "Sending..." : "Send"}
                      </Button>
                    </Col>
                    <Col md={3} style={{ flex: "1" }}>
                      <Button color="link" onClick={toggle}>
                        Cancel
                      </Button>
                    </Col>
                    <Col md={2} style={{ flex: "1" }}></Col>
                    <br />
                    <i
                      style={{
                        color: "#000",
                        fontSize: "11px",
                        marginLeft: "10px",
                      }}
                    >
                      Kindly check the captcha checkbox to enable this "Send"
                      button
                    </i>
                  </Row>
                  {console.log(props.values)}
                </ModalBody>
                <ModalFooter></ModalFooter>
                {info.confirm ? (
                  <SweetAlert
                    title="Are you sure?"
                    showCancel
                    confirmBtnText="Send"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancel"
                    cancelBtnBsStyle="danger"
                    onConfirm={props.handleSubmit}
                    onCancel={() =>
                      setInfo({
                        confirm: false,
                        error_dlg: true,
                        dynamic_title: "Cancelled",
                        dynamic_description: "Process has been terminated!",
                      })
                    }
                  >
                    <span>
                      You are about to transfer ${props.values.amount} to{" "}
                      {props.values.beneficiaryEmailOrPhone}. Please click
                      "Send" button to confirm your transaction or "Cancel" to
                      exit.
                      <br />
                      <br />
                      <strong>
                        Note: This service attracts a non-refundable transaction
                        fee of{" "}
                        {convertUSDollarsCurrency3LettersToCurrencySymbol(
                          systemSettingsDetails.currency3LetterCode
                        ) + systemSettingsDetails.transferCreditTransactionFee}
                        .{" "}
                      </strong>
                    </span>
                  </SweetAlert>
                ) : null}
              </Form>
            )
          }}
        </Formik>
        <Col lg={12}>
          {info.success_dlg ? (
            <SweetAlert
              title={info.dynamic_title}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => setInfo({ success_dlg: false })}
            >
              {info.dynamic_description}
            </SweetAlert>
          ) : null}
          <div>
            {info.error_dlg ? (
              <SweetAlert
                title={info.dynamic_title}
                danger
                confirmBtnBsStyle="danger"
                onConfirm={() => setInfo({ error_dlg: false })}
              >
                {info.dynamic_description}
              </SweetAlert>
            ) : null}
          </div>
        </Col>
      </div>
    </div>
  )
}

export default NewBeneficiaries
