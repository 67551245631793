// availity-reactstrap-validation
import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { Form, Formik } from "formik"
import { SignUp } from "helpers/requests"
import React, { useRef, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import ReactPhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { Link, useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import logo from "../../assets/images/logo.svg"
// import images
import profile from "../../assets/images/profile-img.png"
import externalLinks from "../../helpers/externalLinks"

const Signup = () => {
  const [loading, setLoading] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [user, setUser] = useState({ email: "", password: "" })
  const [serverError, setServerError] = useState("")
  const [password1Type, setPassword1Type] = useState("password")
  const [password2Type, setPassword2Type] = useState("password")
  const [info, setInfo] = useState({
    success_dlg: null,
    error_dlg: null,
    dynamic_title: "",
    dynamic_description: "",
  })
  let verified = useRef()
  const history = useHistory()

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  const handlePassword1Type = () => {
    if (password1Type === "text") setPassword1Type("password")
    else setPassword1Type("text")
  }
  const handlePassword2Type = () => {
    if (password2Type === "text") setPassword2Type("password")
    else setPassword2Type("text")
  }

  const formikInitialValues = {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    partnerCode: "",
    promoCode: "",
  }

  return (
    <React.Fragment>
      {info.success_dlg && (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      )}
      {info.error_dlg && (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      )}

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register for free!</h5>
                        <p>Get your VomozFlex account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <a
                      href={externalLinks.vomozFlexLandingPage}
                      rel="noopener noreferrer"
                    >
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={formikInitialValues}
                      validationSchema={Yup.object({
                        firstName: Yup.string()
                          .min(2, "Minimum of 2 characters")
                          .max(64, "Maximum of 64 characters")
                          .required("Required"),
                        lastName: Yup.string()
                          .min(2, "Minimum of 2 characters")
                          .max(64, "Maximum of 64 characters")
                          .required("Required"),
                        mobile: Yup.string().required("Required"),
                        email: Yup.string()
                          .email("Invalid email address")
                          .required("Required"),
                        password: Yup.string()
                          .min(8, "Must be 8 characters or more")
                          .max(32, "Must not exceed 32 characters")
                          .matches(
                            /([0-9])/,
                            "Must contain at least one number"
                          )
                          .matches(
                            /([a-z])/,
                            "Must contain at least one lowercase letter"
                          )
                          .matches(
                            /([A-Z])/,
                            "Must contain at least one uppercase letter"
                          )
                          .matches(
                            /([@#$%!])/,
                            "Must contain at least one special character (@#$%!)"
                          )
                          .required("Required"),
                        partnerCode: Yup.string()
                          .min(4, "Minimum of 4 characters")
                          .max(20, "Maximum of 20 characters"),
                        promoCode: Yup.string().max(
                          20,
                          "Maximum of 20 characters"
                        ),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        console.log(values)
                        setLoading(true)
                        const data = {
                          orgId: "939001",
                          firstName: values.firstName,
                          lastName: values.lastName,
                          mobile: values.mobile,
                          email: values.email,
                          password: values.password,
                          partnerCode: values.partnerCode,
                          promoCode: values.promoCode,
                        }
                        const res = await SignUp(data)
                        console.log(res)
                        resetForm({ values: "" })
                        setLoading(false)
                        if (res === undefined) {
                          alert("Please Check your internet connection")
                        }
                        if (res.success) {
                          setLoading(false)
                          setInfo({
                            success_dlg: true,
                            dynamic_title: "Welcome!",
                            dynamic_description: res.message,
                          })
                          console.log(res.message)
                          setTimeout(() => {
                            history.push("/login")
                          }, 5)
                        } else {
                          setLoading(false)
                          setInfo({
                            error_dlg: true,
                            dynamic_title: "Failed!",
                            dynamic_description: res.message,
                          })
                          console.log(res)
                          // setServerError(res.message)
                        }
                      }}
                    >
                      {props => {
                        return (
                          <Form className="form-horizontal">
                            {/* {serverError ? (
                              <Alert color="danger">{serverError}</Alert>
                            ) : null} */}

                            <FormGroup>
                              <Label>First Name</Label>
                              <InputGroup>
                                <input
                                  name="firstName"
                                  value={props.values.firstName}
                                  className="form-control mr-0 ml-0"
                                  placeholder="Enter first name"
                                  onChange={props.handleChange}
                                  type="text"
                                  onBlur={props.handleBlur}
                                />
                              </InputGroup>
                              {props.touched.firstName &&
                              props.errors.firstName ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.firstName}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label>Last Name</Label>
                              <InputGroup>
                                <input
                                  name="lastName"
                                  value={props.values.lastName}
                                  className="form-control mr-0 ml-0"
                                  placeholder="Enter last name"
                                  onChange={props.handleChange}
                                  type="text"
                                  onBlur={props.handleBlur}
                                />
                              </InputGroup>
                              {props.touched.lastName &&
                              props.errors.lastName ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.lastName}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label for="formrow-email-Input">
                                International Mobile Number
                              </Label>

                              <ReactPhoneInput
                                // country={location.toLowerCase()}
                                country="us"
                                value={props.values.mobile}
                                inputProps={{
                                  name: "mobile",
                                }}
                                onBlur={props.handleBlur}
                                onChange={val => {
                                  props.setFieldValue("mobile", val)
                                }}
                                inputClass="form-control"
                                inputStyle={{ width: "100%" }}
                              />

                              {props.touched.mobile && props.errors.mobile ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.mobile}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label>Email</Label>
                              <InputGroup>
                                <input
                                  name="email"
                                  value={props.values.email}
                                  className="form-control mr-0 ml-0"
                                  placeholder="Enter email"
                                  onChange={values => {
                                    props.handleChange(values)
                                    if (serverError) {
                                      setServerError("")
                                    }
                                  }}
                                  type="email"
                                  onBlur={props.handleBlur}
                                />
                              </InputGroup>
                              {props.touched.email && props.errors.email ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.email}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label>Password</Label>
                              <InputGroup>
                                <input
                                  name="password"
                                  value={props.values.password}
                                  onChange={values => {
                                    props.handleChange(values)
                                    if (serverError) {
                                      setServerError("")
                                    }
                                  }}
                                  type={password1Type}
                                  placeholder="Enter Password"
                                  className="form-control mr-0 ml-0"
                                  onBlur={props.handleBlur}
                                />

                                <div
                                  className="input-group-append"
                                  style={{ cursor: "pointer" }}
                                  onClick={handlePassword1Type}
                                >
                                  <span className="input-group-text">
                                    {password1Type === "text" ? (
                                      <i class="fa fa-low-vision"></i>
                                    ) : (
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </InputGroup>
                              {props.touched.password &&
                              props.errors.password ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.password}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label>Partner Code</Label>
                              <InputGroup>
                                <input
                                  name="partnerCode"
                                  value={props.values.partnerCode}
                                  className="form-control mr-0 ml-0"
                                  placeholder="Enter partner code"
                                  onChange={props.handleChange}
                                  type="text"
                                  onBlur={props.handleBlur}
                                />
                              </InputGroup>
                              {props.touched.partnerCode &&
                              props.errors.partnerCode ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.partnerCode}
                                </p>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label>Promo Code</Label>
                              <InputGroup>
                                <input
                                  name="promoCode"
                                  value={props.values.promoCode}
                                  onChange={values => {
                                    props.handleChange(values)
                                    if (serverError) {
                                      setServerError("")
                                    }
                                  }}
                                  type={password2Type}
                                  placeholder="Enter the promo code"
                                  className="form-control mr-0 ml-0"
                                  onBlur={props.handleBlur}
                                />

                                <div
                                  className="input-group-append"
                                  style={{ cursor: "pointer" }}
                                  onClick={handlePassword2Type}
                                >
                                  <span className="input-group-text">
                                    {password2Type === "text" ? (
                                      <i class="fa fa-low-vision"></i>
                                    ) : (
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </InputGroup>
                              {props.touched.promoCode &&
                              props.errors.promoCode ? (
                                <p style={{ color: "red", fontSize: "11px" }}>
                                  {props.errors.promoCode}
                                </p>
                              ) : null}
                            </FormGroup>

                            <Col lg="6">
                              <FormGroup className="select2-container">
                                {/* <Label>Country</Label> */}
                                <ReCAPTCHA
                                  sitekey={
                                    process.env.REACT_APP_RECAPTCHA_SITE_KEY
                                  }
                                  onChange={onCaptchaChange}
                                />
                              </FormGroup>
                            </Col>

                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                disabled={!disableButton}
                              >
                                {loading ? "Signing Up" : "Sign Up"}
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <p className="mb-0">
                                By registering you agree to the Vomoz{" "}
                                <Link
                                  to={
                                    externalLinks.vomozFlexLandingPagePrivacyPolicy
                                  }
                                  className="text-primary"
                                >
                                  Terms of Use
                                </Link>
                              </p>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login now{" "}
                  </Link>{" "}
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Signup
