const transferCreditHistoryColumn = [
  {
    field: "id",
    label: "#",
    sort: "asc",
  },
  {
    field: "receiverFirstName",
    label: "First Name",
    sort: "asc",
    width: 150,
  },
  {
    field: "receiverLastName",
    label: "Last Name",
    sort: "asc",
    width: 150,
  },
  {
    field: "receiverMobile",
    label: "Phone",
    sort: "asc",
    width: 136,
  },
  {
    field: "receiverEmail",
    label: "Email",
    sort: "asc",
    width: 400,
  },
  {
    field: "amount",
    label: "Amount ($)",
    sort: "asc",
    width: 100,
  },
  {
    label: "Date",
    field: "date",
    sort: "asc",
    width: 136,
  },
  {
    label: "More",
    field: "more",
    width: 200,
  },
]

export default transferCreditHistoryColumn
