import FooterMessage from "components/HorizontalLayout/FooterMessage"
import { verifyTwoFactorAuth } from "helpers/requests"
import React, { useEffect, useState } from "react"
//Verification code package
import AuthCode from "react-auth-code-input"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap"
import logo from "../../assets/images/logo.svg"
import "./styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const TwostepVerification = () => {
  const [loading, setLoading] = useState(null)
  const [loading2, setLoading2] = useState(null)
  const [code, setCode] = useState("")
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
  })

  useEffect(() => {
    setLoading(true)
  }, [])

  const VerifyOTP = async () => {
    setLoading2(true)
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
      advancedAccessCode: code,
    }
    const res = await verifyTwoFactorAuth(data)
    if (res === undefined) {
      setLoading2(null)
      alert("Please Check your internet connection")
    }
    if (res?.success === false) {
      setLoading2(null)
      console.log(res)
      setInfo({
        confirm: false,
        error_dlg: true,
        dynamic_title: "Failed!",
        dynamic_description: res?.message,
      })
    } else if (res?.success === true) {
      setLoading2(null)
      setInfo({
        confirm: false,
        success_dlg: true,
        dynamic_title: "Verified!",
        dynamic_description: res?.message,
      })
      setTimeout(() => {
        window.location.href = "/dashboard"
      }, 2000)
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <SweetAlert
          title="Loading"
          timeout={1000}
          showConfirm={false}
          onConfirm={() => setLoading(null)}
        >
          <img className="spinning loading-logo" src={logo} />
        </SweetAlert>
      )}
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <div className="account-pages my-4 pt-sm-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-3">
                        <h4>{user?.twoFactorAuthentication?.messageHeader}</h4>
                        <p className="mb-5">
                          {user?.twoFactorAuthentication?.messageBody}
                        </p>

                        <Form>
                          <Row>
                            <Col xs={12}>
                              <FormGroup className="verification">
                                <label
                                  htmlFor="digit1-input"
                                  className="sr-only"
                                >
                                  2FA Code
                                </label>
                                <AuthCode
                                  characters={6}
                                  className="form-control form-control-lg text-center"
                                  inputStyle={{
                                    width: "50px",
                                    height: "50px",
                                    padding: "6px",
                                    borderRadius: "8px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    margin: "5px",
                                    border: "1px solid #ced4da",
                                    textTransform: "uppercase",
                                  }}
                                  onChange={value => setCode(value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>

                        <div className="mt-2">
                          <Link
                            className="btn btn-block btn-success w-md"
                            onClick={VerifyOTP}
                          >
                            {loading2 ? "Verifying" : "Verify"}
                          </Link>
                        </div>
                        <div className="mt-4">
                          <p>{user?.twoFactorAuthentication?.messageFooter}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  Didn't receive a code?{" "}
                  <Link to="/login">
                    <span className="font-weight-medium text-primary">
                      {" "}
                      Back to Login{" "}
                    </span>{" "}
                  </Link>
                </p>
                <FooterMessage />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TwostepVerification
