import { Form, Formik } from "formik"
import { updateExistingCard } from "helpers/requests"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const UpdateExistingCard = props => {
  const { editInfo, isOpen, toggle, refreshTable, cardDetails } = props

  const [loading, setLoading] = useState(null)
  const [info, setInfo] = useState({})
  const [checked, setChecked] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [dataInfo, setDataInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })

  const closeSuccessFeedbackModal = () => {
    setDataInfo({ success_dlg: false })
  }

  const closeErrorFeedbackModal = () => {
    setDataInfo({ error_dlg: false })
  }

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  useEffect(() => {
    setInfo(editInfo)
    console.log(info)
  }, [editInfo])

  const handleChange = event => {
    setChecked(event)
  }

  console.log(cardDetails)

  return (
    <>
      {dataInfo.success_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={closeSuccessFeedbackModal}
        >
          {dataInfo.dynamic_description}
        </SweetAlert>
      ) : null}

      {dataInfo.error_dlg ? (
        <SweetAlert
          title={dataInfo.dynamic_title}
          error
          confirmBtnBsStyle="danger"
          onConfirm={closeSuccessFeedbackModal}
        >
          {dataInfo?.dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Update Card</ModalHeader>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              expirationMonthYear: "",
              cvv: "",
              address: "",
              cityOrTown: "",
              stateOrProvince: "",
              zipcodeOrPostalcode: "",
              country: "",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(64, "Maximum of 64 characters")
                .required("Required"),
              lastName: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(64, "Maximum of 64 characters")
                .required("Required"),
              expirationMonthYear: Yup.string()
                .length(4, "Only 4 digit numbers (MM/YY) are accepted")
                .matches(
                  /(^[0-9]+$)/,
                  "This field can only contain digit number"
                )
                .required("Required"),
              cvv: Yup.string()
                .required("Required")
                .matches(/(^[0-9]+$)/, "cvv can only contain digit number")
                .min(3, "Minimum of 3 characters")
                .max(4, "Maximum of 4 characters")
                .required("Required"),
              address: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(64, "Maximum of 64 characters")
                .required("Required"),
              cityOrTown: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(64, "Maximum of 64 characters")
                .required("Required"),
              stateOrProvince: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(64, "Maximum of 64 characters")
                .required("Required"),
              zipcodeOrPostalcode: Yup.string()
                .min(2, "Minimum of 2 characters")
                .max(20, "Maximum of 20 characters")
                .required("Required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              console.log(editInfo)
              setLoading(true)
              const data = {
                orgId: "939001",
                userGlobalId: user?.userGlobalId,
                firstName: values.firstName,
                lastName: values.lastName,
                id: info.id,
                expirationMonthYear: values.expirationMonthYear,
                cvv: values.cvv,
                address: values.address,
                cityOrTown: values.cityOrTown,
                stateOrProvince: values.stateOrProvince,
                zipcodeOrPostalcode: values.zipcodeOrPostalcode,
                country: values.country,
              }
              console.log(data)
              const res = await updateExistingCard(data)
              resetForm({ values: "" })
              setLoading(false)
              if (res === undefined) {
                alert("Please check your internet connection")
              }
              if (res?.success) {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  success_dlg: true,
                  dynamic_title: "Updated!",
                  dynamic_description: res?.message,
                })
                refreshTable()
                toggle()
              } else {
                setLoading(false)
                setDataInfo({
                  confirm: false,
                  error_dlg: true,
                  dynamic_title: "Failed",
                  dynamic_description: res?.message,
                })
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <ModalBody>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>First Name</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"First Name"}
                            name="firstName"
                            value={props.values.firstName}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                          />
                          {props.touched.firstName && props.errors.firstName ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.firstName}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Last Name</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"Last Name"}
                            name="lastName"
                            onChange={props.handleChange}
                            value={props.values.lastName}
                            onBlur={props.handleBlur}
                          />
                          {props.touched.lastName && props.errors.lastName ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.lastName}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Last Name</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"Expiration Month/Year"}
                            name="expirationMonthYear"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.expirationMonthYear}
                          />
                          <FormText>Example: 0224 for (02/24)</FormText>
                          {props.touched.expirationMonthYear &&
                          props.errors.expirationMonthYear ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.expirationMonthYear}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Last Name</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"CVV"}
                            name="cvv"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.cvv}
                          />
                          {props.touched.cvv && props.errors.cvv ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.cvv}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="select2-container">
                          {/* <Label>Address</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"Address"}
                            name="address"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.address}
                          />
                          {props.touched.address && props.errors.address ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.address}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>City/Town</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"City or Town"}
                            name="cityOrTown"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.cityOrTown}
                          />
                          {props.touched.cityOrTown &&
                          props.errors.cityOrTown ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.cityOrTown}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>State/Province</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"State or Province"}
                            name="stateOrProvince"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.stateOrProvince}
                          />
                          {props.touched.stateOrProvince &&
                          props.errors.stateOrProvince ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.stateOrProvince}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Zip/Postal Code</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"Zip/Postal Code"}
                            name="zipcodeOrPostalcode"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.zipcodeOrPostalcode}
                          />
                          {props.touched.zipcodeOrPostalcode &&
                          props.errors.zipcodeOrPostalcode ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.zipcodeOrPostalcode}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Country</Label> */}
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={"Country"}
                            name="country"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.country}
                          />
                          {props.touched.country && props.errors.country ? (
                            <p style={{ color: "red", fontSize: "11px" }}>
                              {props.errors.country}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup className="select2-container">
                          {/* <Label>Country</Label> */}
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onCaptchaChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <Col md={5} style={{ flex: "2" }}>
                        <Button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          color="primary"
                          disabled={!disableButton}
                        >
                          {loading ? "Updating" : "Update"}
                        </Button>
                      </Col>
                      <Col md={6} style={{ flex: "1" }}>
                        <Button color="link" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col md={2} style={{ flex: "1" }}></Col>
                      <br />
                      <i
                        style={{
                          color: "#000",
                          fontSize: "11px",
                          marginLeft: "10px",
                        }}
                      >
                        Kindly check the captcha checkbox to enable this "Send"
                        button
                      </i>
                    </Row>
                  </ModalBody>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default UpdateExistingCard
