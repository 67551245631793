import "assets/scss/datatables.scss"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { map } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { withRouter } from "react-router-dom"
import {
  Card,
  Col,
  Container,
  Modal,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import transferCreditHistoryColumn from "../dataTableColumns"
import "../styles.scss"
// import TransferCreditHistory from "./transferCreditHis"
import ExistingBeneficiaries from "./ExistingBeneficiaries"
import NewBeneficiaries from "./NewBeneficiaries"

class TransCredit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
      activeTab: "1",
    }
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    })
  }
  handleValidSubmit = () => {
    console.log("Hello World!")
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const { orders, isOpen, toggle, refetch } = this.props

    const data = {
      columns: transferCreditHistoryColumn,
      rows: map(orders, order => ({ ...order, status: status[order.status] })),
    }

    return (
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Account" breadcrumbItem="Transfer Credit" />

            <Row
              style={{
                display: "inline",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col lg="12" className="settings-panel">
                <Card>
                  <div className="settings-panel-card-body">
                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggleTab("1")
                          }}
                        >
                          Existing Beneficiary
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggleTab("2")
                          }}
                        >
                          New Beneficiary
                        </NavLink>
                      </NavItem>
                    </ul>

                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3"
                    >
                      <TabPane tabId="1" id="history">
                        <ExistingBeneficiaries
                          refetch={refetch}
                          toggle={toggle}
                        />
                      </TabPane>

                      <TabPane tabId="2" id="security">
                        <NewBeneficiaries toggle={toggle} refetch={refetch} />
                      </TabPane>
                    </TabContent>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>
    )
  }
}

TransCredit.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(TransCredit)
