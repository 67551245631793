import { Form, Formik } from "formik"
import { availableFollowMeNumbers, editFollowMeNumber } from "helpers/requests"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import ReCAPTCHA from "react-google-recaptcha"
import { RadioButton, RadioGroup } from "react-radio-buttons"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import "../../styles.scss"

const user = JSON.parse(localStorage.getItem("userData")) || {}

const EditFollowMeForm = props => {
  const {
    editInfo,
    toggle,
    refreshTable,
    destination,
    voicemailOption,
    returnDate,
    recordId,
    dataInfoFollowMeNumber,
  } = props
  const [loading, setLoading] = useState(null)
  const [availableNumbers, setAvailableNumbers] = useState([])
  const [showReturnDate, setShowReturnDate] = useState(false)
  const [showVoiceMailOption, setShowVoiceMailOption] = useState(false)
  const [options, setOptions] = useState([])
  const [values, setValues] = useState("")
  const [valuesInfo, setValuesInfo] = useState(null)
  const [followMeNumber, setFollowMeNumber] = useState(null)
  const [dataInfo, setDataInfo] = useState({})
  const [info, setInfo] = useState({
    confirm: null,
    dynamic_description: "",
    dynamic_title: "",
    success_dlg: null,
    error_dlg: null,
    id: "",
    deleting: null,
  })
  const [disableButton, setDisableButton] = useState(false)

  function onCaptchaChange(value) {
    console.log("Captcha value:", value)
    setDisableButton(true)
  }

  useEffect(() => {
    setDataInfo(editInfo)
    console.log(editInfo)
  }, [editInfo])
  console.log(dataInfo)

  const formikInitialValues = {
    destinationNumber: destination,
    voiceMailOption: voicemailOption,
    pickUpPhone: true,
    returnDateOption: true,
    returnDate: returnDate,
    voiceMailRadio: "",
  }

  let optionGroup = [
    {
      options,
    },
  ]
  useEffect(() => {
    console.log(availableNumbers)
    setOptions(
      availableNumbers.map(local => ({
        label: local.followMeNumber,
        value: local.id,
        id: local.id,
        // voiceMailOption: local.voiceMailOption,
      }))
    )
  }, [availableNumbers])

  useEffect(() => {
    let idEd
    if (availableFollowMeNumbers.length < 1)
      idEd = availableFollowMeNumbers.filter(
        num => num.followMeNumber === followMeNumber
      )
    console.log(idEd)
    console.log(followMeNumber)
  }, [availableFollowMeNumbers])

  const getAvailableLocalNumbers = async () => {
    let data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }

    const available = await availableFollowMeNumbers(data)
    const availableNumbers = available?.data?.returnedRecords
    if (availableNumbers) {
      setOptions(
        availableNumbers.map(local => ({
          label: local.followMeNumber,
          value: local.id,
          id: local.id,
        }))
      )
    }
  }
  useEffect(() => {
    getAvailableLocalNumbers()
  }, [])

  const handleReturnDateRadio = e => {
    setValues(prev => ({
      ...prev,
      returnRadio: e === "returnDate" ? "true" : "false",
    }))
    if (e === "returnDate") {
      setShowReturnDate(true)
    } else {
      setShowReturnDate(false)
    }
  }

  const handleVoiceMailRadio = e => {
    setValues(prev => ({
      ...prev,
      voiceMailRadio: e === "voiceMail" ? "true" : "false",
    }))
    if (e === "voiceMail") {
      setShowVoiceMailOption(true)
    } else {
      setShowVoiceMailOption(false)
    }
  }

  useEffect(() => {
    getAvailableLocalNumbers()
  }, [])

  return (
    <>
      {info.success_dlg ? (
        <SweetAlert
          success
          confirmBtnBsStyle="success"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ success_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}

      {info.error_dlg ? (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={info.dynamic_title}
          onConfirm={() => setInfo({ error_dlg: false })}
        >
          {info.dynamic_description}
        </SweetAlert>
      ) : null}
      <Formik
        initialValues={formikInitialValues}
        validationSchema={Yup.object({
          destinationNumber: Yup.string()
            .matches(/(^[0-9]+$)/, "This field can only contain digit number")
            .required("Required")
            .min(10, "Minimum of 10 digits")
            .max(20, "Maximum of 20 digits"),

          voiceMailOption: showVoiceMailOption
            ? Yup.string().email("Invalid email address").required("Required")
            : undefined,
          returnDate: Yup.date(),
        })}
        onSubmit={async (values, { resetForm }) => {
          console.log(values)
          setValuesInfo(values.returnDate)
          console.log(valuesInfo)
          setLoading(true)
          const data = {
            orgId: "939001",
            userGlobalId: user?.userGlobalId,
            recordId: recordId,
            destination: values.destinationNumber,
            voicemailOption:
              values.voiceMailRadio === "voiceMail"
                ? values.voiceMailOption
                : "hangup",
            returnDate: values.returnDate,
          }
          console.log(data)
          const Assigned = await editFollowMeNumber(data)
          resetForm({ values: "" })
          setLoading(false)
          if (Assigned === undefined) {
            alert("Please check your internet connection")
          }
          if (Assigned?.success) {
            console.log(Assigned)
            setLoading(false)
            setInfo({
              confirm: false,
              success_dlg: true,
              dynamic_title: "Added!",
              dynamic_description: Assigned?.message,
            })
            refreshTable()
            toggle()
          } else {
            setLoading(false)
            setInfo({
              confirm: false,
              error_dlg: true,
              dynamic_title: "Failed!",
              dynamic_description: Assigned?.message,
            })
          }
        }}
      >
        {props => {
          return (
            <Form>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <FormGroup className="select2-container">
                      <Label>Follow Me Number: {dataInfoFollowMeNumber}</Label>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="formrow-email-Input">
                        Destination Number (International Mobile Number)
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-email-Input"
                        placeholder={"Enter your destination number"}
                        name="destinationNumber"
                        value={props.values.destinationNumber}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                      />
                      {props.touched.destinationNumber &&
                      props.errors.destinationNumber ? (
                        <p style={{ color: "red", fontSize: "11px" }}>
                          {props.errors.destinationNumber}
                        </p>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup className="select2-container">
                      <Label for="formrow-email-Input">
                        When Am I Returning?
                      </Label>
                      <RadioGroup onChange={handleReturnDateRadio} horizontal>
                        <RadioButton
                          name="returnRadio"
                          value="returnDate"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          Return Date
                        </RadioButton>

                        <RadioButton
                          name="returnRadio"
                          value="iDontKnow"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          I don't Know
                        </RadioButton>
                      </RadioGroup>
                    </FormGroup>
                  </Col>

                  {showReturnDate ? (
                    <Col md={12}>
                      <FormGroup>
                        <div className="form-group row mt-3">
                          <label
                            htmlFor="example-date-input"
                            className="col-md-3 col-form-label"
                          >
                            Return Date
                          </label>
                          <div className="col-md-9">
                            <input
                              name="returnDate"
                              className="form-control"
                              type="date"
                              id="example-date-input"
                              onChange={props.handleChange}
                              value={props.values.returnDate}
                              onBlur={props.handleBlur}
                            />
                            {props.touched.returnDate &&
                            props.errors.returnDate ? (
                              <p style={{ color: "red", fontSize: "11px" }}>
                                {props.errors.returnDate}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col>
                    <FormGroup className="select2-container">
                      <Label for="formrow-email-Input">
                        If I Didn't Pick Up My Phone
                      </Label>
                      <RadioGroup onChange={handleVoiceMailRadio} horizontal>
                        <RadioButton
                          name="voiceMailRadio"
                          value="voiceMail"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          Go to My Voicemail
                        </RadioButton>
                        <RadioButton
                          name="voiceMailRadio"
                          value="hangUp"
                          classNamePrefix="select2-selection"
                          iconSize={15}
                        >
                          Hangup!
                        </RadioButton>
                      </RadioGroup>
                    </FormGroup>
                  </Col>

                  {showVoiceMailOption ? (
                    <Col md={12}>
                      <FormGroup>
                        <Label for="formrow-email-Input">
                          Voicemail Option
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="formrow-name-Input"
                          placeholder={"Enter Voice Mail Details"}
                          name="voiceMailOption"
                          onChange={props.handleChange}
                          value={props.values.voiceMailOption}
                          onBlur={props.handleBlur}
                        />
                        {props.touched.voiceMailOption &&
                        props.errors.voiceMailOption ? (
                          <p style={{ color: "red", fontSize: "11px" }}>
                            {props.errors.voiceMailOption}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col lg="6">
                    <FormGroup className="select2-container">
                      {/* <Label>Country</Label> */}
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Col md={2} style={{ flex: "1" }}></Col>
                  <Col md={5} style={{ flex: "2" }}>
                    <Button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                      color="primary"
                      disabled={!disableButton}
                    >
                      {loading ? "Sending..." : "Send"}
                    </Button>
                  </Col>
                  <Col md={3} style={{ flex: "1" }}>
                    <Button color="link" onClick={toggle}>
                      Cancel
                    </Button>
                  </Col>
                  <Col md={2} style={{ flex: "1" }}></Col>
                  <br />
                  <i
                    style={{
                      color: "#000",
                      fontSize: "11px",
                      marginLeft: "10px",
                    }}
                  >
                    Kindly check the captcha checkbox to enable this "Send"
                    button
                  </i>
                </Row>
              </ModalBody>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

EditFollowMeForm.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EditFollowMeForm
